import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from "react"
import { IconDelete, IconEdit, IconNextSolid, IconOptions, IconPrevSolid, IconView } from "../Icons"
import { Button, Input, Label } from "asbawa-react-ui-ts-test-b";
import './style.css'
import { useListFilters } from "../../../hooks/useListFilters";
// interface ListRowActionProps {
//   actions:{
//       icon: 'view' | 'edit' | 'delete' | 'customIcon',
//       iconSize?:string,
//       title?:string
//       onClick:()=>void;
//       customIcon?:ReactNode;
//     }[]
//   }


type BaseAction = {
  iconSize?: string;
  title?: string;
};

type DefaultAction = BaseAction & {
  icon: 'view' | 'edit' | 'delete';
  onClick: () => void;
  customIcon?: ReactNode
};

type CustomAction = BaseAction & {
  icon: 'customIcon';
  onClick?: () => void;
  customIcon: ReactNode;
};

interface InOptionsAction {
  lable: string,
  onClick?: () => void;
}
type OptionsAction = BaseAction & {
  icon: 'options'
  options: InOptionsAction[];
};

type ListRowActionProps = {
  actions: (DefaultAction | CustomAction | OptionsAction)[];
  align?:'left' | 'center' | 'right'
};
export const ListRowActions: FC<ListRowActionProps> = ({ actions = [],align = 'right' }) => {
  return (
    <div style={{ display: 'flex', columnGap: '12px', alignItems: 'center', justifyContent: (align == 'center')?'center':(align == 'left') ? 'flex-start' : 'flex-end'  }}>
      {actions.map((action, index) => {
        switch (action?.icon) {
          case "view":
            return <IconView key={index} size={action?.iconSize || '15px'} onClick={action?.onClick} title={action?.title || 'View'} />;
          case "edit":
            return <IconEdit key={index} size={action?.iconSize || '15px'} onClick={action?.onClick} title={action?.title || 'Edit'} />;
          case "delete":
            return <IconDelete key={index} size={action?.iconSize || '15px'} onClick={action?.onClick} title={action?.title || 'Delete'} />;
          case "customIcon":
            return <span key={index}>{action.customIcon}</span>
          case "options":
            return <OptionAction actions={action.options} />
        }
      })}
    </div>
  );
};

interface OptionActionProps {
  actions: InOptionsAction[]
}

const OptionAction: FC<OptionActionProps> = ({ actions }) => {
  const [showOptions, setShowOptions] = useState(false);
  const ulRef = useRef<HTMLUListElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ulRef.current && !ulRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <span style={{ position: 'relative' }}>
      <IconOptions size="15px" onClick={() => setShowOptions(true)} />
      {showOptions && (
        <ul
          ref={ulRef}
          style={{
            position: 'absolute',
            right: '10px',
            top: '0px',
            background: 'white',
            padding: '10px',
            borderRadius: '11px',
            lineHeight: 1.8,
            minWidth: '100px',
            boxShadow: 'var(--shadow-dark)',
          }}
        >
          {actions.map((act, index) => (
            <li
              key={index}
              style={{
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                padding: '6px 12px',
              }}
              onClick={() => {
                if (act.onClick) {
                  act.onClick();
                }
                setShowOptions(false);
              }}
            >
              {act.lable}
            </li>
          ))}
        </ul>
      )}
    </span>
  );

}


export default ListRowActions

interface TdProps {
  children: ReactNode,
  align?: 'left' | 'center' | 'right',
  style?: CSSProperties
}


export const Td: FC<TdProps> = ({ children, align = 'left', style }) => {
  return <td style={{ textAlign: align, ...style }}>
    {children}
  </td>
}


interface ListPaginationProps {
  pageNo: number,
  totalPages: number,
  totalCount?: number,
  setPageNo: (number: number) => void,
  setPageSize: (number: number | string) => void,
  pageSize: number
}

export const ListPagination: FC<ListPaginationProps> = ({
  pageNo,
  totalPages,
  totalCount,
  setPageNo,
  pageSize,
  setPageSize
}) => {

  return <div className="asr-pagi">
    <div className="asr-pagi-left">
      Records : {totalCount} &nbsp;
      <select className="asr-pagi-page-selector" value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        <option value={200}>200</option>
      </select>
    </div>
    <div className="asr-pagi-right">
      {pageNo > 1 && <IconPrevSolid onClick={() => setPageNo(pageNo - 1)} />}
      <p>{pageNo} / {totalPages} </p> &nbsp;
      {pageNo < totalPages && <IconNextSolid onClick={() => setPageNo(pageNo + 1)} />}
    </div>
  </div>
}


interface ListSearchProps {
  label?: string
}

export const ListSearch: FC<ListSearchProps> = ({ label }) => {

  const { filters, setFilter } = useListFilters({})
  const [search, setSearch] = useState<any>('');
  return <div className="list-search-container">
    <Input
      name="search"
      handleChange={(value) => setSearch(value)}
      value={search}
    />
    <Button title="Search" onClick={() => setFilter('search', search)} />
  </div>
}