import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../services/axios/settings/companyServices";
import TableForm from "../Components/TableForm";
import { ModalFormProps } from "../../types";
import documentService from "../../services/axios/documents";
import taskService from "../../services/axios/tasks";
import userService from "../../services/axios/users";
import meetingService from "../../services/axios/meetings";
import { InputDatetime } from "../Components/InputDatetime";
import { CS_MEETING_CLIENT_TYPE } from "../../utils/constants";
import clientService from "../../services/axios/clients";

import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { utcToLocalDatetime } from "../../utils/dateFunctions";

// Set up moment.js for calendar localization
const localizer = momentLocalizer(moment);

// interface Meeting {
//   id: number;
//   date: string; // e.g., "2024-10-10"
//   startTime: string; // e.g., "10:00"
//   endTime: string; // e.g., "11:00"
//   executiveName: string;
//   userName: string;
// }

const MeetingCalender: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [meetings, setMeetings] = useState<any[]>([{id:1,startTime:"2024-10-04 22:50:00",endTime:"2024-10-05 22:50:00",executiveName:"adminn",userName:"rraahhuull"},{id:2,startTime:"2024-10-05 02:50:00",endTime:"2024-10-06 22:50:00",executiveName:"AMAN",userName:"rnj"},{id:3,startTime:"2024-10-05 03:50:00",endTime:"2024-10-06 20:40:00",executiveName:"AMAN2",userName:"rnj2"}]);

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = () => {
    // gLoader(true);
    // meetingService?.detailMeeting(data?.meeting_id)
    //   .then((res: any) => {
    //     gLoader(false);
    //     let newDetail = res?.data?.response?.detail;
    //     newDetail = { ...newDetail, from_datetime: utcToLocalDatetime(newDetail.from_datetime), to_datetime: utcToLocalDatetime(newDetail.to_datetime) }
    //     setPayload({ ...payload, ...newDetail });
    //   })
    // const data: Meeting[] = await response.json();
    //   setMeetings(data);
    //   .catch((err: any) => {
    //     gLoader(false);
    //     errorPopUp(err?.message);
    //   })
  }

  // Convert meeting data to events for react-big-calendar
  // const events: Event[] = meetings.map((meeting) => ({
  //   title: `${meeting.executiveName} - ${meeting.userName}`,
  //   start: new Date(`${meeting.date}T${meeting.startTime}`),
  //   end: new Date(`${meeting.date}T${meeting.endTime}`),
  //   allDay: false,
  // }));
  const events: Event[] = meetings.map((meeting) => ({
    title: `${meeting.executiveName} - ${meeting.userName}`,
    start: new Date(`${meeting.startTime}`),
    end: new Date(`${meeting.endTime}`),
    allDay: false,
  }));

  // Custom event style function for highlighting meeting dates
  const eventStyleGetter = (event: Event) => {
    const style = {
      backgroundColor: '#f28b82', // Custom color for booked meetings
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  // const handleChange = (value: any, name: any) => {
  //   setPayload({
  //     ...payload,
  //     [name]: value,
  //   });

  //   setErrors({
  //     ...errors,
  //     [name]: null
  //   })
  // };

  // const handleSubmit = () => {
  //   setButtonLoading(true);
  //   meetingService?.addMeeting(payload)
  //     .then((res) => {
  //       setButtonLoading(false);
  //       successPopUp(res?.data?.message);
  //       onSuccess();
  //     })
  //     .catch((err) => {
  //       setButtonLoading(false);
  //       errorPopUp(err?.message);
  //       setErrors(err?.field_errors);
  //     })
  // };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "1200px" }}>
        <CardHeader title="Meeting Calender" />
        <FieldGroup column={1}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, margin: '50px' }}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={(event) => alert(`Meeting with ${event.title}`)} // Alert when clicking on a booked meeting
            tooltipAccessor={(event) => `Meeting from ${moment(event.start).format('YYYY-MM-DD HH:mm:ss')} to ${moment(event.end).format('YYYY-MM-DD HH:mm:ss')}`}
          />

        </FieldGroup>

        <br />

        <ButtonsGroup>
          {/* <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" /> */}
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default MeetingCalender;
