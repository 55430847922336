import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class userService {
    //user management routes
    listCompanyUser = async (urlParam = {}) => {
        return await getRequest(`company/user`, urlParam);
    }
    detailCompanyUser = async (id: any, urlParam = {}) => {
        return await getRequest(`company/user/${id}`, urlParam);
    }
    addCompanyUser = async (payload = {}) => {
        return await postRequest(`company/user`, payload);
    }
    updateCompanyUser = async (id: any, payload = {}) => {
        return await putRequest(`company/user/${id}`, payload);
    }
    deleteCompanyUser = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/user/${id}`, urlParam);
    }
    quickListCompanyUser = async (urlParam = {}) => {
        return await getRequest(`company/user/quick/list`, urlParam);
    }

}

const instance = new userService();

export default instance;

