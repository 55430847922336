import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "../..";

class companySettings {
    addEmailFooter = async (payload = {}) => {
        return await postRequest(`company/settings/email/footer`, payload);
    }
    detailEmailFooter = async (urlParam = {}) => {
        return await getRequest(`company/settings/email/footer`, urlParam);
    }
    updateEmailFooter = async (id: any, payload = {}) => {
        return await putRequest(`company/settings/email/footer/${id}`, payload);
    }
    deleteEmailFooter = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/settings/email/footer/${id}`, urlParam);
    }
    // quickListCompanyUser = async (urlParam = {}) => {
    //     return await getRequest(`company/settings/email/footer/quick/list`, urlParam);
    // }

}

const instance = new companySettings();

export default instance;

