import { Button, ButtonsGroup, Card, CardHeader, Dropdown, DropdownA2, FieldGroup, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import taskService from "../../../services/axios/tasks";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import TaskEdit from "./TaskEdit";
import { toNull } from "../../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { getTasksData } from "../../../store/taskSlice";

const TaskDetails = () => {
  const { task_id } = useParams<{ task_id: string }>();
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [taskDetail, setTaskDetail] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [tasksStatusList, setTasksStatusList] = useState<any>([]);
  const [tasksStatus, setTasksStatus] = useState<any>(null);

  const dispatch: any = useDispatch();

  const editSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchTasksStatus()
  }, [task_id])

  const fetchTasksStatus = () => {
    gLoader(true);
    taskService?.quickListTaskStatus()
      .then((res: any) => {
        gLoader(false);
        const dropdownOptionsTasksStatus = res?.data?.response?.list?.map((item: any) => ({
          name: item?.title,
          value: item?.id,
          bgColor: item?.bg_color,
          color: item?.color,
        }));
        setTasksStatusList(dropdownOptionsTasksStatus);
        fetchData();
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const fetchData = () => {
    gLoader(true);
    taskService?.detailTask(task_id)
      .then((res: any) => {
        gLoader(false);
        setTaskDetail(res?.data?.response?.detail);
        setTasksStatus(res?.data?.response?.detail.task_status);
        // setPayload((prevPayload: any) => ({
        //   ...prevPayload,
        //   'status': res?.data?.response?.detail.status,
        //   'task_status': res?.data?.response?.detail.task_status,
        // }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  // const handleChange = (value: any, name: any) => {
  //   setPayload({
  //     ...payload,
  //     [name]: value,
  //   });

  //   setErrors({
  //     ...errors,
  //     [name]: null
  //   })
  // };

  // const handleSubmit = () => {
  //   setButtonLoading(true);
  //   taskService?.changeTaskStatus(task_id, payload)
  //     .then((res) => {
  //       setButtonLoading(false);
  //       successPopUp(res?.data?.message);
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       setButtonLoading(false);
  //       errorPopUp(err?.message);
  //       setErrors(err?.field_errors);
  //     })
  // };
  const handleUpdateStatus = (task_id: any, task_status: any) => {
    setButtonLoading(true);
    taskService?.changeTaskStatus(task_id, { task_status })
      .then((res) => {
        dispatch(getTasksData());
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        setTasksStatus(task_status);
         fetchData();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  // const taskStatus = CS_TASK_STATUS.find((field: any) => field.value === taskDetail?.task_status);
  // const taskStatusBgColor = taskStatus?.bgColor;
  // const taskStatusColor = taskStatus?.color;
  // const taskStatusName = taskStatus?.name;

  return <Card>
    <CardHeader title='Task Detail' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        />
      </>
    } />

    {showEdit && (
      <TaskEdit
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={editSubmit}
        data={{ task_id }}
      />
    )}

    <SubContent heading="General Details" style={{ display: "flex" }}>
      <LabelDescriptions
        style={{ width: "80%" }}
        list={[
          ["Id", taskDetail?.id],
          ["Title", taskDetail?.title],
          ["Description", taskDetail?.description],
          ["Trigger Time", utcToLocalDatetime(taskDetail?.trigger_on)],
          // ["Task Status", <p style={{ backgroundColor: taskDetail?.task_status_bg_color, color: taskDetail?.task_status_color, padding: '2px 4px' }}>{taskDetail?.task_status_title}</p>],
          ["Task Status", <DropdownA2
            options={tasksStatusList}
            onChange={(value) => handleUpdateStatus(task_id, value)}
            placeholder="Select Status"
            value={tasksStatus}
          />],
          ["Status", <Badge colorTheme='B' text={taskDetail?.status} />],
        ]}
      />
      <FieldGroup column={1} style={{ flexDirection: "column" }}>
        {/* <Dropdown
          label="Task Status"
          name="task_status"
          value={payload?.task_status}
          handleChange={handleChange}
          options={tasksStatusList}
          error={errors?.task_status}
        />
        <ButtonsGroup>
          <Button title="Update Task Status" onClick={handleSubmit} loading={buttonLoading} theme="success" size="small" />
        </ButtonsGroup> */}

      </FieldGroup>
    </SubContent>
    {
      (toNull(taskDetail?.object) != null) ?
        <SubContent heading="Linked To" style={{ display: "flex" }}>
          <LabelDescriptions
            style={{ width: "50%" }}
            list={[
              ["Linked To", "Id: " + taskDetail?.object_id + ', Type: ' + taskDetail?.object],
            ]}
          />
        </SubContent> :
        null
    }
  </Card>
}

export default TaskDetails