import { IconNo, IconYes } from "../screens/Components/Icons";

export const toNull = (input: any) => {
    if (input == undefined) {
        return null;
    } else if (input == null) {
        return null;
    } else if (input == "") {
        return null;
    } else {
        return input;
    }
}

export const toCamelCaseFirst = (str: any) => {
    if (toNull(str) == null) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export const toYesNo=(input:string|boolean|number)=>{
    if(input){
        return <IconYes size="1.4em" />
    } else {
        return <IconNo  size="1.4em"/>
    }
    
}

