import { Button, ButtonsGroup, Card, CardHeader, Dropdown, DropdownA2, FieldGroup, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import taskService from "../../../services/axios/tasks";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import { CS_REMINDER_STATUS } from "../../../utils/constants";
import ReminderEdit from "./ReminderEdit";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import { useDispatch } from "react-redux";
import { getRemindersData } from "../../../store/taskSlice";

const ReminderDetails = () => {
  const { reminder_id } = useParams<{ reminder_id: string }>();
  const [reminderDetail, setReminderDetail] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [statusList, setStatusList] = useState<any>([]);
  const [status, setStatus] = useState<any>(null);
  const [showEdit, setshowEdit] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  useEffect(() => {
    // fetchTasksStatus()
    fetchData()
  }, [reminder_id])

  const editSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  // const fetchTasksStatus = () => {
  //   gLoader(true);
  //   taskService?.quickListTaskStatus()
  //     .then((res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsTasksStatus = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.title,
  //         value: item?.id,
  //       }));
  //       setStatusList(dropdownOptionsTasksStatus);
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message);
  //     })
  // }

  const fetchData = () => {
    gLoader(true);
    taskService?.detailReminder(reminder_id)
      .then((res: any) => {
        gLoader(false);
        setReminderDetail(res?.data?.response?.detail);
        setStatus(res?.data?.response?.detail.reminder_status);
        // setPayload((prevPayload: any) => ({
        //   ...prevPayload,
        //   'status': res?.data?.response?.detail.status,
        //   'reminder_status': res?.data?.response?.detail.reminder_status,
        // }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  // const handleChange = (value: any, name: any) => {
  //   setPayload({
  //     ...payload,
  //     [name]: value,
  //   });

  //   setErrors({
  //     ...errors,
  //     [name]: null
  //   })
  // };

  // const handleSubmit = () => {
  //   setButtonLoading(true);
  //   taskService?.changeReminderStatus(reminder_id, payload)
  //     .then((res) => {
  //       setButtonLoading(false);
  //       successPopUp(res?.data?.message);
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       setButtonLoading(false);
  //       errorPopUp(err?.message);
  //       setErrors(err?.field_errors);
  //     })
  // };

  const handleUpdateStatus = (task_id: any, reminder_status: any) => {
    setButtonLoading(true);
    taskService?.changeReminderStatus(task_id, { reminder_status })
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        setStatusList(reminder_status);
        fetchData();
        dispatch(getRemindersData());
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
        
      })
  };

  const reminderStatus = CS_REMINDER_STATUS.find((field: any) => field.value === reminderDetail?.reminder_status);
  const reminderStatusBgColor = reminderStatus?.bgColor;
  const reminderStatusColor = reminderStatus?.color;
  const reminderStatusName = reminderStatus?.name;

  return <Card>
    <CardHeader title='Reminder Detail' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        />
      </>
    } />

    {showEdit && (
      <ReminderEdit
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={editSubmit}
        data={{ reminder_id }}
      />
    )}

    <SubContent heading="General Details" style={{ display: "flex" }}>
      <LabelDescriptions
        style={{ width: "80%" }}
        list={[
          ["Id", reminderDetail?.id],
          ["Title", reminderDetail?.title],
          ["Description", reminderDetail?.description],
          ["Trigger Time", utcToLocalDatetime(reminderDetail?.trigger_on)],
          // ["Task Status", <p style={{ backgroundColor: reminderStatusBgColor, color: reminderStatusColor, padding: '2px 4px' }}>{reminderStatusName}</p>],
          ["Task Status", <DropdownA2
            options={CS_REMINDER_STATUS}
            onChange={(value) => handleUpdateStatus(reminder_id, value)}
            placeholder="Select Status"
            value={status}
          />],
          ["Status", <Badge colorTheme='B' text={reminderDetail?.status} />],
        ]}
      />
      <FieldGroup column={1}>
        {/* <Dropdown
          label="Reminder Status"
          name="reminder_status"
          value={payload?.reminder_status}
          handleChange={handleChange}
          options={CS_REMINDER_STATUS}
          error={errors?.reminder_status}
        />
        <ButtonsGroup>
          <Button title="Update Reminder Status" onClick={handleSubmit} loading={buttonLoading} theme="success" size="small" />
        </ButtonsGroup> */}

      </FieldGroup>
    </SubContent>
  </Card>
}

export default ReminderDetails