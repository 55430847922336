export const APP_URL = "http://localhost:3000";

export const CS_BOOLEAN_TRUE: any = ['true', 'TRUE', 'True', '1', true, 1];

export const CS_BOOLEAN_FALSE: any = ['false', 'FALSE', 'False', '0', false, 0];

export const CS_FIELD_TYPES = [
    { name: "Boolean", value: "BOOLEAN" },
    { name: "String", value: "STRING" },
    { name: "Date", value: "DATE" },
] //if value changed here change in backend entity enum also

export const CS_STATUS = [
    { name: "Active", value: "ACTIVE" },
    { name: "In-Active", value: "INACTIVE" }
]

export const CS_MAILER_TYPE = [
    { name: "Gmail", value: "GMAIL" },
    { name: "Other", value: "OTHER" }
]

export const CS_CLIENT_VARS = [
    { name: "Firstname", value: "cl_firstname" },
    { name: "Lastname", value: "cl_lastname" },
    { name: "Email", value: "cl_email" },
    { name: "Phone", value: "cl_phone" },
    { name: "Address Line", value: "cl_address_line" },
    { name: "City", value: "cl_city" },
    { name: "Country", value: "cl_country" },
    { name: "Province", value: "cl_province" },
    { name: "Postal Code", value: "cl_postalcode" },


]

// export const CS_TASK_STATUS = [
//     { name: "New", value: "NEW", bgColor: "lightgray", color: "black" },
//     { name: "In Progress", value: "IN_PROGRESS", bgColor: "#e0f2ff", color: "#118eed" },
//     { name: "Cancelled", value: "CANCELLED", bgColor: "#ffd8df", color: "red" },
//     { name: "Completed", value: "COMPLETED", bgColor: "#c0f5d4", color: "#00762e" }
// ]

export const CS_REMINDER_STATUS = [
    { name: "Upcoming", value: "UPCOMING", bgColor: "white", color: "black" },
    { name: "New", value: "NEW", bgColor: "lightgray", color: "black" },
    { name: "Completed", value: "COMPLETED", bgColor: "#c0f5d4", color: "#00762e" },
    { name: "Cancelled", value: "CANCELLED", bgColor: "#ffd8df", color: "red" },
]

export const CS_TASK_OBJECT_TYPE = [
    { name: "Service", value: "SERVICE" },
]

export const CS_TASK_INTERVAL = [
    { name: "Daily", value: "DAILY" },
    { name: "Weekly", value: "WEEKLY" },
    { name: "Monthly", value: "MONTHLY" },
]

export const CS_TASK_SERVICES_INTERVAL = [
    { name: "Daily", value: "DAILY" },
    { name: "Weekly", value: "WEEKLY" },
    { name: "Monthly", value: "MONTHLY" },
    { name: "Quarterly", value: "QUARTERLY" },
    { name: "Yearly", value: "YEARLY" },
]

export const CS_TASK_WEEKDAYS = [
    { name: "Monday", value: "Monday" },
    { name: "Tuesday", value: "Tuesday" },
    { name: "Wednesday", value: "Wednesday" },
    { name: "Thursday", value: "Thursday" },
    { name: "Friday", value: "Friday" },
    { name: "Saturday", value: "Saturday" },
    { name: "Sunday", value: "Sunday" }
];

export const CS_TASK_MONTH_DAYS = [
    { name: "01", value: "01" },
    { name: "02", value: "02" },
    { name: "03", value: "03" },
    { name: "04", value: "04" },
    { name: "05", value: "05" },
    { name: "06", value: "06" },
    { name: "07", value: "07" },
    { name: "08", value: "08" },
    { name: "09", value: "09" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },
    { name: "20", value: "20" },
    { name: "21", value: "21" },
    { name: "22", value: "22" },
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
    { name: "26", value: "26" },
    { name: "27", value: "27" },
    { name: "28", value: "28" },
    { name: "29", value: "29" },
    { name: "30", value: "30" },
    { name: "31", value: "31" },
    { name: "Last Day Of Month", value: "LAST_DAY_OF_MONTH" }
];

export const CS_TASK_MONTHS = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" }
];

export const CS_TASK_QUARTERLY = [
    { name: "January,April,July,October", value: "01" },
    { name: "February,May,August,November", value: "02" },
    { name: "March,June,September,December", value: "03" },
];

export const CS_DOC_STATUS = [
    { name: "Pending", value: "PENDING", bgColor: "lightgray", color: "black" },
    { name: "Cancelled", value: "CANCELLED", bgColor: "#ffd8df", color: "red" },
    { name: "Uploaded", value: "UPLOADED", bgColor: "#c0f5d4", color: "#00762e" }
]

export const CS_PAYMENT_STATUS = [
    { name: "Pending", value: "PENDING", bgColor: "lightgray", color: "black" },
    { name: "Cancelled", value: "CANCELLED", bgColor: "#ffd8df", color: "red" },
    { name: "Paid", value: "PAID", bgColor: "#c0f5d4", color: "#00762e" }
]

export const CS_INVOICE_STATUS = [
    { name: "Draft", value: "DRAFT", bgColor: "lightgray", color: "black" },
    { name: "Invoiced", value: "INVOICED", bgColor: "#e0f2ff", color: "#118eed" },
    { name: "Paid", value: "PAID", bgColor: "#c0f5d4", color: "#00762e" }
]


export const CS_TIMEZONES = [
    { name: "Asia/Kolkata", value: "Asia/Kolkata" },
    { name: "America/Toronto", value: "America/Toronto" }
]

export const CS_MEETING_CLIENT_TYPE = [
    { name: "Existing", value: "EXISTING" },
    { name: "Non-Existing", value: "NON_EXISTING" }
]