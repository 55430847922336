import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import authService from "../services/axios/auth";
// import { AppDispatch } from "../store";

export const getLogin = (param: any) => async (dispatch: any) => {
  return authService
    .login(param)
    .then(async (resp) => {
      dispatch(setIsLoggedOn(true));
      let response = resp?.data?.response;
      dispatch(setAuth(response));
      dispatch(setToken(response?.token));
      let detail = response?.detail;
      dispatch(setIsStripeEnabled(detail?.isStripeEnabled));
      dispatch(setIsGoogleDriveEnabled(detail?.isGoogleDriveEnabled));
      dispatch(setIsMailerEnabled(detail?.isMailerEnabled));
      dispatch(setTimezone(detail?.timezone));
      localStorage?.setItem("token", await resp?.data?.response?.token);
      return resp;
    })
    .catch((error) => {
      dispatch(setAuth(null));
      dispatch(setToken(null));
      dispatch(setIsStripeEnabled(false));
      dispatch(setIsGoogleDriveEnabled(false));
      dispatch(setIsMailerEnabled(false));
      dispatch(setTimezone(null));
      localStorage.removeItem("token");
      throw error;
    });
};

export const getSignup = (param: any) => async (dispatch: any) => {
  return authService.signup(param)
    .then(async (resp) => {
      // dispatch(setIsLoggedOn(true));
      //dispatch(setToken(resp?.data?.response?.token));
      //dispatch(setAuth(resp?.data?.response));
      // localStorage.setItem("token", await resp?.data?.response?.token);
      return resp;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLogout = (navigate: any) => async (dispatch: any) => {
  return authService
    .logout()
    .then(async (res) => {
      dispatch(setAuth(null));
      dispatch(setIsLoggedOn(false));
      dispatch(setToken(null));
      dispatch(setIsStripeEnabled(false));
      dispatch(setIsGoogleDriveEnabled(false));
      dispatch(setIsMailerEnabled(false));
      dispatch(setTimezone(null));
      localStorage.removeItem("token");
      navigate('/auth');
      return res;
    })
    .catch((error) => {
      dispatch(setAuth(null));
      dispatch(setIsLoggedOn(false));
      dispatch(setToken(null));
      dispatch(setIsStripeEnabled(false));
      dispatch(setIsGoogleDriveEnabled(false));
      dispatch(setIsMailerEnabled(false));
      dispatch(setTimezone(null));
      localStorage.removeItem("token");
      navigate('/auth');
      return error;
    });
};

export const sendForgotPassOtp = (param: any) => async (dispatch: any) => {
  return authService.sendForgotPassOtp(param)
    .then(async (resp) => {
      return resp;
    })
    .catch((error) => {
      throw error;
    });
};

export const newPassword = (param: any) => async (dispatch: any) => {
  return authService.newPassword(param)
    .then(async (resp) => {
      return resp;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePassword = (param: any) => async (dispatch: any) => {
  return authService.updatePassword(param)
    .then(async (resp) => {
      return resp;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendVerifyEmailOtp = (param: any) => async (dispatch: any) => {
  return authService.sendVerifyEmailOtp(param)
    .then(async (resp) => {
      return resp;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyEmail = (param: any) => async (dispatch: any) => {
  return authService.verifyEmail(param)
    .then(async (resp) => {
      dispatch(setIsLoggedOn(true));
      dispatch(setToken(resp?.data?.response?.token));
      dispatch(setAuth(resp?.data?.response));
      localStorage.setItem("token", await resp?.data?.response?.token);
      return resp;
    })
    .catch((error) => {
      throw error;
    });
};



interface AuthState {
  auth: any;
  isLoggedIn: boolean;
  token: string | null;
  isStripeEnabled: boolean;
  isGoogleDriveEnabled: boolean;
  isMailerEnabled: boolean;
  timezone: string | null;
}

const initialState: AuthState = {
  auth: null,
  isLoggedIn: false,
  token: null,
  isStripeEnabled: false,
  isGoogleDriveEnabled: false,
  isMailerEnabled: false,
  timezone: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.auth = action.payload;
    },
    setIsLoggedOn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
    },
    setIsStripeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isStripeEnabled = action.payload
    },
    setIsGoogleDriveEnabled: (state, action: PayloadAction<boolean>) => {
      state.isGoogleDriveEnabled = action.payload
    },
    setIsMailerEnabled: (state, action: PayloadAction<boolean>) => {
      state.isMailerEnabled = action.payload
    },
    setTimezone: (state, action: PayloadAction<string | null>) => {
      state.timezone = action.payload
    },
  },
});

export const { setAuth, setIsLoggedOn, setToken, setIsStripeEnabled, setIsGoogleDriveEnabled, setIsMailerEnabled, setTimezone } = authSlice.actions;

export default authSlice.reducer;
