import { Button, ButtonsGroup, Card, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSignup } from "../../store/authSlice";
import { TextLink } from "../Components";
import AuthLayout from "./AuthLayout";

const Signup = () => {
    const [payload, setPayload] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleChange = (value: any, name: any, item: any) => {
        setPayload({
            ...payload,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: null
        })
    };

    const handleSubmit = () => {
        dispatch(getSignup(payload))
            .then((res: any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
                navigate(`/auth/verify-email?email=${payload?.email}`);
                //window.location.reload();
            })
            .catch((err: any) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
        //   onSuccess();
        //   successPopUp('submit succes')

    };

    return <AuthLayout
    heading="Sign Up"
    description={<p>Please your details to Sign Up</p>}
    >
        <Input
            label="Email"
            name="email"
            handleChange={handleChange}
            value={payload?.email}
            error={errors?.email}
        />
        <Input
            label="Password"
            name="password"
            handleChange={handleChange}
            value={payload?.password}
            error={errors?.password}
            secured
        />
        <Input
            label="Company Name"
            name="company_name"
            handleChange={handleChange}
            value={payload?.company_name}
            error={errors?.company_name}
        />
        <ButtonsGroup>
            <Button title="Sign Up" onClick={handleSubmit} loading={buttonLoading} theme="success" />
        </ButtonsGroup>
        <br />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            Already have account ?
            <br />
            <TextLink text="Login" title="Login" goto="/auth" />
            <br />

            <br />
        </div>
    </AuthLayout>
}

export default Signup;