import {
  Button,
  ButtonsGroup,
  Card,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useRef, useState } from "react";
import { ModalFormProps } from "../../../../../types";
import emailService from "../../../../../services/axios/settings/communication/email";
import clientService from "../../../../../services/axios/clients";
import { CS_CLIENT_VARS } from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

const AddEmailTemplates = () => {
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [insertVars, setInsertVars] = useState<any>([]);
  const navigate = useNavigate()
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    fetchClientCustomFields();
  }, [])

  // const handleInsertValue = (value: string) => {
  //   if (!textAreaRef.current) return;
  //   const textarea = textAreaRef.current;
  //   const { selectionStart, selectionEnd } = textarea;
  //   const formattedValue = `{{${value}}}`;
  //   textarea.value =
  //     textarea.value.substring(0, selectionStart) +
  //     formattedValue +
  //     textarea.value.substring(selectionEnd);
  //   textarea.selectionStart = selectionStart + formattedValue.length;
  //   textarea.selectionEnd = selectionStart + formattedValue.length;
  //   setPayload({
  //     ...payload,
  //     description: textarea.value,
  //   });
  // };

  const handleInsertValue = (value: string) => {
    if (!textAreaRef.current) return;
    const textarea = textAreaRef.current;
    textarea.focus();
    const { selectionStart, selectionEnd } = textarea;
    const formattedValue = `{{${value}}}`;
    textarea.value =
      textarea.value.substring(0, selectionStart) +
      formattedValue +
      textarea.value.substring(selectionEnd);
    textarea.selectionStart = selectionStart + formattedValue.length;
    textarea.selectionEnd = selectionStart + formattedValue.length;
    setPayload({
      ...payload,
      description: textarea.value,
    });
    setShowModal(false);
  };

  const fetchClientCustomFields = () => {
    gLoader(true);
    clientService?.quickListClientProfileCustomField()
      .then((res: any) => {
        gLoader(false);
        const insertOptions = res?.data?.response?.list?.map((item: any) => ({
            name: item?.name,
            value: `clcfid_`+item?.custom_field_id
          }));
          setInsertVars([...CS_CLIENT_VARS, ...insertOptions]);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  // const handleDefaultTemplateChange = (value: any, name: string) => {
  //   handleInsertValue(value);
  // };

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    emailService?.addEmailTemplate(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        navigate(`/settings/communication/email/templates`)
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  return (
    <>
      <Card>
        <CardHeader title="Add Email Template" />
        <FieldGroup column={1}>
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <Input
            label="Subject"
            name="subject"
            handleChange={handleChange}
            value={payload?.subject}
            error={errors?.subject}
          />
          <textarea
            ref={textAreaRef}
            value={payload?.description}
            onChange={(e) => handleChange(e.target.value, 'description')}
            placeholder="Add Email Description Here ..."
            style={{
              width: '100%',
              height: '400px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
          {/* <Dropdown
            label="Default Service Templates"
            name="default_service_template"
            // value={clientVar}
            handleChange={(value, name) => { handleDefaultTemplateChange(value, name) }}
            options={CS_CLIENT_VARS}
          /> */}
        </FieldGroup>

        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Insert Values" onClick={() => setShowModal(true)} theme="primary" />
        </ButtonsGroup>

        <Modal show={showModal} style={{ width: "500px", alignContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          <FieldGroup column={1}>
            <div style={{ height: '300px', width: '200px', overflowY: 'scroll' }}>
              {insertVars.map((option: any) => (
                <div key={option.value} onClick={() => handleInsertValue(option.value)}>
                  {option.name}
                </div>
              ))}
            </div>
          </FieldGroup>

          <Button title="Close" onClick={() => setShowModal(false)} theme="danger" />

        </Modal>
      </Card>
    </>
  );
};

export default AddEmailTemplates;
