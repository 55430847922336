import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface dataType {
  sortByName: string;
  sortByOrder: "ASC" | "DESC";
}

export function useListSorting({ listName }: { listName?: string | null }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<dataType>({
    sortByName: "",
    sortByOrder: "ASC",
  });

  useEffect(() => {
    setData({
      sortByName: searchParams.get("sortByName") || "",
      sortByOrder: searchParams.get("sortByOrder") == "DESC" ? "DESC" : "ASC",
    });
  }, [searchParams]);
  const setDataValue = (key: string, value: string) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set(key, value);
    setSearchParams(newUrl.search);
  };

  const setSortByName = (sortByName: string) => {
    setDataValue("sortByName", sortByName.toString());
  };

  const setSortByOrder = (sortByOrderRaw: 'ASC' | 'DESC' | string) => {

    let sortByOrder = 'ASC'
    if(sortByOrderRaw == 'DESC'){
        sortByOrder = 'DESC'
    }
    setDataValue("sortByOrder", sortByOrder);
  };

  return {
    listName,
    sortByName: data?.sortByName,
    sortByOrder: data?.sortByOrder,
    setSortByName,
    setSortByOrder,
  };
}
