import { Button, ButtonsGroup, Card, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLogin } from "../../store/authSlice";
import { TextLink } from "../Components";
import AuthLayout from "./AuthLayout";

const Login = () => {
  const [payload, setPayload] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false)
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const handleChange = (value: any, name: any, item: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    dispatch(getLogin(payload))
      .then((res: any) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        navigate('/');
      })
      .catch((err: any) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  return <AuthLayout
  heading="Welcome Back"
  description={<p>Please Enter your email & passsword to Sign In</p>}
  >
    <Input
      label="Email"
      name="email"
      handleChange={handleChange}
      value={payload?.email}
      error={errors?.email}
    />
    <Input
      label="Password"
      name="password"
      handleChange={handleChange}
      value={payload?.password}
      error={errors?.password}
      secured
    />
    <div style={{ textAlign: 'right' }}>
      <TextLink mini theme="light-gray" text="Forgot Password ?" title="Forgot Password ?" goto="/auth/forgot-password" />
    </div>

    <ButtonsGroup>
      <Button loading={buttonLoading} title="Sign In" onClick={handleSubmit} theme="success" />
    </ButtonsGroup>

    <br />
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      Don't have an account ?
      <br />
      <TextLink text="Signup Now" title="Signup" goto="/auth/signup" />
      <br />

      <br />
    </div>
  </AuthLayout>
}

export default Login;