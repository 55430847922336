import { Children, FC, ReactNode } from "react"
import './TableForm.css'
import { Label } from "asbawa-react-ui-ts-test-b";
interface TableFormProps {
    headers:{
        title:string,
        width:string
    }[];
    children:ReactNode,
    onAddRow?:()=>void
}

const TableForm:FC<TableFormProps> =({
    headers,
    children,
    onAddRow
}):JSX.Element=>{

    return <>
    <table className="table-form">
        <thead>
            <tr>
                {headers?.map((header,index)=>{
                    return <td style={{width:header?.width}} key={index}><Label text={header.title}/></td>
                })}
            </tr>
        </thead>
        <tbody>
            {children}
        </tbody>
        {
            onAddRow &&
            <tfoot>
                <tr>
                    <td colSpan={headers.length} style={{textAlign:'right'}}> <span style={{color:'gray',cursor:'pointer'}} onClick={onAddRow}>+ Add Row</span></td>
                </tr>
            </tfoot>
        }
    </table>
    </>



}

export default TableForm