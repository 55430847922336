import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "../../..";

class clientCommunicationService {
    //client Communication
    listClientCommunication = async (urlParam = {}) => {
        return await getRequest(`company/communication/client`, urlParam);
    }
    detailClientCommunication = async (id: any, urlParam = {}) => {
        return await getRequest(`company/communication/client/${id}`, urlParam);
    }
    deleteClientCommunication = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/communication/client/${id}`, urlParam);
    }

}

const instance = new clientCommunicationService();

export default instance;

