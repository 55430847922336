import { Button, ButtonsGroup, Card, CardHeader, Dropdown, FieldGroup, Input, InputFile, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import "./MailerSettings.css";
import { useEffect, useState } from "react";
import authService from "../../../services/axios/auth";
import { CS_MAILER_TYPE } from "../../../utils/constants";
import emailService from "../../../services/axios/settings/communication/email";
const MailerSettings = () => {
    const [payload, setPayload] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        gLoader(true);
        emailService?.detailMailerConfigs()
            .then((res: any) => {
                gLoader(false);
                let newDetail = res?.data?.response?.detail;
                setPayload((prevPayload: any) => ({
                    ...prevPayload,
                    ...newDetail
                }));
            })
            .catch((err: any) => {
                gLoader(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    }

    const handleSubmit = () => {
        setButtonLoading(true);
        emailService?.addMailerConfigs(payload)
            .then((res) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
            })
            .catch((err) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    };

    const handleChange = (value: any, name: any) => {
        setPayload({
            ...payload,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: null
        })
    };

    const handleChangeMailer = (value: any, name: any) => {
        let mailerConfigs = { ...payload.mailer_credentials };
        mailerConfigs[name] = value;
        setPayload({
            ...payload,
            ['mailer_credentials']: mailerConfigs,
        });
        setErrors({
            ...errors,
            [name]: null
        })
    };

    return (
        <>
            <Card style={{ maxWidth: '800px' }}>
                <CardHeader
                    title="Mailer Settings"
                />
                <div className="prof-sett-top" >
                    <div className="right-form">
                        <FieldGroup column={1}>
                            <Dropdown
                                label="Mailer Type"
                                name="mailer_service_type"
                                value={payload?.mailer_service_type}
                                handleChange={handleChange}
                                options={CS_MAILER_TYPE}
                                error={errors?.mailer_service_type}
                                required
                            />
                            <Input
                                label="Username"
                                name="username"
                                handleChange={handleChangeMailer}
                                value={payload?.mailer_credentials?.username}
                                error={errors?.username}
                            />
                            <Input
                                label="Password"
                                name="password"
                                handleChange={handleChangeMailer}
                                value={payload?.mailer_credentials?.password}
                                error={errors?.password}
                            />

                            {
                                payload?.mailer_service_type == 'OTHER' ? (
                                    <>
                                        <Input
                                            label="Hostname"
                                            name="host"
                                            handleChange={handleChangeMailer}
                                            value={payload?.mailer_credentials?.host}
                                            error={errors?.host}
                                        />
                                        <Input
                                            label="Port"
                                            name="port"
                                            handleChange={handleChangeMailer}
                                            value={payload?.mailer_credentials?.port}
                                            error={errors?.port}
                                        />
                                    </>
                                ) : null
                            }


                        </FieldGroup>
                        <ButtonsGroup>
                            <Button title="Update" onClick={handleSubmit} theme="success" loading={buttonLoading} />
                        </ButtonsGroup>
                    </div>

                </div>

            </Card>

        </>
    );
};

export default MailerSettings;
