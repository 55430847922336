import { Button, ButtonsGroup, Card, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSignup, sendVerifyEmailOtp } from "../../store/authSlice";

const SendVerifyEmailOtp = () => {
    const [payload, setPayload] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleChange = (value: any, name: any, item: any) => {
        setPayload({
            ...payload,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: null
        })
    };

    const handleSubmit = () => {
        dispatch(sendVerifyEmailOtp(payload))
            .then((res: any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
                navigate(`/auth/verify-email/${payload?.email}`);
            })
            .catch((err: any) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    };

    return <>
        <Input
            label="Email"
            name="email"
            handleChange={handleChange}
            value={payload?.email}
            error={errors?.email}
        />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <a href="/auth" style={{ margin: "10px" }}>Login</a>
        </div>
        <ButtonsGroup>
            <Button title="Get Otp On Email" onClick={handleSubmit} loading={buttonLoading} theme="success" />
        </ButtonsGroup>
    </>
}

export default SendVerifyEmailOtp;