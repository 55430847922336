import "./style.css";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import service from "../../../../../services/axios/settings/companyServices";
import { Badge, TextLink } from "../../../../Components";
import MainContent from "../../../../Components/MainContent";
import ListRowActions, { ListPagination, ListSearch } from "../../../../Components/List";
import documentService from "../../../../../services/axios/documents";
import emailService from "../../../../../services/axios/settings/communication/email";
import AddEmailTemplates from "./AddEmailTemplates";
import { useListFilters } from "../../../../../hooks/useListFilters";
import { useListSorting } from "../../../../../hooks/useListSorting";
import { useListPagination } from "../../../../../hooks/useListPagination";
const EmailTemplates = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize,filters,search])

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    emailService?.listEmailTemplate({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    emailService?.deleteEmailTemplate(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <Card>
     {/* <ListSearch/> */}

      <CardHeader title="Email Templates"
      searchBarProps={{ onSearch: (value) => setSearch(value) }}
      searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Add New"
              theme="success"
              // onClick={() => setShowAddNew(true)}
              onClick={() => navigate(`add`)}
            />
          </>
        } />
      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            return (
              <tr key={index}>
                <td><TextLink text={itm.id} title="View Email Template" goto={`${itm.id}`} /></td>
                <td><TextLink text={itm?.title} title="View Email Template" goto={`${itm.id}`} /></td>
                <td>{itm?.subject}</td>
                <td style={{ display: 'flex' }}><Badge colorTheme='B' text={itm?.status} /></td>
                <td>
                  <ListRowActions
                    actions={[
                      { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                      { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                    ]}
                  /></td>
              </tr>
            );
          })}
        </ListBody>
      </List>
      <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
    </Card>
  );
};

export default EmailTemplates;

const listHeaders: ListHeadType[] = [
  {
    title: "Id",
    width: "10%",
    sortName: "uid",
    align: "center",
  },
  {
    title: "Title",
    width: "25%",
    align: "center",
  },
  {
    title: "Subject",
    width: "25%",
    align: "center",
  },
  {
    title: "Status",
    width: "10%",
    align: "center",
  },
  {
    title: "",
    width: "20%",
    align: "center",
  }
];
