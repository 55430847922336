import moment from 'moment';
import 'moment-timezone';
import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import { ModalFormProps } from "../../../types";
import taskService from "../../../services/axios/tasks";

const ReminderAdd: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleChange = (value: any, name: any) => {
    let trigger_on = '';
    if (name === "trigger_date") {
      let trigger_time = payload?.trigger_time;
      trigger_on = `${value} ${trigger_time}`;
    }
    if (name === "trigger_time") {
      let trigger_date = payload?.trigger_date;
      trigger_on = `${trigger_date} ${value}`;
    }
    setPayload({
      ...payload,
      [name]: value,
      ['trigger_on']: trigger_on,
    });
    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    taskService?.addReminder(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Add Reminder" />
        <FieldGroup column={3}>
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <textarea
            value={payload?.description}
            onChange={(e) => handleChange(e.target.value, 'description')}
            placeholder="Add Description Here ..."
            style={{
              width: '100%',
              height: '100px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
          {/* <Input
            label="Trigger On"
            name="trigger_on"
            handleChange={handleChange}
            value={payload?.trigger_on}
            error={errors?.trigger_on}
          /> */}
          <InputDate
            label="Trigger Date"
            name="trigger_date"
            handleChange={handleChange}
            value={payload?.trigger_date}
            error={errors?.trigger_date}
          />
          <InputTime
            label="Trigger Time"
            name="trigger_time"
            handleChange={handleChange}
            value={payload?.trigger_time}
            error={errors?.trigger_time}
          />
        </FieldGroup>

        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default ReminderAdd;
