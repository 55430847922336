import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import EditServicesTypes from "./EditClientProfileCustomFields";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import EditDocumentsTypes from "./EditClientProfileCustomFields";
import documentService from "../../../services/axios/documents";
import EditClientProfileCustomFields from "./EditClientProfileCustomFields";
import clientService from "../../../services/axios/clients";
import { toCamelCaseFirst } from "../../../utils/commonFunctions";
import { CS_BOOLEAN_TRUE } from "../../../utils/constants";

const DetailClientProfileCustomFields = () => {
  const { client_profile_custom_field_id } = useParams<{ client_profile_custom_field_id: string }>();
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [clientProfileCustomFieldDetail, setClientProfileCustomFieldDetail] = useState<any>([]);

  const handleSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchData()
  }, [client_profile_custom_field_id])

  const fetchData = () => {
    gLoader(true);
    clientService?.detailClientProfileCustomField(client_profile_custom_field_id)
      .then((res: any) => {
        gLoader(false);
        setClientProfileCustomFieldDetail(res?.data?.response?.detail)
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })

  }
  return <Card>
    <CardHeader title='Client Profile Custom Fields Detail' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        /></>
    } />

    {showEdit && (
      <EditClientProfileCustomFields
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={handleSubmit}
        data={{ client_profile_custom_field_id }}
      />
    )}

    <SubContent heading="" style={{ display: "flex" }}>
      <LabelDescriptions
        style={{ width: "50%" }}
        list={[
          ["Id", clientProfileCustomFieldDetail?.id],
          ["Name", clientProfileCustomFieldDetail?.name],
          ["Type", toCamelCaseFirst(clientProfileCustomFieldDetail?.type)],
          ["Is Required", CS_BOOLEAN_TRUE.includes(clientProfileCustomFieldDetail?.is_required) ? "true" : "false"],
          ["Status", <Badge colorTheme='B' text={clientProfileCustomFieldDetail?.status} />],
        ]}
      />
    </SubContent>
  </Card>
}

export default DetailClientProfileCustomFields;
