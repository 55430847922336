
import { Button, Card, CardHeader, confirmPopUp, errorPopUp, gLoader, Input, List, ListBody, ListHeadType, successPopUp, Td } from "asbawa-react-ui-ts-test-b";
import { Badge, TextLink } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import clientCommunicationService from "../../../services/axios/settings/communication/clientCommunication";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
const ClientCommunication = (): JSX.Element | null => {
  const { client_id } = useParams<{ client_id: string }>();
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
  }, [client_id, pageNo, pageSize, search])

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    clientCommunicationService?.listClientCommunication({ client_id, pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    clientCommunicationService?.deleteClientCommunication(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return <>
    <Card>
      <CardHeader
        title="Communication Emails"
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
          </>
        }
      />
      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            return (
              <tr key={index}>
                <Td align="left"><TextLink goto={ `${itm.id}`} text={itm?.email_subject}/></Td>
                <Td >{utcToLocalDatetime(itm?.sent_at)}</Td>
              </tr>
            );
          })}
        </ListBody>
      </List>
      <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
    </Card>
  </>
};

export default ClientCommunication;
const listHeaders: ListHeadType[] = [
  {
    title: "Subject",
    width: "80%",
    align: "left",
  },
  {
    title: "Sent at",
    width: "20%",
    align: "center",
  },
];