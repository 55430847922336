import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class authService {

    login = async (payload: any) => {
        return await postRequest('company/auth/login', payload);
    }
    signup = async (payload: any) => {
        return await postRequest('company/auth/sign-up', payload);
    }
    logout = async (urlParam = {}) => {
        return await getRequest('company/auth/logout', urlParam);
    }
    sendForgotPassOtp = async (payload: any) => {
        return await postRequest('company/auth/forgot-password', payload);
    }
    newPassword = async (payload: any) => {
        return await postRequest('company/auth/new-password', payload);
    }
    updatePassword = async (payload: any) => {
        return await putRequest('company/auth/update-password', payload);
    }
    sendVerifyEmailOtp = async (payload: any) => {
        return await postRequest('company/auth/send-verifyemail-otp', payload);
    }
    verifyEmail = async (payload: any) => {
        return await postRequest('company/auth/verify-email', payload);
    }
    updateProfile = async (payload: any) => {
        return await putRequest('company/auth/profile', payload);
    }
    profile = async (urlParam = {}) => {
        return await getRequest('company/auth/profile', urlParam);
    }
    updateProfileImg = async (urlParam = {}) => {
        return await postFormdata('company/auth/profile/image', urlParam);
    }

}

const instance = new authService();

export default instance;

