import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";
import { CS_STATUS, CS_TASK_INTERVAL, CS_TASK_MONTH_DAYS, CS_TASK_OBJECT_TYPE, CS_TASK_WEEKDAYS } from "../../../utils/constants";
import taskService from "../../../services/axios/tasks";
import { toNull } from "../../../utils/commonFunctions";
import companyServices from "../../../services/axios/settings/companyServices";
import emailService from "../../../services/axios/settings/communication/email";

const CommunicationRecurrenceEdit: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({ object: data?.object, client_id: data?.client_id, object_id: data?.object_id });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [emailTemplateQuickList, setEmailTemplateQuickList] = useState<any>([]);
  // const [clientsList, setClientsList] = useState<any>([]);
  // const [servicesList, setServicesList] = useState<any>([]);

  useEffect(() => {
    fetchEmailTemplatesQuickList();
    fetchData();
  }, [])

  const fetchEmailTemplatesQuickList = async () => {
    gLoader(true);
    emailService?.quickListEmailTemplate()
      .then(async (res: any) => {
        gLoader(false);
        const dropdownOptions = res?.data?.response?.list?.map((item: any) => ({
          name: item?.title,
          value: item?.id,
        }));
        setEmailTemplateQuickList(dropdownOptions);
        // await fetchServiceQuickList();
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const fetchData = () => {
    gLoader(true);
    taskService?.detailCommunicationReccurence(data?.task_schedule_recurrence_id)
      .then((res: any) => {
        gLoader(false);
        let detail = res?.data?.response?.detail;
        setPayload(detail);
        // if (detail?.object == "SERVICE") {
        //   fetchClientQuickList();
        //   fetchServiceQuickList(detail?.client_id)
        // }
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  // const fetchClientQuickList = async () => {
  //   gLoader(true);
  //   companyServices?.quickListOfClientsWithServices()
  //     .then(async (res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.client_firstname + " " + item?.client_lastname,
  //         value: item?.client_id,
  //       }));
  //       setClientsList(dropdownOptionsFolders);
  //       // await fetchServiceQuickList();
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message)
  //     })
  // }
  // const fetchServiceQuickList = async (value: any) => {
  //   gLoader(true);
  //   companyServices?.quickListCompanyServiceToClient({ client_id: value })
  //     .then((res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.services_type_title,
  //         value: item?.id,
  //       }));
  //       setServicesList(dropdownOptionsFolders);
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message)
  //     })
  // }

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })

    // if (name === "object" && toNull(value) != null) {
    //   fetchClientQuickList();
    // }
    // if (name === "client_id" && toNull(value) != null) {
    //   setServicesList([]);
    //   fetchServiceQuickList(value);
    // } else if (name === "client_id" && toNull(value) == null) {
    //   setServicesList([]);
    // }
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    taskService?.updateCommunicationReccurence(data?.task_schedule_recurrence_id, payload)
      .then((res) => {
        // gLoader(false);
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
        //navigate('/')
      })
      .catch((err) => {
        // gLoader(false);
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Edit Event" />
        <FieldGroup column={1}>
          <Dropdown
            label="Email Template"
            name="email_template_id"
            value={payload?.email_template_id}
            handleChange={handleChange}
            options={emailTemplateQuickList}
            error={errors?.email_template_id}
          />

          <Dropdown
            label="Trigger Interval"
            name="trigger_interval"
            value={payload?.trigger_interval}
            handleChange={handleChange}
            options={CS_TASK_INTERVAL}
            error={errors?.trigger_interval}
          />

          <InputTime
            label="Trigger Time"
            name="trigger_time"
            handleChange={handleChange}
            value={payload?.trigger_time}
            error={errors?.trigger_time}
          />
          {
            (payload?.trigger_interval == "WEEKLY") ?
              <Dropdown
                label="Trigger On"
                name="trigger_on"
                value={payload?.trigger_on}
                handleChange={handleChange}
                options={CS_TASK_WEEKDAYS}
                error={errors?.trigger_on}
              />
              :
              (payload?.trigger_interval == "MONTHLY") ?
                <Dropdown
                  label="Trigger On"
                  name="trigger_on"
                  value={payload?.trigger_on}
                  handleChange={handleChange}
                  options={CS_TASK_MONTH_DAYS}
                  error={errors?.trigger_on}
                />
                :
                null
          }
        </FieldGroup>
        {
          (payload?.object == "SERVICE") ?
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "40px" }}>
              <span><b>Linked To:</b> Service</span>
              <span><b>Client:</b> {payload?.client_firstname} {payload?.client_lastname}</span>
              <span><b>Service:</b> {payload?.service_type_title}</span>
            </div>
            :
            null
        }




        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default CommunicationRecurrenceEdit;
