import { FC } from "react"
import { useNavigate } from "react-router-dom"
import './Tabs.css'
import { Button } from "asbawa-react-ui-ts-test-b"
import { Badge, TextLink } from ".."
import { IconAngleLeft, IconSettings, IconView } from "../Icons"
interface TabsProps {
    links:{
      title:string,
      goto:string
    }[],
    showBack?:boolean
  }
  
  const Tabs:FC<TabsProps> = ({links,showBack=true}):JSX.Element => {
  
    const navigate = useNavigate()
  
  return <>
  
  {showBack && <div style={{display:'flex',justifyContent:'flex-start'}}><Badge icon={<IconAngleLeft size="1em"/>} text="Back" color="var(--color-primary)" bgColor="transparent" onClick={()=>navigate(-1)}/></div> }
  <ul className="tabs">
    
    {
      links.map((link,index)=>{
        return <li key={index} onClick={()=>navigate(link.goto)}>{link.title}</li>
      })
    }
  </ul></>
  
  }

  export default Tabs