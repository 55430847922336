import "./style.css";
import MainContent from "../Components/MainContent";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  Input,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
// import AddClient from "./AddClient";
import { useNavigate } from "react-router-dom";
import { Badge, TextLink } from "../Components";
import clientService from "../../services/axios/clients";
import ListRowActions, { ListPagination, ListSearch } from "../Components/List";
import { IconDocuments, IconServices } from "../Components/Icons";
// import AskClientPayment from "./ClientPayments/AskClientPayment";
// import AddInvoice from "./ClientInvoices/AddInvoice";
import { useListPagination } from "../../hooks/useListPagination";
import { useListFilters } from "../../hooks/useListFilters";
import { useListSorting } from "../../hooks/useListSorting";
import businessService from "../../services/axios/businesses";
// import ImportClientData from "./ImportClientData";
const Businesses = () => {
  const [showImportFile, setShowImportFile] = useState<boolean>(false);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showAskPayment, setShowAskPayment] = useState<boolean>(false);
  const [showAddInvoice, setShowAddInvoice] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [client_id, setClient_id] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize, filters, search])

  const fetchData = () => {
    gLoader(true);
    businessService?.list({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        let response = res?.data?.response;
        setList(response?.list);
        setTotalPages(response?.dataFilter?.totalPages);
        setTotalCount(response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const handleSubmitFile = () => {
    fetchData();
    setShowImportFile(false);
  };

  const handleDelete = (id: any) => {
    gLoader(true);
    clientService?.deleteClient(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  const handleAskForPayment = (client_id: any) => {
    setClient_id(client_id);
    setShowAskPayment(true);
  }
  const handleAddInvoice = (client_id: any) => {
    setClient_id(client_id);
    setShowAddInvoice(true);
  }

  const onPaymentSuccess = () => {
    setShowAskPayment(false);
    setClient_id(null);
  };

  const onInvoiceSuccess = () => {
    setShowAddInvoice(false);
    setClient_id(null);
  };

  return (
    <MainContent
    

    >
      <CardHeader
        title="Businesses"
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            {/* <Button
              size="small"
              title="Import Excel/Csv"
              theme="primary"
              onClick={() => setShowImportFile(true)}
            />
            <Button
              size="small"
              title="Add New"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            /> */}
          </>
        }
      />
      {/* {showImportFile && (
        <ImportClientData
          show={showImportFile}
          onCancel={() => setShowImportFile(false)}
          onSuccess={handleSubmitFile}
        />
      )}

      {showAddNew && (
        <AddClient
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleSubmit}
        />
      )} */}

      <Card
      style={{ maxWidth: '800px' }}>
        {/* <ListSearch /> */}
        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <Td align="left"><TextLink text={itm?.name} title="View business" goto={`/client/${itm.id}`} /></Td>
                  <Td align="left"><TextLink text={itm?.client?.firstname + ' ' + itm?.client?.lastname} title="View client" goto={`/client/${itm?.client?.id}`} /></Td>
                  <Td align="left"></Td>
{/*                   
                  <Td>
                    <ListRowActions

                      actions={[
                        { icon: 'view', onClick: () => navigate(`/client/${itm.id}`) },
                        { icon: 'customIcon', customIcon: <IconServices onClick={() => navigate(`/client/${itm.id}/services`)} size='15px' title="Services" /> },
                        { icon: 'customIcon', customIcon: <IconDocuments onClick={() => navigate(`/client/${itm.id}/documents`)} size='15px' title="Documents" /> },
                        { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                      ]}
                    />
                  </Td> */}
                  {/* <Td>
                    <Button title="Ask For Payment" onClick={() => handleAskForPayment(itm.id)} size="small" />
                    <Button title="Add Invoice" onClick={() => handleAddInvoice(itm.id)} size="small" />
                  </Td> */}
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
{/* 
      {showAskPayment && (
        <AskClientPayment
          show={showAskPayment}
          onCancel={() => setShowAskPayment(false)}
          onSuccess={onPaymentSuccess}
          data={{ client_id }}
        />
      )}

      {showAddInvoice && (
        <AddInvoice
          show={showAddInvoice}
          onCancel={() => setShowAddInvoice(false)}
          onSuccess={onInvoiceSuccess}
          data={{ client_id }}
        />
      )} */}
    </MainContent>
  );
};

export default Businesses;

const listHeaders: ListHeadType[] = [
  {
    title: "Name",
    width: "40%",
    sortName: "anme",
    align: "left",
  },
  {
    title: "Parent Client",
    width: "40%",
    align: "left",
  },
//   {
//     title: "Contact",
//     width: "14%",
//     align: "left",
//   },
//   {
//     title: "Address Line",
//     width: "20%",
//     align: "left",
//   },
//   {
//     title: "",
//     width: "24%",
//     align: "right",
//   },
  {
    title: "",
    width: "20%",
    align: "right",
  },
];

