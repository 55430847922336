import "./style.css";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import { Badge, TextLink } from "../../Components";
import MainContent from "../../Components/MainContent";
import AddServicesTypes from "./AddServicesTypes";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { useListPagination } from "../../../hooks/useListPagination";
import { toYesNo } from "../../../utils/commonFunctions";
import { CS_BOOLEAN_TRUE } from "../../../utils/constants";
const ListServicesTypes = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize, filters, search])

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    service?.listCompanyServiceType({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    service?.deleteCompanyServiceType(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <MainContent

    >
      <CardHeader
        title="Service Types"

        rightButtons={
          <>
            <Button
              size="small"
              title="Add New"
              theme="success"
              onClick={() => setShowAddNew(true)}
            />

          </>
        }
      />
      {showAddNew && (
        <AddServicesTypes
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleSubmit}
        />
      )}

      <Card style={{ maxWidth: '800px' }}>

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <Td align="left"><TextLink text={itm?.title} title="View Service Type" goto={`${itm.id}`} /></Td>
                  <Td>{toYesNo(CS_BOOLEAN_TRUE.includes(itm?.has_recurrence))}</Td>
                  <Td align="left">{itm?.category}</Td>
                  <Td><ListRowActions
                    actions={[
                      { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                      { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                    ]}
                  /></Td>
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
    </MainContent>
  );
};

export default ListServicesTypes;

const listHeaders: ListHeadType[] = [
  {
    title: "Title",
    width: "40%",
    align: "left",
  },
  {
    title: "Has Recurrence",
    width: "20%",
    align: "left",
  },
  {
    title: "Category",
    width: "20%",
    align: "left",
  },
  {
    title: "",
    width: "20%",
    align: "right",
  }
];
