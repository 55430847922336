import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";
import { CS_STATUS, CS_TASK_INTERVAL, CS_TASK_MONTH_DAYS, CS_TASK_OBJECT_TYPE, CS_TASK_WEEKDAYS } from "../../../utils/constants";
import taskService from "../../../services/axios/tasks";
import { toNull } from "../../../utils/commonFunctions";
import companyServices from "../../../services/axios/settings/companyServices";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";


const TaskEdit: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({ object: data?.object, client_id: data?.client_id, object_id: data?.object_id });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  // const [clientsList, setClientsList] = useState<any>([]);
  // const [servicesList, setServicesList] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    gLoader(true);
    taskService?.detailTask(data?.task_id)
      .then((res: any) => {
        gLoader(false);
        let detail = res?.data?.response?.detail;
        let triggerDate = '';
        let triggerTime = '';
        if (toNull(detail.trigger_on) != null) {
          let triggerDateTime = utcToLocalDatetime(detail?.trigger_on)?.split(' ');
          triggerDate = triggerDateTime[0];
          triggerTime = triggerDateTime[1];
        }
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          ...detail,
          trigger_date: triggerDate,
          trigger_time: triggerTime,
          trigger_on: triggerDate + ' ' + triggerTime,
        }));
        // if (detail?.object == "SERVICE") {
        //   fetchClientQuickList();
        //   fetchServiceQuickList(detail?.client_id)
        // }
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  // const fetchClientQuickList = async () => {
  //   gLoader(true);
  //   companyServices?.quickListOfClientsWithServices()
  //     .then(async (res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.client_firstname + " " + item?.client_lastname,
  //         value: item?.client_id,
  //       }));
  //       setClientsList(dropdownOptionsFolders);
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message)
  //     })
  // }
  // const fetchServiceQuickList = async (value: any) => {
  //   gLoader(true);
  //   companyServices?.quickListCompanyServiceToClient({ client_id: value })
  //     .then((res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.services_type_title,
  //         value: item?.id,
  //       }));
  //       setServicesList(dropdownOptionsFolders);
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message)
  //     })
  // }

  const handleChange = (value: any, name: any) => {
    let trigger_on = '';
    if (name === "trigger_date") {
      let trigger_time = payload?.trigger_time;
      trigger_on = `${value} ${trigger_time}`;
    }
    if (name === "trigger_time") {
      let trigger_date = payload?.trigger_date;
      trigger_on = `${trigger_date} ${value}`;
    }
    setPayload({
      ...payload,
      [name]: value,
      ['trigger_on']: trigger_on,
    });
    setErrors({
      ...errors,
      [name]: null
    })

    // if (name === "object" && toNull(value) != null) {
    //   fetchClientQuickList();
    // }
    // if (name === "client_id" && toNull(value) != null) {
    //   setServicesList([]);
    //   fetchServiceQuickList(value);
    // } else if (name === "client_id" && toNull(value) == null) {
    //   setServicesList([]);
    // }
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    taskService?.updateTask(data?.task_id, payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  // const getTimezoneOffset = () => {
  //   const offset = new Date().getTimezoneOffset(); // Get timezone offset in minutes
  //   const sign = offset > 0 ? '-' : '+';
  //   const absOffset = Math.abs(offset);
  //   const hours = Math.floor(absOffset / 60).toString().padStart(2, '0');
  //   const minutes = (absOffset % 60).toString().padStart(2, '0');
  //   return `${sign}${hours}:${minutes}`;
  // };

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Edit Task" />
        <FieldGroup column={1}>
        <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
        </FieldGroup>
        <FieldGroup column={3}>
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <textarea
            value={payload?.description}
            onChange={(e) => handleChange(e.target.value, 'description')}
            placeholder="Add Description Here ..."
            style={{
              width: '100%',
              height: '100px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
          {/* <Datetime
            value={payload?.trigger_on ? payload?.trigger_on : ''}
            onChange={(date) => {
              // const localTimeString = moment(date).format('YYYY-MM-DD HH:mm:ss');
              handleChange(date, 'trigger_on');
            }}
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:MM:SS"
            inputProps={{
              placeholder: "YYYY-MM-DD HH:mm:ss",
              // error: errors?.trigger_on,
              style: {
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
                lineHeight: "1.5",
              }
            }}
          /> */}
          {/* <input
            type="datetime-local"
            name="trigger_on"
            value={payload?.trigger_on}
            // value={moment(payload?.trigger_on, 'HH:mm:ss').format('HH:mm:ss')}
            // value={moment.tz(payload?.trigger_on, 'HH:mm:ss', userTimezone).format('HH:mm:ss')}
            // value={payload?.trigger_on}
            // onChange={(date) => handleChange(date, 'trigger_on')}
            onChange={(e) => handleChange(e.target.value, 'trigger_on')}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '4px',
              border: errors?.trigger_on ? '1px solid red' : '1px solid #ccc',
            }}
          />
          {errors?.trigger_on && (
            <div style={{ color: 'red', fontSize: '12px' }}>
              {errors.trigger_on}
            </div>
          )} */}
          <InputDate
            label="Trigger Date"
            name="trigger_date"
            handleChange={handleChange}
            value={payload?.trigger_date}
            error={errors?.trigger_date}
          />
          <InputTime
            label="Trigger Time"
            name="trigger_time"
            handleChange={handleChange}
            value={payload?.trigger_time}
            error={errors?.trigger_time}
          />
        </FieldGroup>

        {/* <FieldGroup column={1}>
          <Dropdown
            label="Linked To"
            name="object"
            value={payload?.object}
            handleChange={handleChange}
            options={CS_TASK_OBJECT_TYPE}
            error={errors?.object}
          />
        </FieldGroup> */}
        {
          (payload?.object == "SERVICE") ?
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "40px" }}>
              <span><b>Linked To:</b> Service</span>
              <span><b>Client:</b> {payload?.client_firstname} {payload?.client_lastname}</span>
              <span><b>Service:</b> {payload?.service_type_title}</span>
            </div>
            :
            null
        }

        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default TaskEdit;
