import "./style.css";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import { Badge, TextLink } from "../../Components";
import MainContent from "../../Components/MainContent";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import AddDocumentsTypes from "./AddTaskStatus";
import documentService from "../../../services/axios/documents";
import taskService from "../../../services/axios/tasks";
import AddTaskStatus from "./AddTaskStatus";
import EditTaskStatus from "./EditTaskStatus";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
const ListTaskStatus = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [taskStatusId, setTaskStatusId] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize,filters,search])

  const handleAddSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const handleShowEdit = (id: any) => {
    setTaskStatusId(id);
    setshowEdit(true);
  };
  const handleEditSuccess = () => {
    fetchData();
    setshowEdit(false);
  };
  const handleEditCancel = () => {
    setshowEdit(false);
    setTaskStatusId(null);
  };

  const fetchData = () => {
    gLoader(true);
    taskService?.listTaskStatus({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    taskService?.deleteTaskStatus(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <MainContent
     
    >
      <CardHeader
        title="Tasks Status"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
            size="small"
            title="Add New"
            theme="success"
            onClick={() => setShowAddNew(true)}
          />
          </>
        }
      />
      {showAddNew && (
        <AddTaskStatus
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleAddSuccess}
        />
      )}

      {showEdit && (
        <EditTaskStatus
          show={showEdit}
          onCancel={handleEditCancel}
          onSuccess={handleEditSuccess}
          data={{ task_status_id: taskStatusId }}
        />
      )}

      <Card>
        {/* <ListSearch /> */}

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <td style={{ textAlign: 'center' }}>{itm?.id}</td>
                  <td style={{ textAlign: 'center' }}>{itm?.title}</td>
                  <td style={{ textAlign: 'center' }}>{itm?.bg_color}</td>
                  <td style={{ textAlign: 'center' }}>{itm?.color}</td>
                  <td style={{ display: 'flex', justifyContent: 'center' }}><Badge colorTheme='B' text={itm?.status} /></td>
                  {
                    (itm?.company_id != "DEFAULT") ?
                      <td><ListRowActions
                        actions={[
                          // { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                          { icon: 'edit', onClick: () => handleShowEdit(`${itm.id}`) },
                          { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                        ]}
                      /></td> :
                      <td></td>
                  }

                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
    </MainContent>
  );
};

export default ListTaskStatus;

const listHeaders: ListHeadType[] = [
  {
    title: "Id",
    width: "10%",
    sortName: "uid",
    align: "center",
  },
  {
    title: "Title",
    width: "20%",
    align: "center",
  },
  {
    title: "Background Color",
    width: "15%",
    align: "center",
  },
  {
    title: "Color",
    width: "15%",
    align: "center",
  },
  {
    title: "Status",
    width: "20%",
    align: "center",
  },
  //   {
  //     title: "Added On",
  //     width: "5%",
  //     align: "center",
  //   },
  {
    title: "Actions",
    width: "20%",
    align: "center",
  }
];
