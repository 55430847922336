import "./style.css";
import MainContent from "../../Components/MainContent";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  DropdownA2,
  Input,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import { Badge, TextLink } from "../../Components";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import taskService from "../../../services/axios/tasks";
import { CS_REMINDER_STATUS } from "../../../utils/constants";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import ReminderScheduleAdd from "../ReminderSchedules/ReminderScheduleAdd";
import ReminderAdd from "./ReminderAdd";
import { useDispatch } from "react-redux";
import { getRemindersData } from "../../../store/taskSlice";
const Reminders = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()
  const dispatch: any = useDispatch();

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize, filters, search])

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    taskService?.listReminder({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    taskService?.deleteReminder(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        dispatch(getRemindersData());
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  // const handleUpdateStatus = (reminder_id: number, reminder_status: string) => {
  //   setButtonLoading(true);
  //   taskService?.updateReminder(reminder_id, { reminder_status })
  //     .then((res) => {
  //       setButtonLoading(false);
  //       successPopUp(res?.data?.message);
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       setButtonLoading(false);
  //       errorPopUp(err?.message);
  //     })
  // };
  const handleUpdateStatus = (reminder_id: number, reminder_status: any) => {
    setButtonLoading(true);
    taskService?.changeReminderStatus(reminder_id, { reminder_status })
      .then((res) => {
        dispatch(getRemindersData());
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <MainContent
    >
      <CardHeader
        title="Reminders"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Add New"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
          </>
        }
      />


      {showAddNew && (
        <ReminderAdd
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleSubmit}
        />
      )}
      <Card>
        {/* <ListSearch /> */}

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              const reminderStatus = CS_REMINDER_STATUS.find((field: any) => field.value === itm?.reminder_status);
              const reminderStatusValue = reminderStatus?.value;
              // const reminderStatusBgColor = reminderStatus?.bgColor;
              // const reminderStatusColor = reminderStatus?.color;
              // const reminderStatusName = reminderStatus?.name || '';
              return (

                <tr key={index}>
                  <Td align="left">{itm.title}</Td>
                  <Td align="left">{itm.description}</Td>
                  <Td style={{ display: 'flex', justifyContent: 'center' }}>
                    <DropdownA2
                      options={CS_REMINDER_STATUS}
                      onChange={(value) => handleUpdateStatus(itm.id, value)}
                      placeholder="Select Status"
                      value={reminderStatusValue}
                    />
                  </Td>


                  <Td>
                    <ListRowActions
                      actions={[
                        { icon: 'view', onClick: () => navigate(`/reminders/${itm.id}`) },
                        { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                      ]}
                    />
                  </Td>
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>

    </MainContent>
  );
};

export default Reminders;

const listHeaders: ListHeadType[] = [
  {
    title: "Title",
    width: "30%",
    align: "left",
  },
  {
    title: "Description",
    width: "30%",
    align: "left",
  },

  // {
  //   title: "Task Status",
  //   width: "20%",
  //   align: "center",
  // },
  {
    title: "Status",
    width: "20%",
    align: "center",
  },
  {
    title: "",
    width: "20%",
    align: "right",
  }
];

