import { Button, ButtonsGroup, Card, CardHeader, Dropdown, FieldGroup, Input, InputFile, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import companySettings from "../../../../../services/axios/settings/companySettings";
import { DefaultEditor, Editor } from "react-simple-wysiwyg";

const EmailFooter = () => {
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [footerValue, setFooterValue] = useState("");

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    gLoader(true);
    companySettings.detailEmailFooter()
      .then((res: any) => {
        gLoader(false);
        let newDetail = res?.data?.response?.detail;
        setFooterValue(newDetail?.data);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  }

  const handleSubmit = () => {
    setButtonLoading(true);
    companySettings?.addEmailFooter({ data: footerValue })
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  return (
    <>
      <Card style={{ maxWidth: '1200px' }}>
        <CardHeader
          title="Email Footer"
        />
        <div className="prof-sett-top" >
          <div className="right-form">
            <FieldGroup column={1}>
              <DefaultEditor
                value={footerValue}
                onChange={(e) => setFooterValue(e.target.value)}
                tagName="p"
                style={{ minHeight: '500px', width: '800px', border: '1px solid #ccc', padding: '10px' }}
              />
            </FieldGroup>
            <ButtonsGroup>
              <Button title="Update" onClick={handleSubmit} theme="success" loading={buttonLoading} />
            </ButtonsGroup>
          </div>

        </div>

      </Card>

    </>
  );
};

export default EmailFooter;
