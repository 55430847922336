import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "../../..";

class stripeService {


    //Stripe Credentials
    addStripeConfigs = async (payload = {}) => {
        return await postRequest(`company/plugins/stripe/config`, payload);
    }
    detailStripeConfigs = async (urlParam = {}) => {
        return await getRequest(`company/plugins/stripe/config`, urlParam);
    }


}

const instance = new stripeService();

export default instance;

