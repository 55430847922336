import { CSSProperties, FC, ReactNode } from "react"
import './style.css'
export interface Props {
    title?: string;
    style?: CSSProperties;
    rightButtons?:ReactNode;
    children:ReactNode
  }
const MainContent : FC<Props> =(props)=>{
    return <div style={props.style}>
        <div className="main-content-header">
        <h1 style={{fontWeight:600}}>{props.title}</h1>
        <div className="right-action-buttons">
            {props?.rightButtons}
        </div>
        </div>
        {props.children}
    </div>
}

export default MainContent