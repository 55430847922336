
import { Button, Card, CardHeader, confirmPopUp, errorPopUp, gLoader, Input, List, ListBody, ListHeadType, successPopUp, Td } from "asbawa-react-ui-ts-test-b";
import { Badge, TextLink } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconCopy, IconDocuments } from "../../Components/Icons";
import paymentService from "../../../services/axios/payments";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import documentService from "../../../services/axios/documents";
import { APP_URL, CS_BOOLEAN_TRUE, CS_DOC_STATUS } from "../../../utils/constants";
import ClientDocumentsRequest from "./ClientDocumentsRequest";
import { toYesNo } from "../../../utils/commonFunctions";
const DocumentRequests = (): JSX.Element | null => {
  const [showNewRequest, setShowNewRequest] = useState(false);
  const { client_id } = useParams<{ client_id: string }>();
  const [list, setList] = useState<any>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<{ [key: number]: string | null }>({});
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
  }, [client_id, pageNo, pageSize])

  const handleAddNewSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const handleCopyUrl = (url: string, id: number) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        setCopySuccess((prev) => ({ ...prev, [id]: "Copied!" }));
        setTimeout(() => {
          setCopySuccess((prev) => ({ ...prev, [id]: null }));
        }, 800);
      })
      .catch(() => {
        setCopySuccess((prev) => ({ ...prev, [id]: "Copy Failed!" }));
      });
  };

  const fetchData = () => {
    gLoader(true);
    documentService?.listDocumentRequests({ client_id, pageNo, pageSize, search: filters?.search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    documentService?.deleteDocumentRequest(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };
  const handleChangeStatus = (id: any) => {
    setButtonLoading(true);
    documentService?.changeDocumentRequestStatus(id, { document_upload_status: "CANCELLED" })
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
      })
  };

  const handleNewRequestSuccess = () => {
    fetchData();
    setShowNewRequest(false);
  };

  return <>
    {showNewRequest && (
      <ClientDocumentsRequest
        show={showNewRequest}
        onCancel={() => setShowNewRequest(false)}
        onSuccess={handleNewRequestSuccess}
        data={{ client_id }}
      />
    )}
    <Card>


      <CardHeader
        title="Document Requests"
        rightButtons={
          <>
            <Button
              size="small"
              theme="primary"
              title="Ask for documents"
              onClick={() => setShowNewRequest(true)}
            />
          </>
        }
      />
      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            const doc_names = itm?.documents.map((doc: any) => doc?.document_name).join(', ');
            const docStatus: any = CS_DOC_STATUS.find((field: any) => field.value == itm.upload_status);
            const docStatusBgColor = docStatus?.bgColor;
            const docStatusColor = docStatus?.color;
            const docStatusName = docStatus?.name;
            const url = itm.url ? `${APP_URL}/${itm.url}` : '';
            return (
              <tr key={index}>
                <Td align="left">{doc_names}</Td>
                <Td align="left">{itm.folder_name}</Td>
                <Td style={{display:'flex',justifyContent:'center'}}><Badge color={docStatusColor} bgColor={docStatusBgColor} text={docStatusName} /></Td>
                <Td>
                {itm.url && (copySuccess[itm.id] ? <small style={{ marginLeft: '3px', color: 'green' }}>{copySuccess[itm.id]}</small> : <IconCopy size='1.5em' onClick={() => handleCopyUrl(url, itm.id)}/>)}
                </Td>
                <Td style={{ textAlign: 'center' }}>{toYesNo(CS_BOOLEAN_TRUE.includes(itm?.notify_on_email))}</Td>
                <Td>     {itm?.upload_status == 'PENDING' &&             <Button title="Cancel" onClick={() => confirmPopUp('Are you sure you want to cancel the request ?', () => handleChangeStatus(itm.id))} loading={buttonLoading} theme="danger" size="small" /> }</Td>
                <Td style={{ textAlign: 'center', display: "flex", justifyContent: "center" }}>
                  <ListRowActions
                    actions={[
                      { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                    ]}
                  />

                </Td>
              </tr>
            );
          })}
        </ListBody>
      </List>
      <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
    </Card>
  </>
};

export default DocumentRequests;
const listHeaders: ListHeadType[] = [

  {
    title: "Documents",
    width: "40%",
    align: "left",
  },
  {
    title: "Folder",
    width: "15%",
    align: "left",
  },
  {
    title: "Upload Status",
    width: "15%",
    align: "center",
  },
  {
    title: "Url",
    width: "5%",
    align: "center",
  },
  {
    title: "Notified On Email",
    width: "10%",
    align: "center",
  },
  {
    title: "",
    width: "10%",
    align: "center",
  },
  {
    title: "",
    width: "5%",
    align: "right",
  }
];