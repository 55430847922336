import { deleteRequest, getBlobRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class documentService {
    //documents
    listDocument = async (client_id: any, urlParam = {}) => {
        return await getRequest(`company/document`, urlParam);
    }
    detailDocument = async (id: any, urlParam = {}) => {
        return await getRequest(`company/document/${id}`, urlParam);
    }
    deleteDocumentsFolder = async (folder_id: any, urlParam: any = {}) => {
        return await deleteRequest(`company/document/folder/${folder_id}`, urlParam);
    }
    deleteDocument = async (document_id: any, urlParam: any = {}) => {
        return await deleteRequest(`company/document/${document_id}`, urlParam);
    }
    storageQuotaDocument = async (urlParam = {}) => {
        return await getRequest(`company/document/storage-quota`, urlParam);
    }
    viewDocument = async (document_drive_id: any, urlParam = {}) => {
        return await getBlobRequest(`company/document/view/${document_drive_id}`, urlParam);
    }

    deleteMultiDocuments = async (payload = {}) => {
        return await postRequest(`company/document/multi-docs/delete`, payload);
    }


    //Document Type MAster
    listDocumentType = async (urlParam = {}) => {
        return await getRequest(`company/document/type`, urlParam);
    }
    detailDocumentType = async (id: any, urlParam = {}) => {
        return await getRequest(`company/document/type/${id}`, urlParam);
    }
    addDocumentType = async (payload = {}) => {
        return await postRequest(`company/document/type`, payload);
    }
    updateDocumentType = async (id: any, payload = {}) => {
        return await putRequest(`company/document/type/${id}`, payload);
    }
    deleteDocumentType = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/document/type/${id}`, urlParam);
    }
    quickListDocumentType = async (urlParam = {}) => {
        return await getRequest(`company/document/type/quick/list`, urlParam);
    }

    //Folders
    addFolder = async (payload = {}) => {
        return await postRequest(`company/document/folder`, payload);
    }
    quickListFolder = async (urlParam = {}) => {
        return await getRequest(`company/document/folder/quick/list`, urlParam);
    }


    //DOCUMENTS REQUESTS ROUTES
    listDocumentRequests = async (urlParam = {}) => {
        return await getRequest(`company/document/requests`, urlParam);
    }
    deleteDocumentRequest = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/document/requests/${id}`, urlParam);
    }
    changeDocumentRequestStatus = async (id: any, payload = {}) => {
        return await putRequest(`company/document/requests/status/${id}`, payload);
    }


}

const instance = new documentService();

export default instance;

