import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";
import clientService from "../../../services/axios/clients";
import { CS_FIELD_TYPES } from "../../../utils/constants";

const AddClientProfileCustomFields: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({ is_required: false });
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    clientService?.addClientProfileCustomField(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  const handleRemove = (index: any) => {
    const payloadArr = [...payload.custom_fields];
    payloadArr.splice(index, 1);
    handleChange(payloadArr, 'custom_fields');
    };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "500px" }}>
        <CardHeader title="Add Client Profile Custom Fields" />
        <FieldGroup column={1}>
          <Input
            label="Name"
            name="name"
            handleChange={handleChange}
            value={payload?.name}
            error={errors?.name}
          />
          <Dropdown
            label="Type"
            name="type"
            value={payload?.type}
            handleChange={handleChange}
            options={CS_FIELD_TYPES}
            error={errors?.type}
          />
          <InputCheckbox
            label="Is Required"
            name="is_required"
            handleChange={handleChange}
            checked={Boolean(payload?.is_required)}
            error={errors?.is_required}
          />
        </FieldGroup>

        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default AddClientProfileCustomFields;
