import { Outlet } from 'react-router-dom';
import Tabs from '../../Components/Tabs';
import './trigger.css'
const TriggerLayout = () => {


    return <>
        <Tabs links={links} showBack={false}/>
        <Outlet/>
    </>
};

export default TriggerLayout;


const links = [
    {title:'Email Triggers',goto:'email-triggers'},
    {title:'Task Triggers',goto:'task-triggers'}
  ];