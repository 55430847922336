import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class taskService {
    //task Scheduler Recurrence Routes
    listTaskScheduleReccurence = async (urlParam = {}) => {
        return await getRequest(`company/task/schedule/reccurence`, urlParam);
    }
    detailTaskScheduleReccurence = async (id: any, urlParam = {}) => {
        return await getRequest(`company/task/schedule/reccurence/${id}`, urlParam);
    }
    addTaskScheduleReccurence = async (payload = {}) => {
        return await postRequest(`company/task/schedule/reccurence`, payload);
    }
    updateTaskScheduleReccurence = async (id: any, payload = {}) => {
        return await putRequest(`company/task/schedule/reccurence/${id}`, payload);
    }
    deleteTaskScheduleReccurence = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/task/schedule/reccurence/${id}`, urlParam);
    }


    // //task Scheduler Routes
    // listTaskSchedule = async (urlParam = {}) => {
    //     return await getRequest(`company/task/schedule`, urlParam);
    // }
    // detailTaskSchedule = async (id: any, urlParam = {}) => {
    //     return await getRequest(`company/task/schedule/${id}`, urlParam);
    // }
    // addTaskSchedule = async (payload = {}) => {
    //     return await postRequest(`company/task/schedule`, payload);
    // }
    // updateTaskSchedule = async (id: any, payload = {}) => {
    //     return await putRequest(`company/task/schedule/${id}`, payload);
    // }
    // deleteTaskSchedule = async (id: any, urlParam = {}) => {
    //     return await deleteRequest(`company/task/schedule/${id}`, urlParam);
    // }


    //tasks Routes
    changeTaskStatus = async (id: any, payload = {}) => {
        return await putRequest(`company/task/change-status/${id}`, payload);
    }
    listTask = async (urlParam = {}) => {
        return await getRequest(`company/task`, urlParam);
    }
    detailTask = async (id: any, urlParam = {}) => {
        return await getRequest(`company/task/${id}`, urlParam);
    }
    addTask = async (payload = {}) => {
        return await postRequest(`company/task`, payload);
    }
    updateTask = async (id: any, payload = {}) => {
        return await putRequest(`company/task/${id}`, payload);
    }
    deleteTask = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/task/${id}`, urlParam);
    }


    //task Status Routes
    quickListTaskStatus = async (urlParam = {}) => {
        return await getRequest(`company/task/status/quick/list`, urlParam);
    }
    listTaskStatus = async (urlParam = {}) => {
        return await getRequest(`company/task/status`, urlParam);
    }
    detailTaskStatus = async (id: any, urlParam = {}) => {
        return await getRequest(`company/task/status/${id}`, urlParam);
    }
    addTaskStatus = async (payload = {}) => {
        return await postRequest(`company/task/status`, payload);
    }
    updateTaskStatus = async (id: any, payload = {}) => {
        return await putRequest(`company/task/status/${id}`, payload);
    }
    deleteTaskStatus = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/task/status/${id}`, urlParam);
    }



    // //Reminder Schedules Routes
    // listReminderSchedule = async (urlParam = {}) => {
    //     return await getRequest(`company/task/reminder/schedule`, urlParam);
    // }
    // detailReminderSchedule = async (id: any, urlParam = {}) => {
    //     return await getRequest(`company/task/reminder/schedule/${id}`, urlParam);
    // }
    // addReminderSchedule = async (payload = {}) => {
    //     return await postRequest(`company/task/reminder/schedule`, payload);
    // }
    // updateReminderSchedule = async (id: any, payload = {}) => {
    //     return await putRequest(`company/task/reminder/schedule/${id}`, payload);
    // }
    // deleteReminderSchedule = async (id: any, urlParam = {}) => {
    //     return await deleteRequest(`company/task/reminder/schedule/${id}`, urlParam);
    // }


    //Reminders Routes
    changeReminderStatus = async (id: any, payload = {}) => {
        return await putRequest(`company/task/reminder/change-status/${id}`, payload);
    }
    listReminder = async (urlParam = {}) => {
        return await getRequest(`company/task/reminder`, urlParam);
    }
    addReminder = async (payload = {}) => {
        return await postRequest(`company/task/reminder`, payload);
    }
    detailReminder = async (id: any, urlParam = {}) => {
        return await getRequest(`company/task/reminder/${id}`, urlParam);
    }
    updateReminder = async (id: any, payload = {}) => {
        return await putRequest(`company/task/reminder/${id}`, payload);
    }
    deleteReminder = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/task/reminder/${id}`, urlParam);
    }


    //On Socket Event Routes
    getTasksData = async (urlParam = {}) => {
        return await getRequest(`company/task/data`, urlParam);
    }

    getRemindersData = async (urlParam = {}) => {
        return await getRequest(`company/task/reminders/data`, urlParam);
    }


    //Communication Recurrence Routes
    listCommunicationReccurence = async (urlParam = {}) => {
        return await getRequest(`company/task/communication/reccurence`, urlParam);
    }
    detailCommunicationReccurence = async (id: any, urlParam = {}) => {
        return await getRequest(`company/task/communication/reccurence/${id}`, urlParam);
    }
    addCommunicationReccurence = async (payload = {}) => {
        return await postRequest(`company/task/communication/reccurence`, payload);
    }
    updateCommunicationReccurence = async (id: any, payload = {}) => {
        return await putRequest(`company/task/communication/reccurence/${id}`, payload);
    }
    deleteCommunicationReccurence = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/task/communication/reccurence/${id}`, urlParam);
    }

}

const instance = new taskService();

export default instance;

