import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class subscriptionService {
    //Plans Routes
    listPlan = async (urlParam = {}) => {
        return await getRequest(`company/subscription/plan`, urlParam);
    }

    addOrder = async (payload = {}) => {
        return await postRequest(`company/subscription/order`, payload);
    }
}

const instance = new subscriptionService();

export default instance;

