import { Button, ButtonsGroup, Card, CardHeader, Dropdown, FieldGroup, Input, InputFile, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import "./GoogleDriveSettings.css";
import { useEffect, useState } from "react";
import authService from "../../../services/axios/auth";
import AddGoogleDriveFolder from "./AddGoogleDriveFolder";
import googleDriveService from "../../../services/axios/settings/plugins/googleDrive";

const GoogleDriveSettings = () => {
    const [payload, setPayload] = useState<any>({});
    const [details, setDetails] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [showAddNew, setShowAddNew] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        gLoader(true);
        googleDriveService?.detailGoogleDriveConfigs()
            .then((res: any) => {
                gLoader(false);
                let newDetail = res?.data?.response?.detail;
                setDetails((prevPayload: any) => ({
                    ...prevPayload,
                    ...newDetail
                }));
            })
            .catch((err: any) => {
                gLoader(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    }

    const onSuccess = () => {
        setShowAddNew(false);
    };

    const handleSubmit = () => {
        setButtonLoading(true);
        const formData = new FormData();
        formData.append("file", payload.file);
        googleDriveService?.addGoogleDriveConfigs(formData)
            .then((res:any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
            })
            .catch((err:any) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    };

    const handleFileChange = (file: File | null) => {
        setPayload({
            ...payload,
            ['file']: file,
        });

    };
    return (
        <>
            <Card>
                <CardHeader
                    title="Google Drive Settings"
                    rightButtons={
                        <>
                            <Button
                                size="small"
                                title="Add Google Drive Folder Id"
                                theme="primary"
                                onClick={() => setShowAddNew(true)}
                            />
                        </>
                    }
                />
                <div className="prof-sett-top" style={{ display: 'flex', maxWidth: '800px' }}>
                    <div className="right-form">
                        <FieldGroup column={1}>
                            <textarea
                                value={JSON.stringify(details?.gd_drive_detail)}
                                placeholder="Your credentials will be displayed here..."
                                style={{
                                    width: '800px',
                                    height: '300px',
                                    resize: 'none',
                                    overflow: 'auto',
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    // fontFamily: 'Arial, sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '1.5'
                                }}
                                readOnly
                            />
                            <InputFile
                                label="Select Credentials .json File"
                                required={true}
                                handleChange={(file) => handleFileChange(file)}
                                error={errors?.file}
                            />

                        </FieldGroup>
                        <ButtonsGroup>
                            <Button title="Update" onClick={handleSubmit} theme="success" loading={buttonLoading} />
                        </ButtonsGroup>
                    </div>

                </div>

            </Card>

            {showAddNew && (
                <AddGoogleDriveFolder
                    show={showAddNew}
                    onCancel={() => setShowAddNew(false)}
                    onSuccess={onSuccess}
                />
            )}
        </>
    );
};

export default GoogleDriveSettings;
