import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import clientService from "../../services/axios/clients";
import { SubContent } from "../Components";
import { CS_BOOLEAN_TRUE, CS_STATUS } from "../../utils/constants";

interface Props {
  show: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  data?: any
}
const EditClient: FC<Props> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [customFieldList, setCustomFieldList] = useState<any>([]);

  useEffect(() => {
    // fetchCustomFields();
    fetchData();
  }, [])

  // const fetchCustomFields = () => {
  //   gLoader(true);
  //   clientService?.quickListClientProfileCustomField()
  //     .then((res: any) => {
  //       gLoader(false);
  //       // let customFieldListData = res?.data?.response?.list;
  //        setCustomFieldList(res?.data?.response?.list);
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message);
  //     })
  // }

  const fetchData = () => {
    gLoader(true);
    clientService?.detailClient(data?.client_id)
      .then((res: any) => {
        gLoader(false);
        // let payloadData = res?.data?.response?.detail;
        // for(const checkField of customFieldList){
        //   const fieldObj = payloadData?.custom_fields?.find((field: any) => field.custom_field_id == checkField.custom_field_id);
        //   if(!fieldObj){
        //     payloadData?.custom_fields.push(checkField);
        //   } 
        // }
        // setPayload(payloadData);
        setPayload(res?.data?.response?.detail);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }



  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    // gLoader(true);
    clientService?.updateClient(data?.client_id, payload)
      .then((res: any) => {
        // gLoader(false);
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
        //navigate('/')
      })
      .catch((err) => {
        // gLoader(false);
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  // const handleArrayChange = (value: any, name: string, index: any) => {
  //   let customFieldListArr = {...customFieldList[index],[name]: value};
  //   customFieldList[index]=customFieldListArr;
  //   handleChange(customFieldList, 'custom_fields');
  // };
  const handleArrayChange = (value: any, name: string, index: any) => {
    const payloadArr = [...payload.custom_fields];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    handleChange(payloadArr, 'custom_fields');
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "1200px" }}>
        <CardHeader title="Edit Client" />
        <FieldGroup column={3}>
          <Input
            label="First Name"
            name="firstname"
            handleChange={handleChange}
            value={payload?.firstname}
            error={errors?.firstname}
          />
          <Input
            label="Last Name"
            name="lastname"
            handleChange={handleChange}
            value={payload?.lastname}
            error={errors?.lastname}
          />
          <Input
            label="Address"
            name="address_line"
            handleChange={handleChange}
            value={payload?.address_line}
            error={errors?.address_line}
          />
          <Input
            label="City"
            name="city"
            handleChange={handleChange}
            value={payload?.city}
            error={errors?.city}
          />
          <Input
            label="Province"
            name="province"
            handleChange={handleChange}
            value={payload?.province}
            error={errors?.province}
          />
          <Input
            label="Country"
            name="country"
            handleChange={handleChange}
            value={payload?.country}
            error={errors?.country}
          />
          <Input
            label="Postal Code"
            name="postalcode"
            handleChange={handleChange}
            value={payload?.postalcode}
            error={errors?.postalcode}
          />
          <Input
            label="Email"
            name="email"
            handleChange={handleChange}
            value={payload?.email}
            error={errors?.email}
          />
          <Input
            label="Phone"
            name="phone"
            handleChange={handleChange}
            value={payload?.phone}
            error={errors?.phone}
          />
          <Input
          required
            label="Whatsapp No."
            name="whatsapp_number"
            handleChange={handleChange}
            value={payload?.whatsapp_number}
            error={errors?.whatsapp_number}
          />
          <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
          <textarea
            value={payload?.internal_notes}
            onChange={(e) => handleChange(e.target.value, 'internal_notes')}
            placeholder="Internal Notes"
            style={{
              width: '100%',
              height: '100px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
          <div></div>
          <div></div>
        </FieldGroup>
        <br />
        <SubContent heading="Custom Fields">
          <FieldGroup column={3}>
            {payload?.custom_fields?.map((item: any, index: number) => (
              <div key={index}>
                {item?.type == 'BOOLEAN' ?
                  <InputCheckbox
                    label={item?.name}
                    name="custom_field_value"
                    handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                    checked={payload?.custom_fields?.[index]?.custom_field_value}
                  /> :
                  item?.type == 'DATE' ?
                    <InputDate
                      label={item?.name}
                      name="custom_field_value"
                      handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                      value={payload?.custom_fields?.[index]?.custom_field_value}
                      required={CS_BOOLEAN_TRUE.includes(item?.is_required) ? true : false}
                    /> :
                    <Input
                      label={item?.name}
                      name="custom_field_value"
                      handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                      value={payload?.custom_fields?.[index]?.custom_field_value}
                      required={CS_BOOLEAN_TRUE.includes(item?.is_required) ? true : false}
                    />
                }
              </div>
            ))}
          </FieldGroup>
        </SubContent>


        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default EditClient;
