import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import { SubContent } from "../../Components";
import { toNull } from "../../../utils/commonFunctions";
import { CS_BOOLEAN_TRUE, CS_STATUS, CS_TASK_MONTHS, CS_TASK_MONTH_DAYS, CS_TASK_QUARTERLY, CS_TASK_SERVICES_INTERVAL, CS_TASK_WEEKDAYS } from "../../../utils/constants";

interface Props {
  show: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  data?: any
}
const EditClientService: FC<Props> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({ client_id: data?.client_id });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [customFieldList, setCustomFieldList] = useState<any>([]);
  const [servicesTypeList, setServicesTypeList] = useState<any>([]);
  const [showRecurrenceFields, setShowRecurrenceFields] = useState<boolean>(false);

  useEffect(() => {
    fetchServicesTypes();
  }, [])

  const fetchServicesTypes = () => {
    gLoader(true);
    service?.quickListCompanyServiceType()
      .then((res: any) => {
        gLoader(false);
        const dropdownOptionsServiceType = res?.data?.response?.list?.map((item: any) => ({
          name: item?.title,
          value: item?.id,
          has_recurrence: item?.has_recurrence,
        }));
        setServicesTypeList(dropdownOptionsServiceType);
        fetchData();
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const fetchData = () => {
    gLoader(true);
    service?.detailCompanyServiceToClient(data?.client_service_id)
      .then((res: any) => {
        gLoader(false);
        let newDetail = res?.data?.response?.detail;
        let serviceTypeId = newDetail?.service_type_id;
        let checkHasRecurrence = servicesTypeList.find((field: any) => field.value == serviceTypeId);
        if (CS_BOOLEAN_TRUE.includes(newDetail?.has_recurrence)) {
          setShowRecurrenceFields(true);
        }
        // setPayload({ ...payload, ...newDetail });
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          ...newDetail
        }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  }

  const fetchCustomFields = (servicesTypeId: any) => {
    gLoader(true);
    service?.quickListServicesCustomFieldsClientsAssign(servicesTypeId)
      .then((res: any) => {
        gLoader(false);
        // const dropdownOptionsCustomFields = res?.data?.response?.list?.map((item: any) => ({
        //   name: item?.name,
        //   value: item?.id,
        //   is_required: item?.is_required,
        //   type: item?.type,
        // }));
        // setCustomFieldList(dropdownOptionsCustomFields);
        let fieldDetails = res?.data?.response?.list;
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          custom_fields: fieldDetails
        }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null
    })
    if (name === "service_type_id" && toNull(value) != null) {
      fetchCustomFields(value);
      const checkHasRecurrence = servicesTypeList.find((field: any) => field.value == value);
      if (checkHasRecurrence.has_recurrence == true) {
        setShowRecurrenceFields(true);
      } else {
        setShowRecurrenceFields(false);
      }
    }
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    // gLoader(true);
    service?.updateCompanyServiceToClient(data?.client_service_id, payload)
      .then((res: any) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };


  const handleArrayChange = (value: any, name: string, index: any) => {
    const payloadArr = [...payload.custom_fields];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    // if (name == 'custom_field_id') {
    //   const fieldObj = customFieldList.find((field: any) => field.value == value);
    //   console.log("fieldObjfieldObj", fieldObj)
    //   payloadArr[index] = {
    //     ...payloadArr[index],
    //     ['custom_field_value']: null,
    //     ['is_required']: fieldObj?.is_required,
    //     ['type']: fieldObj?.type,
    //   };
    // }
    handleChange(payloadArr, 'custom_fields');
  };

  // const addMore = () => {
  //   const payloadArr = [...payload?.custom_fields]
  //   payloadArr.push({})
  //   handleChange(payloadArr, 'custom_fields');
  // }

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Edit Service Assigned To Client" />
        <SubContent heading="">
          <FieldGroup column={1}>
            <Dropdown
              label="Service Type"
              name="service_type_id"
              value={payload?.service_type_id}
              handleChange={handleChange}
              options={servicesTypeList}
              error={errors?.service_type_id}
            />
            <Dropdown
              label="Status"
              name="status"
              value={payload?.status}
              handleChange={handleChange}
              options={CS_STATUS}
              error={errors?.status}
            />
          </FieldGroup>

          <FieldGroup column={3}>
            {
              showRecurrenceFields && <Dropdown
                label="Trigger Interval"
                name="trigger_interval"
                value={payload?.trigger_interval}
                handleChange={handleChange}
                options={CS_TASK_SERVICES_INTERVAL}
                error={errors?.trigger_interval}
              />}

            {payload?.trigger_interval == "WEEKLY" ?
              <Dropdown
                label="Trigger On"
                name="trigger_on"
                value={payload?.trigger_on}
                handleChange={handleChange}
                options={CS_TASK_WEEKDAYS}
                error={errors?.trigger_on}
              />
              :
              (payload?.trigger_interval == "MONTHLY") ?
                <Dropdown
                  label="Trigger On"
                  name="trigger_on"
                  value={payload?.trigger_on}
                  handleChange={handleChange}
                  options={CS_TASK_MONTH_DAYS}
                  error={errors?.trigger_on}
                />
                :
                (payload?.trigger_interval == "QUARTERLY") ?
                  <>
                    <Dropdown
                      label="Trigger Month"
                      name="trigger_month"
                      value={payload?.trigger_month}
                      handleChange={handleChange}
                      options={CS_TASK_QUARTERLY}
                      error={errors?.trigger_month}
                    />
                    <Dropdown
                      label="Trigger On"
                      name="trigger_on"
                      value={payload?.trigger_on}
                      handleChange={handleChange}
                      options={CS_TASK_MONTH_DAYS}
                      error={errors?.trigger_on}
                    />
                  </>
                  :
                  (payload?.trigger_interval == "YEARLY") ?
                    <>
                      <Dropdown
                        label="Trigger Month"
                        name="trigger_month"
                        value={payload?.trigger_month}
                        handleChange={handleChange}
                        options={CS_TASK_MONTHS}
                        error={errors?.trigger_month}
                      />
                      <Dropdown
                        label="Trigger On"
                        name="trigger_on"
                        value={payload?.trigger_on}
                        handleChange={handleChange}
                        options={CS_TASK_MONTH_DAYS}
                        error={errors?.trigger_on}
                      />
                    </>
                    :
                    null
            }

          </FieldGroup>
        </SubContent>

        <br />

        <SubContent heading="Custom Fields">
          <FieldGroup column={3}>
            {payload?.custom_fields?.map((item: any, index: number) => (
              <div key={index}>
                {item?.type == 'BOOLEAN' ?
                  <InputCheckbox
                    label={item?.name}
                    name="custom_field_value"
                    handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                    checked={payload?.custom_fields?.[index]?.custom_field_value}
                  /> :
                  item?.type == 'DATE' ?
                    <InputDate
                      label={item?.name}
                      name="custom_field_value"
                      handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                      value={payload?.custom_fields?.[index]?.custom_field_value}
                      required={CS_BOOLEAN_TRUE.includes(item?.is_required) ? true : false}
                    /> :
                    <Input
                      label={item?.name}
                      name="custom_field_value"
                      handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                      value={payload?.custom_fields?.[index]?.custom_field_value}
                      required={CS_BOOLEAN_TRUE.includes(item?.is_required) ? true : false}
                    />
                }
              </div>
            ))}
          </FieldGroup>
        </SubContent>
        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default EditClientService;
