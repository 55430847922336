import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import { CS_FIELD_TYPES, CS_STATUS } from "../../../utils/constants";
import { IconDelete } from "../../Components/Icons";

const EditServicesTypes: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({});
  const [defaultServiceTemplate, setDefaultServiceTemplate] = useState<any>(null);
  const [defaultTemplateOptions, setDefaultTemplateOptions] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    fetchData();
    fetchDefaultTemplates();
  }, [])

  const fetchDefaultTemplates = () => {
    gLoader(true);
    service?.quickListDefaultServiceTemplateAdmin()
      .then((res: any) => {
        gLoader(false);
        const defaultTemplates = res?.data?.response?.list?.map((item: any) => ({
          name: item?.title,
          value: item?.id,
          custom_fields: item?.custom_fields
        }));
        setDefaultTemplateOptions(defaultTemplates);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const fetchData = () => {
    gLoader(true);
    service?.detailCompanyServiceType(data?.service_type_id)
      .then((res: any) => {
        gLoader(false);
        let newDetail = res?.data?.response?.detail;
        setPayload({ ...payload, ...newDetail });
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    service?.updateCompanyServiceType(data?.service_type_id, payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  const handleDefaultTemplateChange = (value: any, name: string) => {
    setDefaultServiceTemplate(value);
    const fieldObj = defaultTemplateOptions.find((field: any) => field?.value == value);
    let defaultCustomFields = fieldObj?.custom_fields || [];
    setPayload({
      ...payload,
      ['title']: fieldObj?.name,
      ['custom_fields']: defaultCustomFields,
    });
  };

  const handleArrayChange = (value: any, name: string, index: any) => {
    const payloadArr = [...payload.custom_fields];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    handleChange(payloadArr, 'custom_fields');
  };

  const addMore = () => {
    const payloadArr = [...payload?.custom_fields]
    payloadArr.push({});
    handleChange(payloadArr, 'custom_fields');
  }

  const handleRemove = (index: any) => {
    const payloadArr = [...payload.custom_fields];
    payloadArr.splice(index, 1);
    handleChange(payloadArr, 'custom_fields');
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Edit Service Type" />
        <FieldGroup column={1}>
          <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
          <Dropdown
            label="Default Service Templates"
            name="default_service_template"
            value={defaultServiceTemplate}
            handleChange={(value, name) => { handleDefaultTemplateChange(value, name) }}
            options={defaultTemplateOptions}
          />
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <InputCheckbox
            label="Has recurrence"
            name="has_recurrence"
            handleChange={handleChange}
            checked={payload?.has_recurrence}
          />
        </FieldGroup>

        <br />
        <TableForm headers={[
          { title: 'Name', width: '40%' },
          { title: 'Type', width: '40%' },
          { title: 'Is Required', width: '20%' },
        ]}>
          {payload?.custom_fields?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <Input
                  name="name"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.custom_fields?.[index]?.name}
                />
              </td>
              <td>
                <Dropdown
                  name="type"
                  value={payload?.custom_fields?.[index]?.type}
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  options={CS_FIELD_TYPES}
                />
              </td>
              <td>
                <InputCheckbox
                  name="is_required"
                  handleChange={(value, name) => {
                    handleArrayChange(value, name, index);
                  }}
                  checked={Boolean(payload?.custom_fields?.[index]?.is_required)}
                />
              </td>
              <td>
                <IconDelete size={18} onClick={() => handleRemove(index)} />
              </td>
            </tr>
          ))}
        </TableForm>
        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          <Button title="Add More" onClick={() => addMore()} theme="primary" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default EditServicesTypes;
