import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "asbawa-react-ui-ts-test-b"
import { Badge, TextLink } from ".."
import { IconAngleLeft, IconSettings, IconView } from "../Icons"


export interface InputDateTimeProps {
  label?: string;
  name?: string;
  value?: any;
  onChange: (value: Date | string | undefined | null, name: string) => void;
  error?: string | null;
  disabled?: boolean;
  required?: boolean;
}

const formatDate = (date: Date) => {
  if (date) {
    let parsedDate: Date;
    if (typeof date === 'string') {
      parsedDate = new Date(date);
    } else {
      parsedDate = date;
    }
    const year = parsedDate.getFullYear();
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = parsedDate.getDate().toString().padStart(2, '0');
    const hours = parsedDate.getHours().toString().padStart(2, '0');
    const minutes = parsedDate.getMinutes().toString().padStart(2, '0');
    const seconds = parsedDate.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  return '';
};

export const InputDatetime: FC<InputDateTimeProps> = ({
  onChange,
  name,
  value,
  label,
  required,
  disabled,
  error,
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(value);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleDateChange = (datestring: string | null) => {
    if (datestring) {
      const selectedDateObject = new Date(datestring);
      if (!isNaN(selectedDateObject.getTime())) {
        setSelectedDate(formatDate(selectedDateObject));
        onChange(formatDate(selectedDateObject), name || "");
      } else {
        setSelectedDate(null);
        onChange(null, name || "");
      }
    } else {
      setSelectedDate(null);
      onChange(null, name || "");
    }
  };

  return (
    <div className="asr-form-input-wrapper">
      {label && (
        <label className="asr-form-label">
          {label} {required && "*"}
        </label>
      )}
      <div className="asr-form-input-out">
        <input
          type="datetime-local"
          name={name}
          value={selectedDate ? selectedDate : ""}
          onChange={(e) => handleDateChange(e.target.value)}
          disabled={disabled}
        />
      </div>
      {error && <p className="asr-form-input-error">{error}</p>}
    </div>
  );
};