import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import EditServicesTypes from "./EditServicesTypes";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { toCamelCaseFirst, toYesNo } from "../../../utils/commonFunctions";
import { CS_BOOLEAN_TRUE } from "../../../utils/constants";

const DetailServicesTypes = () => {
  const { service_type_id } = useParams<{ service_type_id: string }>();
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [serviceTypeDetail, setServiceTypeDetail] = useState<any>([]);

  const handleSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchData()
  }, [service_type_id])

  const fetchData = () => {
    gLoader(true);
    service?.detailCompanyServiceType(service_type_id)
      .then((res: any) => {
        gLoader(false);
        setServiceTypeDetail(res?.data?.response?.detail)
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })

  }
  return <Card>
    <CardHeader title='Service Type Detail' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        /></>
    } />

    {showEdit && (
      <EditServicesTypes
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={handleSubmit}
        data={{ service_type_id }}
      />
    )}

    <SubContent heading="" style={{ display: "flex" }}>
      <LabelDescriptions
        style={{ width: "50%" }}
        list={[
          ["Id", serviceTypeDetail?.id],
          ["Service Type", serviceTypeDetail?.title],
          ["Has Recurrence", toYesNo(CS_BOOLEAN_TRUE.includes(serviceTypeDetail?.has_recurrence))],
          ["Status", <Badge colorTheme='B' text={serviceTypeDetail?.status} />],

        ]}
      />
    </SubContent>

    <SubContent heading="Custom Fields">
      <TableForm headers={[
        { title: 'Name', width: '40%' },
        { title: 'Type', width: '40%' },
        { title: 'Is Required', width: '20%' },
      ]}>
        {serviceTypeDetail?.custom_fields?.map((item: any, index: number) => (
          <tr key={index}>
            <td>{item?.name}</td>
            <td>{toCamelCaseFirst(item?.type)}</td>
            <td>{CS_BOOLEAN_TRUE.includes(item?.is_required) ? "true" : "false"}</td>
          </tr>
        ))}
      </TableForm>
    </SubContent>
  </Card>
}

export default DetailServicesTypes;
