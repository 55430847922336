
import { Outlet, useNavigate } from "react-router-dom";
import { FC } from "react";
import Tabs from "../../../Components/Tabs";
const CommunicationLayout = (): JSX.Element | null => {
  
  return <>
    <div className="client-ly-wrapper">
    
    <Tabs showBack={false} links={links}/>
    <Outlet/>
    </div>

  </>
};

export default CommunicationLayout;
const links = [
  {title:'Mailer Settings',goto:'mailer'},
  {title:'Email Templates',goto:'email/templates'},
  {title:'Email Footer',goto:'email/footer'}
];
