import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface dataType {
  pageNo: number;
  pageSize: number;
}

export function useListPagination({ listName }: { listName?: string | null }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<dataType>({
    pageNo: parseInt(searchParams.get("pageNo") || "1") || 1,
    pageSize: parseInt(searchParams.get("pageSize") || "10") || 10,
  });

  useEffect(() => {
    setData({
      pageNo: parseInt(searchParams.get("pageNo") || "1") || 1,
      pageSize: parseInt(searchParams.get("pageSize") || "10") || 10,
    });
  }, [searchParams]);
  const setDataValue = (key: string, value: string) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set(key, value);
    setSearchParams(newUrl.search);
  };

  const setPageNo = (pageNo: number | string) => {
    setDataValue("pageNo", pageNo.toString());
  };

  const setPageSize = (pageSize: number | string) => {
    setDataValue("pageSize", pageSize.toString());
  };


  return {
    listName,
    pageNo: data?.pageNo,
    pageSize: data?.pageSize,
    setPageNo,
    setPageSize,
  };
}
