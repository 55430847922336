import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import { SubContent } from "../../Components";
import { toNull } from "../../../utils/commonFunctions";
import { CS_BOOLEAN_TRUE, CS_STATUS } from "../../../utils/constants";
import { ModalFormProps } from "../../../types";
import paymentService from "../../../services/axios/payments";
import TableForm from "../../Components/TableForm";
import { IconDelete } from "../../Components/Icons";


const EditInvoice: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({ client_id: data?.client_id });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [])



  const fetchData = () => {
    gLoader(true);
    paymentService?.detailInvoice(data?.invoice_id)
      .then((res: any) => {
        gLoader(false);
        let newDetail = res?.data?.response?.detail;
        // setPayload({ ...payload, ...newDetail });
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          ...newDetail
        }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  }


  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    // gLoader(true);
    paymentService?.updateInvoice(data?.invoice_id, payload)
      .then((res: any) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };


  const handleArrayChange = (value: any, name: string, index: any) => {
    const payloadArr = [...payload.items];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    handleChange(payloadArr, 'items');
  };

  const addMore = () => {
    const payloadArr = [...payload?.items]
    payloadArr.push({})
    handleChange(payloadArr, 'items');
  }

  const handleRemove = (index: any) => {
    const payloadArr = [...payload.items];
    payloadArr.splice(index, 1);
    handleChange(payloadArr, 'items');
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Edit Invoice" />
        <br />
        {/* <FieldGroup column={1}>
        <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
        </FieldGroup> */}
        
        <TableForm 
        onAddRow={addMore}
        headers={[
          { title: 'Title', width: '80%' },
          { title: 'Price', width: '20%' },
        ]}>
          {payload?.items?.map((itm: any, index: number) => (
            <tr key={index}>
              <td>
                <Input
                  name="title"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.items?.[index]?.title}
                />
              </td>
              <td>
                <Input
                  name="unit_price"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.items?.[index]?.unit_price}
                />
              </td>
              <td>
                <IconDelete size={18} onClick={() => handleRemove(index)} />
              </td>
            </tr>
          ))}
        </TableForm>
        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default EditInvoice;
