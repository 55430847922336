import { Button, Card, CardHeader, List, ListBody, ListHeadType, confirmPopUp, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Badge, LabelDescriptions, SubContent, TextLink } from "../../Components";
import { CS_BOOLEAN_TRUE } from "../../../utils/constants";
import clientCommunicationService from "../../../services/axios/settings/communication/clientCommunication";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import { toNull } from "../../../utils/commonFunctions";

const ClientCommunicationDetail = () => {
  const { communication_id, client_id } = useParams<{ communication_id: string, client_id: string }>();
  const [commDetail, setCommDetail] = useState<any>([]);

  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, [communication_id])

  const fetchData = () => {
    gLoader(true);
    clientCommunicationService?.detailClientCommunication(communication_id)
      .then((res: any) => {
        gLoader(false);
        setCommDetail(res?.data?.response?.detail)
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }
  return <>
  <Card>
    <CardHeader title="Email Details" rightButtons={
      <>
      </>
    } />

      <LabelDescriptions
        list={[
          ["From", commDetail?.email_from_address],
          ["To", commDetail?.email_to_address?.map((itm: any) => itm?.name + '-' + itm?.address).join(',  ')],
          ["Subject", commDetail?.email_subject],
          ["Sent at", utcToLocalDatetime(commDetail?.sent_at)],
        ]}
      />
    </Card>
        <br/>
        <br/>
        <br/>

    <Card style={{maxWidth:'800px'}}>
      <div dangerouslySetInnerHTML={{ __html: commDetail?.email_body }}></div>
    </Card>

    <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

    </>
  
}


export default ClientCommunicationDetail