import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  InputFile,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import clientService from "../../services/axios/clients";
import { ModalFormProps } from "../../types";

const ImportClientData: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({ custom_fields: [{}] });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (file: File | null) => {
    setFile(file);
  };

  const handleSubmit = () => {
    if (!file) return;
    setButtonLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    clientService?.uploadClientData(formData)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
      })
      .catch((err) => {
        setButtonLoading(false);
        if (err?.field_errors) {
          errorPopUp(`${err?.message} : \n ${JSON.stringify(err?.field_errors)}`);
        } else {
          errorPopUp(err?.message);
        }
        // setErrors(err?.field_errors);
      });
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "500px" }}>
        <CardHeader title="Import Excel / Csv File" />
        <FieldGroup column={1}>
          <InputFile
            buttonTitle="Select File"
            accept=".csv,.xlsx,.xls"
            handleChange={(file) => handleFileChange(file)}
            error={errors?.file}
          />
        </FieldGroup>
        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default ImportClientData;
