import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const ClientDocumentView: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {

  const [fileType, setFileType] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<any>();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );

  useEffect(() => {
    fetchPreview();
  }, [])

  const fetchPreview = () => {
    gLoader(true);
    documentService?.viewDocument(data?.drive_document_id, {})
      .then((res: any) => {
        gLoader(false);
        const blob = res.data;
        if (blob) {
          setFileUrl(URL.createObjectURL(blob));
          setFileType(res.headers.get('Content-Type'));
        }
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const renderPreviewContent = () => {
    return (
      <div>
        {fileType && fileType.startsWith('image/') ? (
          <img src={fileUrl} alt="Image" style={{ objectFit: 'contain' }} />
        ) : fileType === 'application/pdf' ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <nav>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button size="small" title="Prev" onClick={goToPrevPage} />
                <Button size="small" title="Next" onClick={goToNextPage} />
              </nav>
            </div>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "1200px" }} >
        {renderPreviewContent()}
        <br />
        <ButtonsGroup>
          <Button title="Close" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default ClientDocumentView;
