import { Button, ButtonsGroup, Card, CardHeader, Dropdown, FieldGroup, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import taskService from "../../../services/axios/tasks";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import TaskScheduleRecurrenceEdit from "./TaskScheduleRecurrenceEdit";
import { toNull } from "../../../utils/commonFunctions";

const TaskScheduleRecurrenceDetail = () => {
  const { task_schedule_recurrence_id } = useParams<{ task_schedule_recurrence_id: string }>();
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [taskScheduleRecurrenceDetail, setTaskScheduleRecurrenceDetail] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const editSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchData()
  }, [task_schedule_recurrence_id])

  const fetchData = () => {
    gLoader(true);
    taskService?.detailTaskScheduleReccurence(task_schedule_recurrence_id)
      .then((res: any) => {
        gLoader(false);
        setTaskScheduleRecurrenceDetail(res?.data?.response?.detail);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  return <Card>
    <CardHeader title='Task Reccurence Detail' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        />
      </>
    } />

    {showEdit && (
      <TaskScheduleRecurrenceEdit
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={editSubmit}
        data={{ task_schedule_recurrence_id }}
      />
    )}

    <SubContent heading="General Detail" style={{ display: "flex" }}>
      <LabelDescriptions
        style={{ width: "100%" }}
        list={[
          ["Id", taskScheduleRecurrenceDetail?.id],
          ["Title", taskScheduleRecurrenceDetail?.title],
          ["Description", taskScheduleRecurrenceDetail?.description],
          ["Trigger Interval", taskScheduleRecurrenceDetail?.trigger_interval],
          ["Trigger On", taskScheduleRecurrenceDetail?.trigger_on == "LAST_DAY_OF_MONTH" ? "Last Day Of Month" : taskScheduleRecurrenceDetail?.trigger_on],
          ["Trigger Time", taskScheduleRecurrenceDetail?.trigger_time],

          ["Status", <Badge colorTheme='B' text={taskScheduleRecurrenceDetail?.status} />],
        ]}
      />
    </SubContent>
    {
      (toNull(taskScheduleRecurrenceDetail?.object) != null) ?
        <SubContent heading="Linked To" style={{ display: "flex" }}>
          <LabelDescriptions
            style={{ width: "100%" }}
            list={[
              ["Linked To", "Id: " + taskScheduleRecurrenceDetail?.object_id + ', Type: ' + taskScheduleRecurrenceDetail?.object + ', Title: ' + taskScheduleRecurrenceDetail?.service_type_title],
            ]}
          />
        </SubContent> :
        null
    }

    {
      (taskScheduleRecurrenceDetail?.object === "SERVICE") ?
        <SubContent heading="Client Detail" style={{ display: "flex" }}>
          <LabelDescriptions
            style={{ width: "100%" }}
            list={[
              ["Name", taskScheduleRecurrenceDetail?.client_firstname + " " + taskScheduleRecurrenceDetail?.client_lastname],
              ["Email", taskScheduleRecurrenceDetail?.client_email],
              ["Phone", taskScheduleRecurrenceDetail?.client_phone],
              ["Address", taskScheduleRecurrenceDetail?.client_address_line],
              ["City", taskScheduleRecurrenceDetail?.client_city],
              ["Province", taskScheduleRecurrenceDetail?.client_province],
              ["Country", taskScheduleRecurrenceDetail?.client_country],
              ["Postal Code", taskScheduleRecurrenceDetail?.client_postalcode],
            ]}
          />
        </SubContent> :
        null
    }

  </Card>
}

export default TaskScheduleRecurrenceDetail