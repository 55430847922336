import { Button, ButtonsGroup, Card, CardHeader, Dropdown, FieldGroup, Input, InputFile, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import "./StripeSettings.css";
import { useEffect, useState } from "react";
import authService from "../../../services/axios/auth";
import stripeService from "../../../services/axios/settings/plugins/stripe";
import { setIsStripeEnabled } from "../../../store/authSlice";
import { useDispatch } from "react-redux";
const StripeSettings = () => {
    const [payload, setPayload] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const dispatch: any = useDispatch();

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        gLoader(true);
        stripeService?.detailStripeConfigs()
            .then((res: any) => {
                gLoader(false);
                let newDetail = res?.data?.response?.detail;
                setPayload((prevPayload: any) => ({
                    ...prevPayload,
                    ...newDetail
                }));
            })
            .catch((err: any) => {
                gLoader(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    }

    const handleSubmit = () => {
        setButtonLoading(true);
        stripeService?.addStripeConfigs(payload)
            .then((res:any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
                dispatch(setIsStripeEnabled(true));
            })
            .catch((err:any) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    };

    const handleChangeStripe = (value: any, name: any) => {
        let stripeConfigs = { ...payload.stripe_configs };
        stripeConfigs[name] = value;
        setPayload({
            ...payload,
            ['stripe_configs']: stripeConfigs,
        });
        setErrors({
            ...errors,
            [name]: null
        })
    };

    return (
        <>
            <Card>
                <CardHeader
                    title="Stripe Settings"
                />
                <div className="prof-sett-top" style={{ display: 'flex', maxWidth: '800px' }}>
                    <div className="right-form">
                        <FieldGroup column={1}>

                            <Input
                                label="Stripe Publishable Key"
                                name="stripe_publishable_key"
                                handleChange={handleChangeStripe}
                                value={payload?.stripe_configs?.stripe_publishable_key}
                                error={errors?.stripe_publishable_key}
                            />
                            <Input
                                label="Stripe Secret Key"
                                name="stripe_secret_key"
                                handleChange={handleChangeStripe}
                                value={payload?.stripe_configs?.stripe_secret_key}
                                error={errors?.stripe_secret_key}
                            />
                            <Input
                                label="Webhook Secret Key"
                                name="stripe_webhook_secret_key"
                                handleChange={handleChangeStripe}
                                value={payload?.stripe_configs?.stripe_webhook_secret_key}
                                error={errors?.stripe_webhook_secret_key}
                            />

                        </FieldGroup>
                        <ButtonsGroup>
                            <Button title="Update" onClick={handleSubmit} theme="success" loading={buttonLoading} />
                        </ButtonsGroup>
                    </div>

                </div>

            </Card>

        </>
    );
};

export default StripeSettings;
