import "./style.css";
import MainContent from "../../Components/MainContent";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonsGroup,
  Card,
  CardHeader,
  FieldGroup,
  List,
  ListBody,
  ListHeadType,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import { Grid, LabelDescriptions } from "../../Components";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import subscriptionService from "../../../services/axios/subscriptions";
import { toNull, toYesNo } from "../../../utils/commonFunctions";
const Plans = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [list, setList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    gLoader(true);
    subscriptionService?.listPlan({})
      .then((res: any) => {
        gLoader(false);
        let response = res?.data?.response;
        setList(response?.list);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const handleSubmit = (id: any) => {
    setButtonLoading(true);
    subscriptionService?.addOrder({ plan_id: id })
      .then((res) => {
        setButtonLoading(false);
        // successPopUp(res?.data?.message);
        const paymentUrl = res?.data?.response?.payment_url;

        if (toNull(paymentUrl) != null) {
          window.location.href = paymentUrl;
        } else {
          successPopUp("Free Plan Activated");
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        // setErrors(err?.field_errors);
      })
  };

  return (
    <MainContent
      title="Subscription Plans"
      style={{ maxWidth: '1400px' }}
      rightButtons={
        <>
        </>
      }
    >

      <Grid column={2}>
        {
          list?.length > 0 ?
            list.map((itm: any, index: number) => {
              return (
                <Card style={{ flexGrow: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ fontWeight: '600', fontSize: '1.3em' }}>{itm.name}</div>
                    <div style={{ fontWeight: '500', fontSize: '1.1em' }}>{itm.price == 0 ? <FreeSpan /> : `$${itm.price}`}</div>
                  </div>

                  {/* <SubContent heading={itm.name}> */}
                  <LabelDescriptions
                    list={[
                      ["Allowed Clients:", itm.allowed_clients],
                      // ["Type:", itm.type],
                      ["Stripe:", toYesNo(itm.is_stripe_enabled)],
                      ["Google Drive:", toYesNo(itm.is_google_drive_enabled)],
                      ["Custom Emails:", toYesNo(itm.is_custom_emails_enabled)],
                      ["Documents:", toYesNo(itm.is_ask_for_documents_enabled)],
                    ]}
                  />
                  <ButtonsGroup>
                    <Button title="Order Now" onClick={() => handleSubmit(itm.id)} theme="primary" loading={buttonLoading} />
                  </ButtonsGroup>
                  {/* </SubContent> */}
                </Card>
              );
            }) :
            "No Subscription Plans Found"
        }

      </Grid>
    </MainContent>
  );
};

export default Plans;


const FreeSpan: FC = () => {
  return <span style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red', fontSize: '24px', background: '#ffff36', padding: '1px 8px', borderRadius: '2px' }}>Free</span>
}