import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

const ClientPaymentStatus = () => {
  const location = useLocation();
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setStatus(searchParams.get('status') || '');
  }, [location.search]);

  return (status && status == "Success") ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Card style={{ display: "flex", height: "300px", width: "400px", alignItems: 'center', justifyContent: "center" }}><h1>Payment Successfull</h1></Card></div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Card style={{ display: "flex", height: "300px", width: "400px", alignItems: 'center', justifyContent: "center" }}><h1>Payment Failed</h1></Card></div>
  );
};

export default ClientPaymentStatus;
