import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class paymentService {
    //Payments
    askClientForPayment = async (payload = {}) => {
        return await postRequest(`company/payment/client/ask-for-payment`, payload);
    }


    //Invoices Routes
    invoiceListForAskPayment = async (client_id: any, urlParam = {}) => {
        return await getRequest(`company/payment/invoice/list-for-ask-payment/${client_id}`, urlParam);
    }
    listInvoice = async (id: any, urlParam = {}) => {
        return await getRequest(`company/payment/invoice`, urlParam);
    }
    detailInvoice = async (id: any, urlParam = {}) => {
        return await getRequest(`company/payment/invoice/${id}`, urlParam);
    }
    addInvoice = async (payload = {}) => {
        return await postRequest(`company/payment/invoice`, payload);
    }
    updateInvoice = async (id: any, payload = {}) => {
        return await putRequest(`company/payment/invoice/${id}`, payload);
    }
    deleteInvoice = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/payment/invoice/${id}`, urlParam);
    }


    //Transaction Routes
    listTransaction = async (id: any, urlParam = {}) => {
        return await getRequest(`company/payment/transaction`, urlParam);
    }


    //PAyment Requests Routes
    listPaymentRequests = async (urlParam = {}) => {
        return await getRequest(`company/payment/requests`, urlParam);
    }

    // webhookPaymentStatus = async (com_uuid: any, urlParam = {}) => {
    //     return await getRequest(`client/payment/stripe-webhook/${com_uuid}`, urlParam);
    // }
}

const instance = new paymentService();

export default instance;

