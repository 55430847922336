import { Card, CardHeader } from 'asbawa-react-ui-ts-test-b'

const EmailsSettings =()=>{
    return <>
        <Card>
            <CardHeader title='Email Settings'/>
            Email setting will be here
        </Card>
    </>
}

export default EmailsSettings