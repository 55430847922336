import {
  Button,
  ButtonsGroup,
  Card,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Label,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import documentService from "../../../services/axios/documents";
import { ModalFormProps } from "../../../types";

const ClientDocumentsAddFolder: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}) => {
  const [payload, setPayload] = useState<any>({client_id:data?.client_id});
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    documentService?.addFolder(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  return (
    <>
      <Modal show={show} style={{ width: "400px",height:'200px',minHeight:'300px' }}>
        <CardHeader title="Create New Folder" />
        <Input
          label="Folder Name"
          name='name'
          handleChange={handleChange}
          value={payload?.name}
        />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={onCancel} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default ClientDocumentsAddFolder;
