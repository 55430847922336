import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import taskService from "../services/axios/tasks";
// import { AppDispatch } from "../store";

export const getRemindersData = (param?: any) => async (dispatch: any) => {
  return taskService
    .getRemindersData(param)
    .then(async (resp) => {
      dispatch(setReminderCount(resp?.data?.response?.count));
      return resp;
    })
    .catch((error) => {
      dispatch(setReminderCount(0));
      throw error;
    });
};

export const getTasksData = (param?: any) => async (dispatch: any) => {
  return taskService
    .getTasksData(param)
    .then(async (resp) => {
      dispatch(setTasksCount(resp?.data?.response?.count));
      return resp;
    })
    .catch((error) => {
      dispatch(setTasksCount(0));
      throw error;
    });
};


interface taskState {
  remindersCount: number,
  tasksCount: number
}

const initialState: taskState = {
  remindersCount: 0,
  tasksCount: 0
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setReminderCount: (state, action: PayloadAction<number>) => {
      state.remindersCount = action.payload
    },
    setTasksCount: (state, action: PayloadAction<number>) => {
      state.tasksCount = action.payload
    }
  },
});

export const { setReminderCount, setTasksCount } = taskSlice.actions;

export default taskSlice.reducer;
