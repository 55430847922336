import moment from 'moment';
import 'moment-timezone';
import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import { ModalFormProps } from "../../../types";
import taskService from "../../../services/axios/tasks";
import { SubContent } from "../../Components";
import { CS_TASK_INTERVAL, CS_TASK_MONTH_DAYS, CS_TASK_OBJECT_TYPE, CS_TASK_WEEKDAYS } from "../../../utils/constants";
import companyServices from "../../../services/axios/settings/companyServices";
import { toNull } from "../../../utils/commonFunctions";

const TaskScheduleRecurrenceAdd: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({ object: data?.object, client_id: data?.client_id, object_id: data?.object_id });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  // const [clientsList, setClientsList] = useState<any>([]);
  // const [servicesList, setServicesList] = useState<any>([]);

  useEffect(() => {
    //fetchCustomFields();
  }, [])

  // const fetchClientQuickList = async () => {
  //   gLoader(true);
  //   companyServices?.quickListOfClientsWithServices()
  //     .then(async (res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.client_firstname + " " + item?.client_lastname,
  //         value: item?.client_id,
  //       }));
  //       setClientsList(dropdownOptionsFolders);
  //       // await fetchServiceQuickList();
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message)
  //     })
  // }
  // const fetchServiceQuickList = async (value: any) => {
  //   gLoader(true);
  //   companyServices?.quickListCompanyServiceToClient({ client_id: value })
  //     .then((res: any) => {
  //       gLoader(false);
  //       const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
  //         name: item?.services_type_title,
  //         value: item?.id,
  //       }));
  //       setServicesList(dropdownOptionsFolders);
  //     })
  //     .catch((err: any) => {
  //       gLoader(false);
  //       errorPopUp(err?.message)
  //     })
  // }

  // const userTimezone = moment.tz.guess();

  const handleChange = (value: any, name: any) => {
    // if (name === 'trigger_time') {
    //   const utcTime = moment(value, 'HH:mm:ss').format()
    //   //const utcTime = moment(value, 'HH:mm:ss').utc().format(); //2024-08-24T06:25:50Z
    //   //const utcTime = moment(value, 'HH:mm:ss').utc().format('HH:mm:ss'); //HH:mm:ss
    // } 
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null
    })

    // if (name === "object" && toNull(value) != null) {
    //   fetchClientQuickList();
    // }
    // if (name === "client_id" && toNull(value) != null) {
    //   setServicesList([]);
    //   fetchServiceQuickList(value);
    // } else if (name === "client_id" && toNull(value) == null) {
    //   setServicesList([]);
    // }
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    taskService?.addTaskScheduleReccurence(payload)
      .then((res) => {
        // gLoader(false);
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
        //navigate('/')
      })
      .catch((err) => {
        // gLoader(false);
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Add Task Reccurence" />
        <FieldGroup column={3}>
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <textarea
            value={payload?.description}
            onChange={(e) => handleChange(e.target.value, 'description')}
            placeholder="Add Description Here ..."
            style={{
              width: '100%',
              height: '100px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
          <Dropdown
            label="Trigger Interval"
            name="trigger_interval"
            value={payload?.trigger_interval}
            handleChange={handleChange}
            options={CS_TASK_INTERVAL}
            error={errors?.trigger_interval}
          />
          {/* <input
            type="time"
            name="trigger_time"
            value={moment(payload?.trigger_time).format('HH:mm:ss')}
            // value={moment(payload?.trigger_time, 'HH:mm:ss').format('HH:mm:ss')}
            // value={moment.tz(payload?.trigger_time, 'HH:mm:ss', userTimezone).format('HH:mm:ss')}
            // value={payload?.trigger_time}
            onChange={(e) => handleChange(e.target.value, 'trigger_time')}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '4px',
              border: errors?.trigger_time ? '1px solid red' : '1px solid #ccc',
            }}
          />
          {errors?.trigger_time && (
            <div style={{ color: 'red', fontSize: '12px' }}>
              {errors.trigger_time}
            </div>
          )} */}
          <InputTime
            label="Trigger Time"
            name="trigger_time"
            handleChange={handleChange}
            value={payload?.trigger_time}
            error={errors?.trigger_time}
          />
          {
            (payload?.trigger_interval == "WEEKLY") ?
              <Dropdown
                label="Trigger On"
                name="trigger_on"
                value={payload?.trigger_on}
                handleChange={handleChange}
                options={CS_TASK_WEEKDAYS}
                error={errors?.trigger_on}
              />
              :
              (payload?.trigger_interval == "MONTHLY") ?
                <Dropdown
                  label="Trigger On"
                  name="trigger_on"
                  value={payload?.trigger_on}
                  handleChange={handleChange}
                  options={CS_TASK_MONTH_DAYS}
                  error={errors?.trigger_on}
                />
                :
                null
          }
        </FieldGroup>

        {/* <FieldGroup column={1}>
          <Dropdown
            label="Linked To"
            name="object"
            value={payload?.object}
            handleChange={handleChange}
            options={CS_TASK_OBJECT_TYPE}
            error={errors?.object}
          />
        </FieldGroup> */}
        {/* {
          (payload?.object == "SERVICE") ?
            <FieldGroup column={3}>
              <Dropdown
                label="Select Client"
                name="client_id"
                value={payload?.client_id}
                handleChange={handleChange}
                options={clientsList}
                error={errors?.client_id}
              />
              <Dropdown
                label="Select Service"
                name="object_id"
                value={payload?.object_id}
                handleChange={handleChange}
                options={servicesList}
                error={errors?.object_id}
              />
            </FieldGroup>
            :
            null
        } */}




        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default TaskScheduleRecurrenceAdd;
