import "./style.css";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import { Badge, TextLink } from "../../Components";
import MainContent from "../../Components/MainContent";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import AddDocumentsTypes from "./AddDocumentsTypes";
import documentService from "../../../services/axios/documents";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
const ListDocumentsTypes = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize,filters,search])

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    documentService?.listDocumentType({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    documentService?.deleteDocumentType(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <MainContent
    
    >
       <CardHeader
        title="Documents Types"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Add New"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
          </>
        }
      />
      {showAddNew && (
        <AddDocumentsTypes
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleSubmit}
        />
      )}

      <Card>
       {/* <ListSearch /> */}

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <td style={{ textAlign: 'center' }}><TextLink text={itm.id} title="View Document Type" goto={`${itm.id}`} /></td>
                  <td style={{ textAlign: 'center' }}><TextLink text={itm?.name} title="View Document Type" goto={`${itm.id}`} /></td>
                  <td style={{ display: 'flex', justifyContent: 'center' }}><Badge colorTheme='B' text={itm?.status} /></td>
                  {/* <td>{formatDate(itm?.added_on)}</td> */}
                  <td><ListRowActions
                    actions={[
                      { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                      { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                    ]}
                  /></td>
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
    </MainContent>
  );
};

export default ListDocumentsTypes;

const listHeaders: ListHeadType[] = [
  {
    title: "Id",
    width: "25%",
    sortName: "uid",
    align: "center",
  },
  {
    title: "Name",
    width: "25%",
    align: "center",
  },
  {
    title: "Status",
    width: "25%",
    align: "center",
  },
  //   {
  //     title: "Added On",
  //     width: "5%",
  //     align: "center",
  //   },
  {
    title: "Actions",
    width: "25%",
    align: "center",
  }
];
