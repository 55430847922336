import { Card, CardHeader } from 'asbawa-react-ui-ts-test-b'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import { TextLink } from '../../Components'

const SettingsLayout =()=>{
    const location = useLocation();
    return <>
    {
        location.pathname !=='/settings' && <TextLink text='Back to settings' goto='/settings'/>
    }
        <Outlet/>
    </>
}

export default SettingsLayout