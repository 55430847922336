import { Button, ButtonsGroup, Card, CardHeader, Dropdown, FieldGroup, Input, InputFile, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import "./ProfileSettings.css";
import { useEffect, useState } from "react";
import authService from "../../../services/axios/auth";
import UpdatePassword from "../../Auth/UpdatePassword";
import { useDispatch } from "react-redux";
import { setTimezone } from "../../../store/authSlice";
import { CS_TIMEZONES } from "../../../utils/constants";
const ProfileSettings = () => {
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    gLoader(true);
    authService?.profile()
      .then((res: any) => {
        gLoader(false);
        setPayload(res?.data?.response?.detail);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    authService?.updateProfile(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        dispatch(setTimezone(payload?.timezone));
        //navigate('/')
      })
      .catch((err) => {

        if(err?.field_errors){
          setErrors(err?.field_errors)
        }
        setButtonLoading(false);
        errorPopUp(err?.message);
      })
  };

  const handleFileChange = (file: File | null) => {
    setImage({
      ...image,
      ['image']: file,
    });
  };

  const handleImageSubmit = () => {
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("image", image.image);
    authService?.updateProfileImg(formData)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
      })
  };

  const onChangePassSuccess = () => {
    setShowChangePassword(false);
  };

  return (
    <>
      <Card>
        <CardHeader title="Profile Settings" rightButtons={
          <>
            <Button
              size="small"
              title="Update Password"
              theme="primary"
              onClick={() => setShowChangePassword(true)}
            />
          </>
        } />
        <div className="prof-sett-top">
          <div className="logo-wrapper">
            <FieldGroup column={1}>
              <div className="image-cropper">
                {payload.image ? (
                  <img className="image" src={`data:image/jpeg;base64,${payload.image}`} alt="Profile Image" />
                ) : (
                  <div>No Image</div>
                )}
              </div>
              <div style={{ maxWidth: '240px' }}>
                <InputFile
                  buttonTitle="Select Image"
                  handleChange={(file) => handleFileChange(file)}
                />
                <Button title="Update Image" onClick={handleImageSubmit} theme="success" loading={buttonLoading} />
              </div>
            </FieldGroup>

          </div>
          <div className="right-form">
            <FieldGroup column={2}>
              <Input
                required
                label="Name"
                name="name"
                handleChange={handleChange}
                value={payload?.name}
                error={errors?.name}
              />
              <Input
                required
                label="Address"
                name="address_line"
                handleChange={handleChange}
                value={payload?.address_line}
                error={errors?.address_line}
              />
              <Input
                required
                label="City"
                name="city"
                handleChange={handleChange}
                value={payload?.city}
                error={errors?.city}
              />
              <Input
                required
                label="Province"
                name="province"
                handleChange={handleChange}
                value={payload?.province}
                error={errors?.province}
              />
              <Input
                required
                label="Country"
                name="country"
                handleChange={handleChange}
                value={payload?.country}
                error={errors?.country}
              />
              <Input
                required
                label="Postal Code"
                name="postalcode"
                handleChange={handleChange}
                value={payload?.postalcode}
                error={errors?.postalcode}
              />
              <Input
                required
                label="Phone"
                name="phone"
                handleChange={handleChange}
                value={payload?.phone}
                error={errors?.phone}
              />
              <Input
                label="Document Request Message"
                name="document_request_msg"
                handleChange={handleChange}
                value={payload?.document_request_msg}
                error={errors?.document_request_msg}
              />
              <Input
                  name="tax_gst"
                  label="GST default rate"
                  handleChange={handleChange}
                  value={payload?.tax_gst}
                error={errors?.tax_gst}
                /><Input
                name="tax_hst"
                label="HST default rate"
                handleChange={handleChange}
                value={payload?.tax_hst}
                error={errors?.tax_hst}
              />
              {/* <Input
                required
                label="Timezone"
                name="timezone"
                handleChange={handleChange}
                value={payload?.timezone}
                error={errors?.timezone}
              /> */}
              <Dropdown
                label="Timezone"
                name="timezone"
                value={payload?.timezone}
                handleChange={handleChange}
                options={CS_TIMEZONES}
                error={errors?.timezone}
              />
            </FieldGroup>
            <ButtonsGroup>
              <Button title="Update" onClick={handleSubmit} loading={buttonLoading} theme="success" />
            </ButtonsGroup>
          </div>

        </div>

      </Card>
      {showChangePassword && (
        <UpdatePassword
          show={showChangePassword}
          onCancel={() => setShowChangePassword(false)}
          onSuccess={onChangePassSuccess}
        />
      )}
    </>
  );
};

export default ProfileSettings;
