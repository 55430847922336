import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "../../..";

class emailService {
    //Email Templates
    listEmailTemplate = async (urlParam = {}) => {
        return await getRequest(`company/communication/email/template`, urlParam);
    }
    detailEmailTemplate = async (id: any, urlParam = {}) => {
        return await getRequest(`company/communication/email/template/${id}`, urlParam);
    }
    addEmailTemplate = async (payload = {}) => {
        return await postRequest(`company/communication/email/template`, payload);
    }
    updateEmailTemplate = async (id: any, payload = {}) => {
        return await putRequest(`company/communication/email/template/${id}`, payload);
    }
    deleteEmailTemplate = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/communication/email/template/${id}`, urlParam);
    }
    quickListEmailTemplate = async (urlParam = {}) => {
        return await getRequest(`company/communication/email/template/quick/list`, urlParam);
    }

    //MAILER CONFIGS
    addMailerConfigs = async (payload = {}) => {
        return await postRequest(`company/communication/email/mailer-config`, payload);
    }
    detailMailerConfigs = async (urlParam = {}) => {
        return await getRequest(`company/communication/email/mailer-config`, urlParam);
    }

}

const instance = new emailService();

export default instance;

