import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";
import { CS_STATUS, CS_TASK_INTERVAL, CS_TASK_MONTH_DAYS, CS_TASK_OBJECT_TYPE, CS_TASK_WEEKDAYS } from "../../../utils/constants";
import taskService from "../../../services/axios/tasks";
import { toNull } from "../../../utils/commonFunctions";
import companyServices from "../../../services/axios/settings/companyServices";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";


const ReminderEdit: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    gLoader(true);
    taskService?.detailReminder(data?.reminder_id)
      .then((res: any) => {
        gLoader(false);
        let detail = res?.data?.response?.detail;
        let triggerDate = '';
        let triggerTime = '';
        if (toNull(detail.trigger_on) != null) {
          let triggerDateTime = utcToLocalDatetime(detail?.trigger_on)?.split(' ');
          triggerDate = triggerDateTime[0];
          triggerTime = triggerDateTime[1];
        }
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          ...detail,
          trigger_date: triggerDate,
          trigger_time: triggerTime,
          trigger_on: triggerDate + ' ' + triggerTime,
        }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleChange = (value: any, name: any) => {
    let trigger_on = '';
    if (name === "trigger_date") {
      let trigger_time = payload?.trigger_time;
      trigger_on = `${value} ${trigger_time}`;
    }
    if (name === "trigger_time") {
      let trigger_date = payload?.trigger_date;
      trigger_on = `${trigger_date} ${value}`;
    }
    setPayload({
      ...payload,
      [name]: value,
      ['trigger_on']: trigger_on,
    });
    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    taskService?.updateReminder(data?.reminder_id, payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Edit Reminder" />
        <FieldGroup column={1}>
        <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
        </FieldGroup>
        <FieldGroup column={3}>
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <textarea
            value={payload?.description}
            onChange={(e) => handleChange(e.target.value, 'description')}
            placeholder="Add Description Here ..."
            style={{
              width: '100%',
              height: '100px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
          <InputDate
            label="Trigger Date"
            name="trigger_date"
            handleChange={handleChange}
            value={payload?.trigger_date}
            error={errors?.trigger_date}
          />
          <InputTime
            label="Trigger Time"
            name="trigger_time"
            handleChange={handleChange}
            value={payload?.trigger_time}
            error={errors?.trigger_time}
          />
        </FieldGroup>

        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default ReminderEdit;
