import "./style.css";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import service from "../../services/axios/settings/companyServices";
import { Badge, TextLink } from "../Components";
import MainContent from "../Components/MainContent";
import ListRowActions, { ListPagination, ListSearch } from "../Components/List";
import AddDocumentsTypes from "./AddUser";
import documentService from "../../services/axios/documents";
import taskService from "../../services/axios/tasks";
import AddTaskStatus from "./AddUser";
import EditTaskStatus from "./EditUser";
import { useListPagination } from "../../hooks/useListPagination";
import { useListFilters } from "../../hooks/useListFilters";
import { useListSorting } from "../../hooks/useListSorting";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import userService from "../../services/axios/users";
const ListUser = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [userId, setUserId] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize,filters,search])

  const handleAddSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const handleShowEdit = (id: any) => {
    setUserId(id);
    setshowEdit(true);
  };
  const handleEditSuccess = () => {
    fetchData();
    setUserId(null);
    setshowEdit(false);
  };
  const handleEditCancel = () => {
    setshowEdit(false);
    setUserId(null);
  };

  const fetchData = () => {
    gLoader(true);
    userService?.listCompanyUser({ pageNo, pageSize, search:search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    userService?.deleteCompanyUser(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <MainContent
    >
      <CardHeader
        title="Users"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
           <Button
            size="small"
            title="Add New"
            theme="primary"
            onClick={() => setShowAddNew(true)}
          />
          </>
        }
      />
      {showAddNew && (
        <AddUser
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleAddSuccess}
        />
      )}

      {showEdit && (
        <EditUser
          show={showEdit}
          onCancel={handleEditCancel}
          onSuccess={handleEditSuccess}
          data={{ user_id: userId }}
        />
      )}

      <Card>

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <Td align="left">{itm?.user_name}</Td>
                  <Td align="left">{itm?.email}</Td>
                  <Td>{itm?.user_type}</Td>
                  <Td>
                    <ListRowActions
                      actions={[
                        // { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                        { icon: 'edit', onClick: () => handleShowEdit(`${itm.id}`) },
                        { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                      ]}
                    />
                  </Td>
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
    </MainContent>
  );
};

export default ListUser;

const listHeaders: ListHeadType[] = [
  {
    title: "Name",
    width: "30%",
    align: "left",
  },
  {
    title: "Email",
    width: "30%",
    align: "left",
  },
  {
    title: "Role",
    width: "15%",
    align: "center",
  },
  {
    title: "",
    width: "25%",
    align: "right",
  }
];
