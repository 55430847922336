import { useEffect } from "react";
import { toNull } from "../utils/commonFunctions";
import { useDispatch } from "react-redux";

export const SocketListeners = async (socket: any) => {
    if (socket) {
        socket.emit('register', {});
        //console.log(`Connected with Socket 1 : ${socket.id}`);

        socket.on('connect', () => {
           // console.log(`RE-connected with Socket 2 : ${socket.id}`);
            socket.emit('register', {});
        });

        // socket.on('getReminders', (data: any, callback: any) => {
        //     // setMessageList((prevMessages) => [...prevMessages, data]);
        //     // // getMessageList();
        //     console.log("ddddddddddddddddddddddddddddddddd", data);
        //     callback('received');
        // });
        // socket.on('getReminders', (data: any, callback: any) => {
        //     console.log('Received reminders:', data);
        //     dispatch(setReminderCount(2));
        //     try {
        //         // callback('received'); // Acknowledgement
        //     } catch (error) {
        //         // console.error('Failed to send acknowledgment:', error);
        //     }
        // });

        socket.on('disconnect', (reason: any) => {
            //  socket.emit('discon', user_id);
            // console.log(`Disconnected: ${reason}`);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
        };
    }

    return () => {
        socket.off('connect');
        socket.off('notification');
        socket.off('disconnect');
    };
}