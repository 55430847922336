
import { Button, Card, CardHeader, confirmPopUp, errorPopUp, gLoader, Input, List, ListBody, ListHeadType, successPopUp, Td } from "asbawa-react-ui-ts-test-b";
import { Badge, TextLink } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconDocuments } from "../../Components/Icons";
import paymentService from "../../../services/axios/payments";
import AddInvoice from "./AddInvoice";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import { CS_INVOICE_STATUS } from "../../../utils/constants";
const ClientInvoices = (): JSX.Element | null => {
  const { client_id } = useParams<{ client_id: string }>();
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
  }, [client_id, pageNo, pageSize, search])

  const handleAddNewSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    paymentService?.listInvoice('', { client_id, pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    paymentService?.deleteInvoice(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return <>
    <Card>


      <CardHeader
        title="Invoices"
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Create Invoice"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
          </>
        }
      />

      {showAddNew && (
        <AddInvoice
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleAddNewSuccess}
          data={{ client_id: client_id }}
        />
      )}

      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            const invStatus: any = CS_INVOICE_STATUS.find((field: any) => field.value == itm.invoice_status);
            const invStatusBgColor = invStatus?.bgColor;
            const invStatusColor = invStatus?.color;
            const invStatusName = invStatus?.name;
            return (
              <tr key={index}>
                <Td align="left"><TextLink text={itm?.cuid} title="View" goto={`${itm.id}`} /></Td>
                <Td>{utcToLocalDatetime(itm?.invoice_date)}</Td>
                <Td align="right">{itm?.sub_total}</Td>
                <Td align="right">{itm?.gst}</Td>
                <Td align="right">{itm?.hst}</Td>
                <Td align="right">{itm?.total_amount}</Td>
                <Td><Badge style={{maxWidth:'150px',margin:'auto'}} color={invStatusColor} bgColor={invStatusBgColor} text={invStatusName} /></Td>
                <Td><ListRowActions
                  actions={[
                    { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                  ]}
                /></Td>
              </tr>
            );
          })}
        </ListBody>
      </List>
      <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
    </Card>
  </>
};

export default ClientInvoices;
const listHeaders: ListHeadType[] = [

  {
    title: "CUID",
    width: "10%",
    align: "left",
  },
  {
    title: "Invoice Date",
    width: "20%",
    align: "center",
  },
  {
    title: "Sub Total",
    width: "10%",
    align: "right",
  },
  {
    title: "GST",
    width: "10%",
    align: "right",
  },
  {
    title: "HST",
    width: "10%",
    align: "right",
  },
  {
    title: "Amount",
    width: "10%",
    align: "right",
  },
  {
    title: "Payment Status",
    width: "20%",
    align: "center",
  },
  {
    title: "",
    width: "10%",
    align: "right",
  }
];