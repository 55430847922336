import { Outlet, useNavigate } from "react-router-dom";
import "./style.css";
import SideBar from "./SideBar";
import Header from "./Header";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Socket from "../../../sockets";

const PanelLayout = () => {
  const isLoggedIn = useSelector((store: any) => store.auth?.isLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/auth');
    }
  }, [isLoggedIn, navigate]);

  if (!isLoggedIn) {
    return null;
  }

  return (

    <div className="ly-panel-wrapper">
      <div className="ly-panel-header">
        <Socket />
        <Header />
      </div>
      <br />
      <div className="ly-panel-body">
        <div className="ly-panel-side-bar">
          <SideBar />
        </div>
        <div className="ly-panel-content">
          <br />
          <br />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PanelLayout;
