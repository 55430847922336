
import { Outlet, useNavigate } from "react-router-dom";
import './style.css'
import { FC } from "react";
import Tabs from "../../Components/Tabs";
const ClientLayout = (): JSX.Element | null => {
  
  return <>
    <div className="client-ly-wrapper">
    
    <Tabs links={links}/>
    <Outlet/>
    </div>

  </>
};

export default ClientLayout;
const links = [
  {title:'Profile',goto:''},
  {title:'Businesses',goto:'businesses'},
  {title:'Services',goto:'services'},
  {title:'Documents',goto:'documents'},
  {title:'Communication',goto:'communication'},
  // {title:'Invoices',goto:'invoices'},
  {title:'Payments & Invoices',goto:'payment-requests'},
  {title:'Tasks',goto:'task'},

];
