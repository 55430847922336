import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useMemo, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import { SubContent } from "../../Components";
import { toNull } from "../../../utils/commonFunctions";
import {
  CS_BOOLEAN_TRUE,
  CS_TASK_INTERVAL,
  CS_TASK_MONTHS,
  CS_TASK_MONTH_DAYS,
  CS_TASK_QUARTERLY,
  CS_TASK_SERVICES_INTERVAL,
  CS_TASK_WEEKDAYS,
} from "../../../utils/constants";
import { isArray } from "chart.js/dist/helpers/helpers.core";
import businessService from "../../../services/axios/businesses";

interface AddClientServiceProps {
  show: boolean | "BUSINESS" | "INDIVIDUAL";
  onCancel?: () => void;
  onSuccess?: () => void;
  data?: any;
}
const AddClientServiceMulti: FC<AddClientServiceProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data,
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({
    client_id: data?.client_id,
    category: show,
  });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [businessList, setBusinessList] = useState<any>([]);
  const [servicesTypeList, setServicesTypeList] = useState<any>([]);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  useEffect(() => {
    fetchServicesTypes();
    fetchBusinessList();
  }, []);

  const fetchServicesTypes = () => {
    gLoader(true);
    service
      ?.quickListCompanyServiceType()
      .then((res: any) => {
        gLoader(false);
        setServicesTypeList(res?.data?.response?.list);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };
  const fetchBusinessList = () => {
    gLoader(true);
    businessService
      ?.quicklist(data?.client_id, {})
      .then((res: any) => {
        gLoader(false);
        setBusinessList(
          res?.data?.response?.list?.map((obj: any) => {
            return { name: obj?.name, value: obj?.id };
          }) || []
        );
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const handleServiceChange = (
    key: string,
    value: any,
    service_type_id: number | null = null
  ) => {
    if (key == "service_type_id") {
      const service = selectedServices.find(
        (obj: any) => obj.service_type_id == value
      );
      if (service) {
        setSelectedServices(
          selectedServices.filter((obj: any) => obj.service_type_id != value)
        );
      } else {
        let oldServices = [...selectedServices];
        oldServices.push({ service_type_id: value, custom_fields: [] });
        setSelectedServices(oldServices);
      }
    } else {
      let oldServices = [...selectedServices];
      let curIndex = selectedServices.findIndex(
        (obj: any) => obj.service_type_id == service_type_id
      );
      let currentService = selectedServices[curIndex];
      currentService[key] = value;
      oldServices[curIndex] = currentService;
      setSelectedServices(oldServices);
    }
  };

  const handleServiceCfChange = (
    key: string,
    value: any,
    custom_field_id: number,
    service_type_id: number
  ) => {
    let oldServices = [...selectedServices];
    let curIndex = oldServices.findIndex(
      (obj: any) => obj.service_type_id == service_type_id
    );
    let currentService = oldServices[curIndex];
    let currentCfFields = currentService?.custom_fields || [];

    let hasCf = currentCfFields?.findIndex(
      (obj: any) => obj.id == custom_field_id
    );

    if (hasCf !== -1) {
      currentCfFields[hasCf] = {
        ...currentCfFields[hasCf],
        [key]: value,
      };
    } else {
      currentCfFields.push({
        id: custom_field_id,
        [key]: value,
      });
    }
    currentService.custom_fields = currentCfFields;
    oldServices[curIndex] = currentService;
    setSelectedServices(oldServices);
  };

  const handleSubmit = () => {
    setErrors({});
    gLoader(true);
    service
      ?.assignServices({ ...payload, services: selectedServices })
      .then((res) => {
        gLoader(false);
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        gLoader(false);
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.mixed_errors);
      });
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={Boolean(show)} style={{ maxWidth: "800px" }}>
        <CardHeader
          title={`Assign ${
            show == "BUSINESS" ? "Business" : "Individual"
          } Services`}
        />

        {show && show == "BUSINESS" ? (
          <Dropdown
            label="Business"
            name="business_id"
            value={payload?.business_id}
            handleChange={(value: any) => {
              setPayload({
                ...payload,
                business_id: value,
              });
            }}
            options={businessList}
            error={errors?.status}
          />
        ) : null}

        {servicesTypeList
          ?.filter((itm: any) => itm?.category == show)
          ?.map((itm: any) => {
            let selectedItem = selectedServices.find(
              (obj: any) => obj?.service_type_id == itm?.id
            );
            return (
              <div
                style={{
                  margin: "30px 0",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    background: "lightgray",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "20px" }}>
                    <InputCheckbox
                      handleChange={() =>
                        handleServiceChange("service_type_id", itm?.id)
                      }
                      checked={selectedItem}
                    />
                  </div>
                  {itm?.title}
                </div>
                {selectedItem && (
                  <div>
                    <FieldGroup column={3}>
                      {itm?.has_recurrence && (
                        <>
                          <Dropdown
                            label="Service Interval"
                            name="trigger_interval"
                            value={selectedItem?.trigger_interval}
                            handleChange={(value) =>
                              handleServiceChange(
                                "trigger_interval",
                                value,
                                itm?.id
                              )
                            }
                            options={CS_TASK_SERVICES_INTERVAL}
                            error={
                              errors?.services?.[itm?.id]?.trigger_interval
                            }
                          />

                          {selectedItem?.trigger_interval == "WEEKLY" && (
                            <Dropdown
                              label="Service Day"
                              name="trigger_on"
                              value={selectedItem?.trigger_on}
                              handleChange={(value) =>
                                handleServiceChange(
                                  "trigger_on",
                                  value,
                                  itm?.id
                                )
                              }
                              options={CS_TASK_WEEKDAYS}
                              error={errors?.services?.[itm?.id]?.trigger_on}
                            />
                          )}

                          {selectedItem?.trigger_interval == "MONTHLY" && (
                            <Dropdown
                              label="Service Day"
                              name="trigger_on"
                              value={selectedItem?.trigger_on}
                              handleChange={(value) =>
                                handleServiceChange(
                                  "trigger_on",
                                  value,
                                  itm?.id
                                )
                              }
                              options={CS_TASK_MONTH_DAYS}
                              error={errors?.services?.[itm?.id]?.trigger_on}
                            />
                          )}

                          {selectedItem?.trigger_interval == "QUARTERLY" && (
                            <>
                              <Dropdown
                                label="Service Month"
                                name="trigger_month"
                                value={selectedItem?.trigger_month}
                                handleChange={(value) =>
                                  handleServiceChange(
                                    "trigger_month",
                                    value,
                                    itm?.id
                                  )
                                }
                                options={CS_TASK_QUARTERLY}
                                error={
                                  errors?.services?.[itm?.id]?.trigger_month
                                }
                              />
                              <Dropdown
                                label="Service Day"
                                name="trigger_on"
                                value={selectedItem?.trigger_on}
                                handleChange={(value) =>
                                  handleServiceChange(
                                    "trigger_on",
                                    value,
                                    itm?.id
                                  )
                                }
                                options={CS_TASK_MONTH_DAYS}
                                error={errors?.services?.[itm?.id]?.trigger_on}
                              />
                            </>
                          )}
                          {selectedItem?.trigger_interval == "YEARLY" && (
                            <>
                              <Dropdown
                                label="Serive Month"
                                name="trigger_month"
                                value={selectedItem?.trigger_month}
                                handleChange={(value) =>
                                  handleServiceChange(
                                    "trigger_month",
                                    value,
                                    itm?.id
                                  )
                                }
                                options={CS_TASK_MONTHS}
                                error={
                                  errors?.services?.[itm?.id]?.trigger_month
                                }
                              />
                              <Dropdown
                                label="Service Day"
                                name="trigger_on"
                                value={selectedItem?.trigger_on}
                                handleChange={(value) =>
                                  handleServiceChange(
                                    "trigger_on",
                                    value,
                                    itm?.id
                                  )
                                }
                                options={CS_TASK_MONTH_DAYS}
                                error={errors?.services?.[itm?.id]?.trigger_on}
                              />
                            </>
                          )}
                        </>
                      )}
                    </FieldGroup>
                    <FieldGroup column={3}>
                      {itm?.custom_fields &&
                        itm?.custom_fields?.map((field: any, index: number) => {
                          const selectedField =
                            selectedItem?.custom_fields?.find(
                              (obj: any) => obj?.id == field?.id
                            ) || {};

                          if (field?.type == "BOOLEAN") {
                            return (
                              <InputCheckbox
                                label={field?.name}
                                name="custom_field_value"
                                handleChange={(value, name) => {
                                  handleServiceCfChange(
                                    "value",
                                    value,
                                    field?.id,
                                    itm?.id
                                  );
                                }}
                                checked={Boolean(selectedField?.value)}
                                error={
                                  errors?.services?.[itm?.id]
                                    ?.custom_field_errors?.[field?.id]
                                }
                              />
                            );
                          }

                          if (field?.type == "DATE") {
                            return (
                              <InputDate
                                label={field?.name}
                                name="custom_field_value"
                                handleChange={(value, name) => {
                                  handleServiceCfChange(
                                    "value",
                                    value,
                                    field?.id,
                                    itm?.id
                                  );
                                }}
                                value={selectedField?.value}
                                required={
                                  CS_BOOLEAN_TRUE.includes(field?.is_required)
                                    ? true
                                    : false
                                }
                                error={
                                  errors?.services?.[itm?.id]
                                    ?.custom_field_errors?.[field?.id]
                                }
                              />
                            );
                          }

                          if (field?.type == "STRING") {
                            return (
                              <Input
                                label={field?.name}
                                name="custom_field_value"
                                handleChange={(value, name) => {
                                  handleServiceCfChange(
                                    "value",
                                    value,
                                    field?.id,
                                    itm?.id
                                  );
                                }}
                                value={selectedField?.value}
                                required={
                                  CS_BOOLEAN_TRUE.includes(field?.is_required)
                                    ? true
                                    : false
                                }
                                error={
                                  errors?.services?.[itm?.id]
                                    ?.custom_field_errors?.[field?.id]
                                }
                              />
                            );
                          }

                          return <></>;
                        })}
                      {
                        // (()=>{
                        // if(itm?.custom_fields && isArray(itm?.custom_fields)){
                        // return <></>
                        //   for (let index = 0; index < itm?.custom_fields.length; index++) {
                        //     return <div></div>
                        //   }
                        // }
                        //})()
                      }
                    </FieldGroup>
                  </div>
                )}
              </div>
            );
          })}
        <br />

        <ButtonsGroup>
          <Button
            title="Assign"
            onClick={handleSubmit}
            loading={buttonLoading}
            theme="success"
          />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default AddClientServiceMulti;
