
import { Button, Card, CardHeader, confirmPopUp, errorPopUp, gLoader, Input, List, ListBody, ListHeadType, successPopUp, Td } from "asbawa-react-ui-ts-test-b";
import { Badge, TextLink } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconCopy, IconDocuments } from "../../Components/Icons";
import paymentService from "../../../services/axios/payments";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import { CS_BOOLEAN_TRUE, CS_PAYMENT_STATUS } from "../../../utils/constants";
import AddInvoice from "../ClientInvoices/AddInvoice";
import AskClientPayment from "./AskClientPayment";
import { toYesNo } from "../../../utils/commonFunctions";
const PaymentRequests = (): JSX.Element | null => {
  const { client_id } = useParams<{ client_id: string }>();
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  // const [balances, setBalances] = useState<any>({});
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showAskPayment, setShowAskPayment] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<{ [key: number]: string | null }>({});
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
  }, [client_id, pageNo, pageSize,search])

  const handleCopyUrl = (url: string, id: number) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        setCopySuccess((prev) => ({ ...prev, [id]: "Copied!" }));
        setTimeout(() => {
          setCopySuccess((prev) => ({ ...prev, [id]: null }));
        }, 800);
      })
      .catch(() => {
        setCopySuccess((prev) => ({ ...prev, [id]: "Copy Failed!" }));
      });
  };

  const fetchData = () => {
    gLoader(true);
    paymentService?.listPaymentRequests({ client_id, pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
        // setBalances(res?.data?.response?.detail?.balances);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    paymentService?.deleteInvoice(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  const handleAddNewSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const onPaymentSuccess = () => {
    setShowAskPayment(false);
    fetchData();
  };


  return <>
    <Card>
      <CardHeader
        title="Payments"
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Ask for Payment"
              theme="primary"
              onClick={() => setShowAskPayment(true)}
            />
            {/* <Button
              size="small"
              title="Payment Requests"
              theme="primary"
              onClick={() => navigate(`../payment-requests`)}
            /> */}
            <Button
              size="small"
              title="Invoices"
              theme="primary"
              onClick={() => navigate(`../invoices`)}
            />
            <Button
              size="small"
              title="Create Invoice"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
          </>
        }
      />
      {/* <ListSearch /> */}
      {showAddNew && (
        <AddInvoice
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleAddNewSuccess}
          data={{ client_id: client_id }}
        />
      )}
      {showAskPayment && (
        <AskClientPayment
          show={showAskPayment}
          onCancel={() => setShowAskPayment(false)}
          onSuccess={onPaymentSuccess}
          data={{ client_id }}
        />
      )}
      {/* <br />
      <span style={{ margin: "15px", color: "#118eed" }}><b>Total Amount:</b>{balances?.total_amount}</span><span style={{ margin: "15px", color: "#00762e" }}><b>Total Paid:</b> {balances?.total_amount_paid}</span><span style={{ margin: "15px", color: "red" }}><b>Balance:</b> {balances?.total_balance}</span> */}
      <br />
      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            const payStatus: any = CS_PAYMENT_STATUS.find((field: any) => field.value == itm.payment_status);
            const payStatusBgColor = payStatus?.bgColor;
            const payStatusColor = payStatus?.color;
            const payStatusName = payStatus?.name;
            const url = itm.url ? itm.url : '';
            return (
              <tr key={index}>
                <Td>2024-09-11 19:18:08</Td>
                <Td>{itm.amount}</Td>
                
                <Td><Badge color={payStatusColor} bgColor={payStatusBgColor} text={payStatusName} /></Td>
                <Td>{utcToLocalDatetime(itm?.amount_paid_on)}</Td>
                <Td>
                  {itm.url && (copySuccess[itm.id] ? <small style={{ marginLeft: '3px', color: 'green' }}>{copySuccess[itm.id]}</small> : <IconCopy size='1.5em' onClick={() => handleCopyUrl(url, itm.id)}/>)}
                </Td>
                
                {/* <Td ><Badge colorTheme='B' text={itm?.status} /></Td> */}
                <Td>{toYesNo(CS_BOOLEAN_TRUE.includes(itm?.notify_on_email))}</Td>
                {/* <td></Td> */}
              </tr>
            );
          })}
        </ListBody>
      </List>
      <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
    </Card>
  </>
};

export default PaymentRequests;
const listHeaders: ListHeadType[] = [
  {
    title: "Request Date",
    width: "15%",
    align: "center",
  },
  {
    title: "Amount",
    width: "15%",
    align: "center",
  },
  {
    title: "Payment Status",
    width: "10%",
    align: "center",
  },
  {
    title: "Payment Date",
    width: "20%",
    align: "center",
  },

  {
    title: "Copy Link",
    width: "25%",
    align: "center",
  },
  {
    title: "Notified On Email",
    width: "10%",
    align: "center",
  }
];