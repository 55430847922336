import {
  Button,
  ButtonsGroup,
  Card,
  CardHeader,
  CheckboxInline,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Label,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import documentService from "../../../services/axios/documents";
import clientService from "../../../services/axios/clients";
import { ModalFormProps } from "../../../types";
import TableForm from "../../Components/TableForm";

const ClientDocumentsRequest: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}) => {
  const [payload, setPayload] = useState<any>({
    client_id: data?.client_id,
    documents: [{}],
    folder_id: "",
  });
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [folderList, setFoldersList] = useState<any>([]);
  const [documentTypes, setDocumentTypes] = useState<any>([]);

  useEffect(() => {
    fetchFolders();
    fetchDocumentTypes();
  }, []);

  const fetchFolders = () => {
    gLoader(true);
    documentService?.quickListFolder({ client_id: data?.client_id })
      .then((res: any) => {
        gLoader(false);
        const dropdownOptionsFolders = res?.data?.response?.list?.map((item: any) => ({
          name: item?.name,
          value: item?.id,
        }));
        setFoldersList(dropdownOptionsFolders);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const fetchDocumentTypes = () => {
    gLoader(true);
    documentService?.quickListDocumentType()
      .then((res: any) => {
        gLoader(false);
        const dropdownOptionsDocumentTypes = res?.data?.response?.list?.map((item: any) => ({
          name: item?.name,
          value: item?.id,
          is_required: false
        }));
        setDocumentTypes(dropdownOptionsDocumentTypes);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    clientService?.requestDocuments(payload)
      .then((res) => {
        // gLoader(false);
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
        //navigate('/')
      })
      .catch((err) => {
        // gLoader(false);
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  const handleArrayChange = (value: any, name: string, index: any) => {
    const payloadArr = [...payload.documents];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    if (name == 'document_id') {
      const fieldObj = documentTypes.find((field: any) => field.value == value);
      payloadArr[index] = {
        ...payloadArr[index],
        ['document_name']: fieldObj?.name,
        ['is_required']: false,
      };
    }
    handleChange(payloadArr, 'documents');
  };

  const addMore = () => {
    const payloadArr = [...payload?.documents]
    payloadArr.push({})
    handleChange(payloadArr, 'documents');
  }

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ width: "700px" }}>
        <CardHeader title="Request Documents" />
        <FieldGroup column={1}>
          <Dropdown
            label="Folder Name"
            name="folder_id"
            value={payload?.folder_id}
            handleChange={handleChange}
            options={folderList}
          />
        </FieldGroup>
        <br />
        <CheckboxInline
          label="Notify on Email"
          name="notify_on_email"
          handleChange={handleChange}
          checked={payload?.notify_on_email}
        />

        <br />
        <TableForm 
        onAddRow={addMore}
        headers={[
          { title: 'Document Id', width: '40%' },
          { title: 'Document Name', width: '40%' },
          { title: 'Is Required', width: '20%' },
        ]}>
          {payload?.documents?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <Dropdown
                  name="document_id"
                  value={payload?.documents?.[index]?.document_id}
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  options={documentTypes}
                />
              </td>
              <td>
                <Input
                  name="document_name"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.documents?.[index]?.document_name}
                />
              </td>
              <td>
                <InputCheckbox
                  name="is_required"
                  handleChange={(value, name) => {
                    handleArrayChange(value, name, index);
                  }}
                  checked={Boolean(payload?.documents?.[index]?.is_required)}
                />
              </td>
            </tr>
          ))}
        </TableForm>

        <br />
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default ClientDocumentsRequest;
