import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useListFilters({ listName }: { listName?: string | null }) {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterList,setFilterList] = useState<Record<string, any>>({})
  

  useEffect(()=>{
    setFilterList(Object.fromEntries(searchParams))
  },[searchParams])
  

  const setFilter=(key:string,value:string | null | number | undefined)=>{
      const newUrl = new URL(window.location.href);
      if(typeof value == 'string' || typeof value == 'number'){
        newUrl.searchParams.set(key, value as string);
      } else {
        newUrl.searchParams.delete(key);
      }
      setSearchParams(newUrl.search);
  }

  interface newFilter {
    key:string,
    value:string | null | number | undefined
  }

  const setFilters=(newFilters:newFilter[])=>{

    const newUrl = new URL(window.location.href);
    newFilters.forEach(element => {
      if(typeof element.value == 'string' || typeof element.value == 'number'){
        newUrl.searchParams.set(element.key, element.value as string);
      } else {
        newUrl.searchParams.delete(element.key);
      }
    });
    
    setSearchParams(newUrl.search);
}




  return {
    filters:filterList,
    setFilter,
    setFilters
  };
}
