import { NavLink } from "react-router-dom";
import "./sidebar.css";
import {
  IconClients,
  IconDashboard,
  IconMeeting,
  IconSettings,
  IconTasks,
  IconTodo,
  IconUsers,
  IconView,
} from "../../Components/Icons";
import COLOR from "../../../constants/palette";
const iconProps = {
  size: 25,
  color: COLOR.grey,
};
const links = [
  {
    to: "",
    icon: <IconDashboard {...iconProps} />,
    title: "Dashboard"
  },
  {
    to: "clients",
    icon: <IconClients {...iconProps} />,
    title: "Clients"
  },
  {
    to: "businesses",
    icon: <IconClients {...iconProps} />,
    title: "Businesses"
  },
  {
    to: "tasks",
    icon: <IconTasks {...iconProps} />,
    title: "Tasks"
  },
  {
    to: "reminders",
    icon: <IconTodo {...iconProps} />,
    title: "Reminders"
  },
  {
    to: "meetings",
    icon: <IconMeeting {...iconProps} />,
    title: "Appointments"
  },
  {
    to: "bulk-assignments",
    icon: <IconUsers {...iconProps} />,
    title: "Bulk Assignments"
  },
  {
    to: "users",
    icon: <IconUsers {...iconProps} />,
    title: "Users"
  },
  {
    to: "settings",
    icon: <IconSettings {...iconProps} />,
    title: "Settings"
  },
];

const linksBottom = [
  {
    to: "settings",
    icon: <IconSettings {...iconProps} />,
  },
];
const SideBar = () => {
  return (
    <div className="ly-sidebar-wrapper">
      <div className="ly-sidebar-top">
        {links.map((link) => {
          return (
            <NavLink className={({ isActive, isPending, isTransitioning }) =>
              [
                "ly-sidebar-link",
                isPending ? "pending" : "",
                isActive ? "active" : "",
                isTransitioning ? "transitioning" : "",
              ].join(" ")
            } key={link.to} to={link.to}>
              <div>
                {link.icon}
                <p>{link.title}</p>
              </div>
            </NavLink>
          );
        })}
      </div>
      {/* <div className="ly-sidebar-top">
        {linksBottom.map((link) => {
          return (
            <NavLink className={({ isActive, isPending, isTransitioning }) =>
              [
                "ly-sidebar-link",
                isPending ? "pending" : "",
                isActive ? "active" : "",
                isTransitioning ? "transitioning" : "",
              ].join(" ")
            } key={link.to} to={link.to}>
              {link.icon}
            </NavLink>
          );
        })}
      </div> */}
    </div>
  );
};

export default SideBar;
