import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Card, CardHeader } from 'asbawa-react-ui-ts-test-b';
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const data = {
  labels: ['Pending', 'In Progress', 'Upcoming'],
  datasets: [
    {
      label: 'My Doughnut Chart',
      data: [12, 19, 3],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)'
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem:any) => {
          return `${tooltipItem.label}: ${tooltipItem.raw} units`;
        },
      },
    },
  },
};

const TaskStatusPieChart: React.FC = () => {
  return (
    <Card>
        <CardHeader title='Task Status'/>
      <div style={{width:'80%',margin:'auto'}}>
      <Doughnut data={data} options={options} />
      </div>
    </Card>
  );
};

export default TaskStatusPieChart;
