import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "../../..";

class googleDriveService {
    //Google Credentials
    addGoogleDriveConfigs = async (payload = {}) => {
        return await postFormdata(`company/plugins/google-drive/config`, payload);
    }
    detailGoogleDriveConfigs = async (payload = {}) => {
        return await getRequest(`company/plugins/google-drive/config`, payload);
    }
    addGoogleDriveFolderId = async (payload = {}) => {
        return await postRequest(`company/plugins/google-drive/folder`, payload);
    }
    getGoogleDriveFolderId = async (payload = {}) => {
        return await getRequest(`company/plugins/google-drive/folder`, payload);
    }
    // getGoogleDriveTokensUrl = async (urlParam = {}) => {
    //     return await getRequest(`company/plugins/google-drive/tokens-url`, urlParam);
    // }
    // getGoogleDriveCallback = async (urlParam = {}) => {
    //     return await getRequest(`company/plugins/google-drive/callback`, urlParam);
    // }
    // addGoogleDriveRefreshToken = async (payload = {}) => {
    //     return await postRequest(`company/plugins/google-drive/refresh-token`, payload);
    // }


}

const instance = new googleDriveService();

export default instance;

