import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class businessService {
    list = async (id: any, urlParam = {}) => {
        return await getRequest(`company/business`, urlParam);
    }
    quicklist = async (id: any, urlParam = {}) => {
        return await getRequest(`company/business/quick-list`, urlParam);
    }

    details = async (id: any, urlParam = {}) => {
        return await getRequest(`company/business/${id}`, urlParam);
    }
    add = async (payload = {}) => {
        return await postRequest(`company/business`, payload);
    }
    update = async (id: any, payload = {}) => {
        return await putRequest(`company/business/${id}`, payload);
    }
    delete = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/business/${id}`, urlParam);
    }

}

const instance = new businessService();

export default instance;

