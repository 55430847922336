import "./style.css";
import MainContent from "../../Components/MainContent";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, TextLink } from "../../Components";
import clientService from "../../../services/axios/clients";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconDocuments, IconServices } from "../../Components/Icons";
import AddClient from "../../Clients/AddClient";
import { fakeTaskList } from "../../../fakeData";
import taskService from "../../../services/axios/tasks";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
const ClientTasks = () => {
  const { client_id } = useParams<{ client_id: string }>();
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()


  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize, filters, search])

  const fetchData = () => {
    gLoader(true);
    taskService?.listTask({ pageNo, pageSize, search: search, client_id })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }


  const handleDelete = (id: any) => {
    gLoader(true);
    taskService?.deleteTask(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };


  return (
    <MainContent
    >
      <CardHeader
        title="Client Tasks"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>

          </>
        }
      />

      <Card>

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              // const taskStatus: any = CS_TASK_STATUS.find((field: any) => field.value == itm.task_status);
              // const taskStatusBgColor = taskStatus?.bgColor;
              // const taskStatusColor = taskStatus?.color;
              // const taskStatusName = taskStatus?.name;
              return (

                <tr key={index}>
                  <Td align="left"><TextLink text={itm?.title} title="View" goto={`/tasks/${itm.id}`} /></Td>
                  <Td>{itm?.service_type_title} <br /> {itm?.object}</Td>
                  <Td>{utcToLocalDatetime(itm?.trigger_on)}</Td>
                  <Td>
                    <Badge style={{margin:'auto',maxWidth:'150px'}} color={itm?.task_status_color} bgColor={itm?.task_status_bg_color} text={itm?.task_status_title} /></Td>
                  <Td><ListRowActions
                    actions={[
                      { icon: 'view', onClick: () => navigate(`/tasks/${itm.id}`) },
                      { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                    ]}
                  />
                  </Td>
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
    </MainContent>
  );
};

export default ClientTasks;

const listHeaders: ListHeadType[] = [
  {
    title: "Title",
    width: "25%",
    sortName: "uid",
    align: "left",
  },
  {
    title: "Linked To",
    width: "10%",
    align: "center",
  },
  // {
  //   title: "Interval",
  //   width: "15%",
  //   align: "center",
  // },
  {
    title: "Triggered On",
    width: "20%",
    align: "center",
  },
  {
    title: "Task Status",
    width: "20%",
    align: "center",
  },
  {
    title: "",
    width: "20%",
    align: "right",
  }
];

