import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import EditServicesTypes from "./EditDocumentsTypes";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import EditDocumentsTypes from "./EditDocumentsTypes";
import documentService from "../../../services/axios/documents";

const DetailDocumentsTypes = () => {
  const { document_type_id } = useParams<{ document_type_id: string }>();
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [documentTypeDetail, setDocumentTypeDetail] = useState<any>([]);

  const handleSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchData()
  }, [document_type_id])

  const fetchData = () => {
    gLoader(true);
    documentService?.detailDocumentType(document_type_id)
      .then((res: any) => {
        gLoader(false);
        setDocumentTypeDetail(res?.data?.response?.detail)
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })

  }
  return <Card>
    <CardHeader title='Document Type Detail' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        /></>
    } />

    {showEdit && (
      <EditDocumentsTypes
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={handleSubmit}
        data={{ document_type_id }}
      />
    )}

    <SubContent heading="" style={{ display: "flex" }}>
      <LabelDescriptions
        style={{ width: "50%" }}
        list={[
          ["Id", documentTypeDetail?.id],
          ["Document Name", documentTypeDetail?.name],
          ["Status", <Badge colorTheme='B' text={documentTypeDetail?.status} />],
        ]}
      />
    </SubContent>
  </Card>
}

export default DetailDocumentsTypes;
