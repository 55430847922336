import { useEffect } from 'react';
import { io, Socket as SocketIOClient } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { SocketListeners } from './SocketListeners';


let socket: SocketIOClient | null = null;
const Socket = () => {
    useEffect(() => {
        let access_token = localStorage.getItem('token');
        socket = io(process.env.REACT_APP_API_SOCKET_URL!, {
            auth: {
                token: access_token
            }
        });

        SocketListeners(socket);

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    return null;
};

// const Socket = () => {
//     let access_token = localStorage.getItem('token');
//     socket = io('http://localhost:3021', {
//         auth: {
//             token: access_token
//         }
//     });
//     const socketUserId: any = null;
//     // const user = useSelector((store) => store?.user);
//     // const socketUserId = parseInt(user?.user?.id) || null;
//     useEffect(() => {
//         SocketListeners(socket);
//     }, []);

//     return null;
// };

// console.log("soooooooocccccket fileeeeeee ccaaaallleddddddddddd");
// console.log("ssssssssssssssssockeweeetttt222222222222", socket.id);

export { socket };
export default Socket;
