import { Button, ButtonsGroup, Card, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSignup, sendVerifyEmailOtp, verifyEmail } from "../../store/authSlice";
import { TextLink } from "../Components";
import AuthLayout from "./AuthLayout";

const VerifyEmail = () => {
    // const { email } = useParams<{ email: string }>();
    const { search } = useLocation();
    const email = new URLSearchParams(search).get('email');
    const [payload, setPayload] = useState<any>({ email: email });
    const [errors, setErrors] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleChange = (value: any, name: any, item: any) => {
        setPayload({
            ...payload,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: null
        })
    };

    const handleSubmit = () => {
        dispatch(verifyEmail(payload))
            .then((res: any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
                navigate('/settings/profile');
            })
            .catch((err: any) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    };

    const handleSendVerifyEmailOtp = () => {
        //  gLoader(true);
        dispatch(sendVerifyEmailOtp({ email }))
            .then((res: any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
            })
            .catch((err: any) => {
                setButtonLoading(false);
                errorPopUp(`${err?.message}: ${err?.field_errors?.email}`);
            })
    };

    return <AuthLayout
            heading="Verify Email"
            description={<p>An OTP has been sent on <b>{payload?.email}</b>. Please use the OTP to verify your email.</p>}
        >
        <Input
            label="OTP"
            name="otp"
            handleChange={handleChange}
            value={payload?.otp}
            error={errors?.otp}
        />
        <div style={{ margin: "10px", display:'flex',justifyContent:'space-between' }}>
            <TextLink theme="light-gray" text="Resesd" title="Resend"  onClick={handleSendVerifyEmailOtp} />
            <TextLink theme="light-gray" text="Back To Login" title="Login" goto="/auth" />
        </div>
        <ButtonsGroup>
            <Button title="Verify Email" onClick={handleSubmit} loading={buttonLoading} theme="success" />
        </ButtonsGroup>
    </AuthLayout>
}

export default VerifyEmail;