import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  InputTime,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../services/axios/settings/companyServices";
import TableForm from "../Components/TableForm";
import { ModalFormProps } from "../../types";
import documentService from "../../services/axios/documents";
import taskService from "../../services/axios/tasks";
import userService from "../../services/axios/users";
import { CS_MEETING_CLIENT_TYPE, CS_STATUS } from "../../utils/constants";
import meetingService from "../../services/axios/meetings";
import { utcToLocalDatetime } from "../../utils/dateFunctions";
import { InputDatetime } from "../Components/InputDatetime";
import clientService from "../../services/axios/clients";

const EditMeeting: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [companyUsers, setCompanyUsers] = useState<any>([]);
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [clientsList, setClientsList] = useState<any>([]);

  useEffect(() => {
    fetchCompanyUsers();
    fetchClientQuickList();
  }, []);

  const fetchClientQuickList = () => {
    gLoader(true);
    clientService?.quickListClient()
      .then((res: any) => {
        gLoader(false);
        const clientDropdownOptions = res?.data?.response?.list?.map((item: any) => ({
          name: item?.firstname + " " + item.lastname,
          value: item?.id
        }));
        setClientsList(clientDropdownOptions);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const fetchCompanyUsers = () => {
    gLoader(true);
    userService?.quickListCompanyUser({})
      .then((res: any) => {
        gLoader(false);
        setCompanyUsers(res?.data?.response?.list);
        const dropdownOptions = res?.data?.response?.list?.map((item: any) => ({
          name: item?.user_name,
          value: item?.id,
        }));
        setCompanyUsers(dropdownOptions);
        fetchData();
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const fetchData = () => {
    gLoader(true);
    meetingService?.detailMeeting(data?.meeting_id)
      .then((res: any) => {
        gLoader(false);
        let newDetail = res?.data?.response?.detail;
        newDetail = { ...newDetail, from_datetime: utcToLocalDatetime(newDetail.from_datetime), to_datetime: utcToLocalDatetime(newDetail.to_datetime) }
        setPayload({ ...payload, ...newDetail });
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }
  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    meetingService?.updateMeeting(data?.meeting_id, payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "500px" }}>
        <CardHeader title="Edit Meeting" />
        <FieldGroup column={1}>
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <Dropdown
            label="Representative"
            name="representative_id"
            value={payload?.representative_id}
            handleChange={handleChange}
            options={companyUsers}
            error={errors?.representative_id}
          />
          <Dropdown
            label="Client Type"
            name="client_type"
            value={payload?.client_type}
            handleChange={handleChange}
            options={CS_MEETING_CLIENT_TYPE}
            error={errors?.client_type}
          />
          {
            payload?.client_type == "EXISTING" ?
              <Dropdown
                label="Client"
                name="client"
                value={payload?.client}
                handleChange={handleChange}
                options={clientsList}
                error={errors?.client}
              /> :
              payload?.client_type == "NON_EXISTING" ?
                <Input
                  label="Client"
                  name="client"
                  handleChange={handleChange}
                  value={payload?.client}
                  error={errors?.client}
                /> :
                null
          }
          {/* <InputDate
            label="Date"
            name="date"
            handleChange={handleChange}
            value={payload?.date}
            error={errors?.date}
          />
          <InputTime
            label="From Time"
            name="from_time"
            handleChange={handleChange}
            value={payload?.from_time}
            error={errors?.from_time}
          />
          <InputTime
            label="To Time"
            name="to_time"
            handleChange={handleChange}
            value={payload?.to_time}
            error={errors?.to_time}
          /> */}
          {/* <InputDate
            label="Date"
            name="date"
            handleChange={handleChange}
            value={payload?.date}
            error={errors?.date}
          /> */}
          {/* <InputTime
            label="From Time"
            name="from_time"
            handleChange={handleChange}
            value={payload?.from_time}
            error={errors?.from_time}
          />
          <InputTime
            label="To Time"
            name="to_time"
            handleChange={handleChange}
            value={payload?.to_time}
            error={errors?.to_time}
          /> */}
          <InputDatetime
            label="From Datetime"
            name="from_datetime"
            onChange={handleChange}
            value={payload?.from_datetime}
            error={errors?.from_datetime}
          />
          <InputDatetime
            label="To Datetime"
            name="to_datetime"
            onChange={handleChange}
            value={payload?.to_datetime}
            error={errors?.to_datetime}
          />
          {/* <Input
            label="From Datetime"
            name="from_datetime"
            handleChange={handleChange}
            value={payload?.from_datetime}
            error={errors?.from_datetime}
          />
          <Input
            label="To Datetime"
            name="to_datetime"
            handleChange={handleChange}
            value={payload?.to_datetime}
            error={errors?.to_datetime}
          /> */}
          <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
        </FieldGroup>

        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default EditMeeting;
