import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import EditClient from "../EditClient";
import { useEffect, useState } from "react";
import authService from "../../../services/axios/auth";
import clientService from "../../../services/axios/clients";
import { useParams } from "react-router-dom";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import { CS_BOOLEAN_TRUE } from "../../../utils/constants";
const ClientProfile = (): JSX.Element | null => {
  const { client_id } = useParams<{ client_id: string }>();
  const [profileData, setProfileData] = useState<any>({});
  const [showEdit, setshowEdit] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [client_id]);

  const fetchData = () => {
    gLoader(true);
    clientService
      ?.detailClient(client_id)
      .then((res: any) => {
        gLoader(false);
        setProfileData(res?.data?.response?.detail);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const onSuccess = () => {
    setshowEdit(false);
    fetchData();
  };


  return (
    <>
      <Card>
        <CardHeader
          title="Profile"
          rightButtons={
            <>
              <Button
                size="small"
                title="Edit"
                onClick={() => setshowEdit(true)}
              />
            </>
          }
        />

        {showEdit && (
          <EditClient
            show={showEdit}
            onCancel={() => setshowEdit(false)}
            onSuccess={onSuccess}
            data={{ client_id: client_id }}
          />
        )}

        <SubContent heading="General Details" style={{ display: "flex" }}>
          <LabelDescriptions
            column={2}
            list={[
              ["CUID", profileData?.cuid],
              ["Address Line", profileData?.address_line],
              ["Name", profileData?.firstname + ", " + profileData?.lastname],
              ["City", profileData?.city],
              ["Email", profileData?.email],
              ["Province", profileData?.province],
              ["Phone", profileData?.phone],
              ["Whatsapp No.", profileData?.whatsapp_number],
              ["Coutnry", profileData?.country],
              ["Status", <Badge colorTheme='B' text={profileData?.status} />],
              ["Postal Code", profileData?.postalcode],
              ["Internal Notes", profileData?.internal_notes],
            ]}
          />
        </SubContent>

        <SubContent heading="Other Details">
          <LabelDescriptions
            column={2}
            list={profileData?.custom_fields?.map((item: any, index: number) => {
              let custom_field_value = item?.custom_field_value;
              if (item?.type == 'BOOLEAN' && CS_BOOLEAN_TRUE.includes(custom_field_value)) {
                custom_field_value = 'true';
              } else if (item?.type == 'BOOLEAN') {
                custom_field_value = 'false';
              }
              return [item?.name, custom_field_value]
            })}
          />
        </SubContent>
      </Card>
    </>
  );
};

export default ClientProfile;
