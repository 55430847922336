import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class meetingService {
    //Meeting routes
    listMeeting = async (urlParam = {}) => {
        return await getRequest(`company/meeting`, urlParam);
    }
    detailMeeting = async (id: any, urlParam = {}) => {
        return await getRequest(`company/meeting/${id}`, urlParam);
    }
    addMeeting = async (payload = {}) => {
        return await postRequest(`company/meeting`, payload);
    }
    updateMeeting = async (id: any, payload = {}) => {
        return await putRequest(`company/meeting/${id}`, payload);
    }
    deleteMeeting = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/meeting/${id}`, urlParam);
    }

}

const instance = new meetingService();

export default instance;

