import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "..";

class clientService {
    quickListClient = async (urlParam = {}) => {
        return await getRequest(`company/client/quick/list`, urlParam);
    }
    listClient = async (urlParam = {}) => {
        return await getRequest(`company/client`, urlParam);
    }
    detailClient = async (id: any, urlParam = {}) => {
        return await getRequest(`company/client/${id}`, urlParam);
    }
    addClient = async (payload = {}) => {
        return await postRequest(`company/client`, payload);
    }
    updateClient = async (id: any, payload = {}) => {
        return await putRequest(`company/client/${id}`, payload);
    }
    deleteClient = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/client/${id}`, urlParam);
    }
    requestDocuments = async (payload = {}) => {
        return await postRequest(`company/client/document/request`, payload);
    }
    uploadClientData = async (urlParam = {}) => {
        return await postFormdata('company/client/import-file', urlParam);
    }


    //client document upload
    uploadDocumentsClient = async (payload = {}) => {
        return await postFormdata('client/document/upload', payload);
    }
    listDocumentsToUpload = async (token: any, urlParam = {}) => {
        return await getRequest(`client/document/upload/${token}`, urlParam);
    }


    //client profile custom field crud
    listClientProfileCustomField = async (urlParam = {}) => {
        return await getRequest(`company/client/custom-fields`, urlParam);
    }
    quickListClientProfileCustomField = async (urlParam = {}) => {
        return await getRequest(`company/client/custom-fields/quick/list`, urlParam);
    }
    detailClientProfileCustomField = async (id: any, urlParam = {}) => {
        return await getRequest(`company/client/custom-fields/${id}`, urlParam);
    }
    addClientProfileCustomField = async (payload = {}) => {
        return await postRequest(`company/client/custom-fields`, payload);
    }
    updateClientProfileCustomField = async (id: any, payload = {}) => {
        return await putRequest(`company/client/custom-fields/${id}`, payload);
    }
    deleteClientProfileCustomField = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/client/custom-fields/${id}`, urlParam);
    }

}

const instance = new clientService();

export default instance;

