import { Button } from "asbawa-react-ui-ts-test-b"
import TasksQuickList from "./TasksQuickList"
import TaskStatusPieChart from "./TaskStatusPieChart"
import AppointmentsQuickList from "./AppointmentsQuickList"


const Dashboard = () =>{

return <>

    <div style={{display:'flex'}}>
    <div style={{flexGrow:'1'}}>
        <AppointmentsQuickList/>
    </div>
    <div style={{maxWidth:'500px',flexGrow:'1',}}>
    <TaskStatusPieChart/>
    </div>
    </div>
    
</>
}

export default Dashboard