import { deleteRequest, getRequest, postFormdata, postRequest, putRequest } from "../..";

class companyServices {
    //service master
    listCompanyServiceType = async (urlParam = {}) => {
        return await getRequest(`company/service/type`, urlParam);
    }
    quickListCompanyServiceType = async (urlParam = {}) => {
        return await getRequest(`company/service/type/quick/list`, urlParam);
    }
    detailCompanyServiceType = async (id: any, urlParam = {}) => {
        return await getRequest(`company/service/type/${id}`, urlParam);
    }
    addCompanyServiceType = async (payload = {}) => {
        return await postRequest(`company/service/type`, payload);
    }
    updateCompanyServiceType = async (id: any, payload = {}) => {
        return await putRequest(`company/service/type/${id}`, payload);
    }
    deleteCompanyServiceType = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/service/type/${id}`, urlParam);
    }


    //custom field service where object type is SERVICE_TYPE
    quickListServicesCustomFieldsClientsAssign = async (service_type_id: any, urlParam = {}) => {
        return await getRequest(`company/service/custom-fields/quick/list/${service_type_id}`, urlParam);
    }


    //Default Admin Service
    quickListDefaultServiceTemplateAdmin = async (urlParam = {}) => {
        return await getRequest(`admin/service/template/quick/list`, urlParam);
    }


    //service applied to client
    listCompanyServiceToClient = async (client_id: any, urlParam = {}) => {
        return await getRequest(`company/service/client`, urlParam);
    }
    list = async (urlParam = {}) => {
        return await getRequest(`company/service`, urlParam);
    }
    detailCompanyServiceToClient = async (id: any, urlParam = {}) => {
        return await getRequest(`company/service/client/${id}`, urlParam);
    }
    addCompanyServiceToClient = async (payload = {}) => {
        return await postRequest(`company/service/client`, payload);
    }
    assignServices = async (payload = {}) => {
        return await postRequest(`company/service/assign-services`, payload);
    }
    updateCompanyServiceToClient = async (id: any, payload = {}) => {
        return await putRequest(`company/service/client/${id}`, payload);
    }
    deleteCompanyServiceToClient = async (id: any, urlParam = {}) => {
        return await deleteRequest(`company/service/client/${id}`, urlParam);
    }

    quickListCompanyServiceToClient = async (urlParam = {}) => {
        return await getRequest(`company/service/client/quick/list`, urlParam);
    }

    quickListOfClientsWithServices = async (urlParam = {}) => {
        return await getRequest(`company/service/clients-list`, urlParam);
    }

}

const instance = new companyServices();

export default instance;

