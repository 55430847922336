import { Button, ButtonsGroup, Card, CardHeader, FieldGroup, Input, errorPopUp, gLoader, successPopUp, Label, InputFile } from "asbawa-react-ui-ts-test-b"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import documentService from "../../../services/axios/documents";
import clientService from "../../../services/axios/clients";
import TableForm from "../../Components/TableForm";
import { toNull } from "../../../utils/commonFunctions";
import defaultLogo from "../../../assets/images/default-logo.png"
import { IconYes } from "../../Components/Icons";

const ClientUploadDocuments = () => {
  const { token } = useParams<{ token: string }>();
  const [documentList, setDocumentList] = useState<any>([]);
  const [payload, setPayload] = useState<any>({ token: token, documents: [] });
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [companyData, setCompanyData] = useState<any>({});
  const [showUploadFields, setShowUploadFields] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<any>();
  const [uploadStatus,setUploadStatus] = useState<string>('PENDING')

  useEffect(() => {
    fetchData()
  }, [token])

  const fetchData = () => {
    gLoader(true);
    let documents: any = [];
    clientService?.listDocumentsToUpload(token)
      .then((res: any) => {
        gLoader(false);
        documents = res?.data?.response?.list;
        setCompanyData(res?.data?.response?.company_data);
        setDocumentList(documents);
        setPayload({
          ...payload,
          ['documents']: documents,
        });
        setShowUploadFields(true);
        setDisplayError('');
      })
      .catch((err: any) => {
        setShowUploadFields(false);
        setDisplayError(err?.message);
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const handleFileChange = (file: File | null, index: number) => {
    const updatedDocuments = [...payload.documents];
    updatedDocuments[index] = {
      ...updatedDocuments[index],
      file: file,
    };
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      documents: updatedDocuments,
    }));
  };


  const handleSubmit = () => {
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("token", payload.token);
    payload?.documents?.forEach((doc: any, index: number) => {
      if (toNull(doc.document_id) != null && doc.file && Number.isInteger(Number(doc.document_id))) {
        let parsedId: any = Number(doc.document_id);
        if (toNull(parsedId) != null) {
          formData.append(`documents[${index}][id]`, parsedId);
          formData.append(`documents[${index}][file]`, doc.file);
        }
      }
    });
    clientService?.uploadDocumentsClient(formData)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        setUploadStatus('UPLOADED')
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      });
  };

  if(uploadStatus == 'UPLOADED'){
  return <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:700,flexDirection:'column'}}>
      <IconYes  size={'3em'}/>
    <h2>Documents Uploaded Successfully.</h2>
  </div>
  }

  return showUploadFields ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:'center' }}>
    <br />
    <div style={{display:"flex",justifyContent:'center',alignItems:'center',flexDirection:'column',gap:12}}>
      <div style={{background:'orange',width:150,height:150,borderRadius:'50%',overflow:'hidden'}}>
      {companyData.company_image ? (
        <img src={`data:image/jpeg;base64,${companyData?.company_image}`} alt="Company Logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      ) : (
        <img src={defaultLogo} alt="Company Logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        // <div>Company Logo</div>
      )}
      </div>
      <h2>{companyData.company_name}</h2>
      <p>{companyData?.company_msg}</p>
    </div>

    {/* <CardHeader title="Upload Documents" /> */}
    <br />
    <Card style={{minWidth:800}}>
      <TableForm headers={[
        { title: 'Document Name', width: '30%' },
        { title: 'Upload Document', width: '70%' },
      ]}>
        {documentList?.map((item: any, index: number) => (
          <tr key={index}>
            <td>
              {item.document_name}
            </td>
            <td>
              <InputFile
                required={item?.is_required}
                handleChange={(file) => handleFileChange(file, index)}
              // error={errors?.document_id}
              />
            </td>
          </tr>
        ))}
      </TableForm>
      <br />
      <ButtonsGroup>
        <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
      </ButtonsGroup>
    </Card> </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <h1 style={{ textAlign: 'center' }}>{displayError}</h1>
  </div>
}

export default ClientUploadDocuments