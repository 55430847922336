import { Button, Card, CardHeader, List, ListBody, ListHeadType, Td, confirmPopUp, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import EditClientService from "./EditClientService";
import { Badge, LabelDescriptions, SubContent, TextLink } from "../../Components";
import { CS_BOOLEAN_TRUE, CS_TASK_MONTHS, CS_TASK_MONTH_DAYS, CS_TASK_QUARTERLY } from "../../../utils/constants";
import TaskScheduleAdd from "../../TasksManagement/TaskSchedules/TaskScheduleAdd";
import TaskScheduleRecurrenceAdd from "../../TasksManagement/TaskSchedulesRecurrences/TaskScheduleRecurrenceAdd";
import TaskScheduleEdit from "../../TasksManagement/TaskSchedules/TaskScheduleEdit";
import TaskScheduleRecurrenceEdit from "../../TasksManagement/TaskSchedulesRecurrences/TaskScheduleRecurrenceEdit";
import ListRowActions from "../../Components/List";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import taskService from "../../../services/axios/tasks";
import CommunicationRecurrenceAdd from "../../TasksManagement/CommunicationRecurrences/CommunicationRecurrenceAdd";
import CommunicationRecurrenceEdit from "../../TasksManagement/CommunicationRecurrences/CommunicationRecurrenceEdit";
import TaskAdd from "../../TasksManagement/Tasks/TaskAdd";
import TaskEdit from "../../TasksManagement/Tasks/TaskEdit";
import { toCamelCaseFirst, toYesNo } from "../../../utils/commonFunctions";

const ClientServiceDetails = () => {
  const { client_service_id, client_id } = useParams<{ client_service_id: string, client_id: string }>();
  const [tasksList, setTasksList] = useState<any>([]);
  const [taskRecurringList, setTaskRecurringList] = useState<any>([]);
  const [communicationRecurringList, setCommunicationRecurringList] = useState<any>([]);
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [showTaskAdd, setshowTaskAdd] = useState<boolean>(false);
  const [showTaskRecurringAdd, setshowTaskRecurringAdd] = useState<boolean>(false);
  const [showTaskEdit, setshowTaskEdit] = useState<boolean>(false);
  const [showTaskRecurringEdit, setshowTaskRecurringEdit] = useState<boolean>(false);
  const [showCommunicationRecurringAdd, setshowCommunicationRecurringAdd] = useState<boolean>(false);
  const [showCommunicationRecurringEdit, setshowCommunicationRecurringEdit] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<any>(null);
  const [taskRecurrenceId, setTaskRecurrenceId] = useState<any>(null);
  const [serviceDetail, setServiceDetail] = useState<any>([]);

  const navigate = useNavigate()

  const handleSubmit = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
    fetchTasks();
    fetchTaskRecurring();
    fetchCommunicationRecurring();
  }, [client_service_id])

  const fetchTasks = () => {
    gLoader(true);
    taskService?.listTask({ object: "SERVICE", object_id: client_service_id })
      .then((res: any) => {
        gLoader(false);
        setTasksList(res?.data?.response?.list);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }
  const fetchTaskRecurring = () => {
    gLoader(true);
    taskService?.listTaskScheduleReccurence({ object: "SERVICE", object_id: client_service_id, action_type: "COMMUNICATION_EMAIL" })
      .then((res: any) => {
        gLoader(false);
        setTaskRecurringList(res?.data?.response?.list);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const fetchCommunicationRecurring = () => {
    gLoader(true);
    taskService?.listCommunicationReccurence({ object: "SERVICE", object_id: client_service_id })
      .then((res: any) => {
        gLoader(false);
        setCommunicationRecurringList(res?.data?.response?.list);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  const fetchData = () => {
    gLoader(true);
    service?.detailCompanyServiceToClient(client_service_id)
      .then((res: any) => {
        gLoader(false);
        setServiceDetail(res?.data?.response?.detail)
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleTaskAddSuccess = () => {
    setshowTaskAdd(false);
    fetchTasks();
  };

  const handleTaskRecurrenceAddSuccess = () => {
    setshowTaskRecurringAdd(false);
    fetchTaskRecurring();
  };

  const handleTaskEditSuccess = () => {
    setshowTaskEdit(false);
    fetchTasks();
    setTaskId(null);
  };

  const handleTaskRecurrenceEditSuccess = () => {
    setshowTaskRecurringEdit(false);
    fetchTaskRecurring();
    setTaskRecurrenceId(null);

  };


  const handleShowTaskEdit = (id: any) => {
    setTaskId(id);
    setshowTaskEdit(true);
  };
  const handleShowTaskRecurrenceEdit = (id: any) => {
    setTaskRecurrenceId(id);
    setshowTaskRecurringEdit(true);
  };

  const handleCommunicationRecurrenceAddSuccess = () => {
    setshowCommunicationRecurringAdd(false);
    fetchCommunicationRecurring();
  };
  const handleCommunicationRecurrenceEditSuccess = () => {
    setshowCommunicationRecurringEdit(false);
    fetchCommunicationRecurring();
    setTaskRecurrenceId(null);
  };
  const handleShowCommuicationRecurrenceEdit = (id: any) => {
    setTaskRecurrenceId(id);
    setshowCommunicationRecurringEdit(true);
  };

  const handleDeleteTask = (id: any) => {
    gLoader(true);
    taskService?.deleteTask(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchTasks();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  const handleDeleteTaskRecurring = (id: any) => {
    gLoader(true);
    taskService?.deleteTaskScheduleReccurence(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchTaskRecurring();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  const handleDeleteCommunicationRecurring = (id: any) => {
    gLoader(true);
    taskService?.deleteCommunicationReccurence(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchCommunicationRecurring();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return <Card>
    <CardHeader title="Client Service Detail" rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        /></>
    } />

    {showEdit && (
      <EditClientService
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={handleSubmit}
        data={{ client_service_id, client_id }}
      />
    )}

    {showTaskAdd && (
      <TaskAdd
        show={showTaskAdd}
        onCancel={() => setshowTaskAdd(false)}
        onSuccess={handleTaskAddSuccess}
        data={{ object: "SERVICE", client_id, object_id: client_service_id }}
      />
    )}
    {showTaskRecurringAdd && (
      <TaskScheduleRecurrenceAdd
        show={showTaskRecurringAdd}
        onCancel={() => setshowTaskRecurringAdd(false)}
        onSuccess={handleTaskRecurrenceAddSuccess}
        data={{ object: "SERVICE", client_id, object_id: client_service_id }}
      />
    )}

    {showTaskEdit && (
      <TaskEdit
        show={showTaskEdit}
        onCancel={() => setshowTaskEdit(false)}
        onSuccess={handleTaskEditSuccess}
        data={{ object: "SERVICE", client_id, object_id: client_service_id, task_id: taskId }}
      />
    )}
    {showTaskRecurringEdit && (
      <TaskScheduleRecurrenceEdit
        show={showTaskRecurringEdit}
        onCancel={() => setshowTaskRecurringEdit(false)}
        onSuccess={handleTaskRecurrenceEditSuccess}
        data={{ object: "SERVICE", client_id, object_id: client_service_id, task_schedule_recurrence_id: taskRecurrenceId }}
      />
    )}

    {showCommunicationRecurringAdd && (
      <CommunicationRecurrenceAdd
        show={showCommunicationRecurringAdd}
        onCancel={() => setshowCommunicationRecurringAdd(false)}
        onSuccess={handleCommunicationRecurrenceAddSuccess}
        data={{ object: "SERVICE", client_id, object_id: client_service_id }}
      />
    )}
    {showCommunicationRecurringEdit && (
      <CommunicationRecurrenceEdit
        show={showCommunicationRecurringEdit}
        onCancel={() => setshowCommunicationRecurringEdit(false)}
        onSuccess={handleCommunicationRecurrenceEditSuccess}
        data={{ object: "SERVICE", client_id, object_id: client_service_id, task_schedule_recurrence_id: taskRecurrenceId }}
      />
    )}

    {/* <LabelDescriptions
      column={2}
      list={[
        ["Service Type", serviceDetail?.services_type_title],
        ["Has Recurrence", toYesNo(CS_BOOLEAN_TRUE.includes(serviceDetail?.has_recurrence))],
        ["Status", <Badge colorTheme='B' text={serviceDetail?.status} />],
        ...(serviceDetail?.custom_fields ? serviceDetail?.custom_fields?.map((item: any, index: number) => {
          let custom_field_value = item?.custom_field_value;
          if (item?.type == 'BOOLEAN' && CS_BOOLEAN_TRUE.includes(custom_field_value)) {
            custom_field_value = 'Yes';
          } else if (item?.type == 'BOOLEAN') {
            custom_field_value = 'No';
          }
          return [item?.name, custom_field_value]
        }) : [])
      ]}
    /> */}
    <LabelDescriptions
      column={2}
      list={[
        ["Service Type", serviceDetail?.services_type_title],
        ["Has Recurrence", toYesNo(CS_BOOLEAN_TRUE.includes(serviceDetail?.has_recurrence))],
        ["Status", <Badge colorTheme='B' text={serviceDetail?.status} />],
        ...(
          serviceDetail?.custom_fields
            ? serviceDetail?.custom_fields.map((item: any) => {
              let custom_field_value = item?.custom_field_value;
              if (item?.type === 'BOOLEAN' && CS_BOOLEAN_TRUE.includes(custom_field_value)) {
                custom_field_value = 'Yes';
              } else if (item?.type === 'BOOLEAN') {
                custom_field_value = 'No';
              }
              return [item?.name, custom_field_value];
            })
            : []
        ),
        ...(CS_BOOLEAN_TRUE.includes(serviceDetail?.has_recurrence)
          ? [["Trigger Interval", toCamelCaseFirst(serviceDetail?.trigger_interval)]]
          : []
        ),
        ...(serviceDetail?.trigger_on
          ? [["Trigger On", serviceDetail?.trigger_on === "LAST_DAY_OF_MONTH" ? "Last Day Of Month" : serviceDetail?.trigger_on]]
          : []
        ),
        ...(serviceDetail?.trigger_month
          ? [["Trigger Month",
            serviceDetail?.trigger_interval === "QUARTERLY"
              ? CS_TASK_QUARTERLY.find((itm: any) => itm.value === serviceDetail?.trigger_month)?.name || ""
              : CS_TASK_MONTHS.find((itm: any) => itm.value === serviceDetail?.trigger_month)?.name || ""]]
          : []
        )
      ]}
    />
    {/* <>
      {CS_BOOLEAN_TRUE.includes(serviceDetail?.has_recurrence) && (
        <div>{`Trigger Interval ${toCamelCaseFirst(serviceDetail?.trigger_interval)}`}</div>
      )}

      {serviceDetail?.trigger_on && (
        <div>
          {`Trigger On ${serviceDetail?.trigger_on === "LAST_DAY_OF_MONTH" ? "Last Day Of Month" : serviceDetail?.trigger_on}`}
        </div>
      )}

      {serviceDetail?.trigger_month && (
        <div>
          {`Trigger Month ${serviceDetail?.trigger_interval === "QUARTERLY"
            ? CS_TASK_QUARTERLY.find((itm: any) => itm.value === serviceDetail?.trigger_month)?.name
            : CS_TASK_MONTHS.find((itm: any) => itm.value === serviceDetail?.trigger_month)?.name || ""}`}
        </div>
      )}
    </> */}

    <br />
    <br />
    <br />
    <CardHeader title="Tasks"
      titleStyle={{ color: 'gray' }}
    // rightButtons={
    //   <>
    //     <Button size="small" title="Add Task" onClick={() => setshowTaskAdd(true)} />
    //     <Button size="small" title="Add Recurring Task" onClick={() => setshowTaskRecurringAdd(true)} />
    //   </>
    // }
    />
    <List headers={listHeadersTasks}>
      <ListBody>
        {tasksList.map((itm: any, index: number) => {
          return (
            itm?.task_status != 1 ?
              <tr key={index}>
                <Td align="left"><TextLink text={itm?.title} title="View" goto={`/tasks/${itm.id}`} /></Td>
                {/* <Td>{itm?.service_type_title} <br /> {itm?.object}</Td> */}
                <Td>{utcToLocalDatetime(itm?.trigger_on)}</Td>
                <Td align="right"><ListRowActions
                  actions={[
                    { icon: 'edit', onClick: () => handleShowTaskEdit(`${itm.id}`) },
                    { icon: 'view', onClick: () => navigate(`/tasks/${itm.id}`) },
                    { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDeleteTask(itm.id)) },
                  ]}
                />
                </Td>
              </tr>
              :
              null
          );
        })}
      </ListBody>
    </List>

    <br />
    <br />
    <br />
    <CardHeader title="Tasks Schedules"
      titleStyle={{ color: 'gray' }}
      rightButtons={
        <>
          <Button size="small" title="Add Task" onClick={() => setshowTaskAdd(true)} />
          <Button size="small" title="Add Recurring Task" onClick={() => setshowTaskRecurringAdd(true)} />
        </>
      }
    />


    <List headers={listHeadersTasksSchedules}>
      <ListBody>
        {tasksList.map((itm: any, index: number) => {
          return (
            itm?.task_status == 1 ?
              <tr key={index}>
                <Td align="left">Once</Td>
                <Td align="left"><TextLink text={itm?.title} title="View" goto={`/tasks/${itm.id}`} /></Td>
                {/* <Td>{itm?.service_type_title} <br /> {itm?.object}</Td> */}
                <Td>{utcToLocalDatetime(itm?.trigger_on)}</Td>
                <Td align="right"><ListRowActions
                  actions={[
                    { icon: 'edit', onClick: () => handleShowTaskEdit(`${itm.id}`) },
                    { icon: 'view', onClick: () => navigate(`/tasks/${itm.id}`) },
                    { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDeleteTask(itm.id)) },
                  ]}
                />
                </Td>
              </tr>
              :
              null
          );
        })}

        {taskRecurringList.map((itm: any, index: number) => {
          return (
            <tr key={index}>
              <Td align="left">{itm?.trigger_interval}</Td>
              <Td align="left"><TextLink text={itm?.title} title="View" goto={`/tasks/schedule/recurrence/${itm.id}`} /></Td>
              <Td>{(itm?.trigger_on == "LAST_DAY_OF_MONTH") ? "Last Day Of Month" : itm?.trigger_on}  {itm?.trigger_interval == 'DAILY' ? 'Daily ' : ''} / {itm?.trigger_time}</Td>
              <Td align="right"><ListRowActions
                align="right"
                actions={[
                  { icon: 'edit', onClick: () => handleShowTaskRecurrenceEdit(`${itm.id}`) },
                  { icon: 'view', onClick: () => navigate(`/tasks/schedule/recurrence/${itm.id}`) },
                  { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDeleteTaskRecurring(itm.id)) },
                ]}
              />
              </Td>
            </tr>
          );
        })}
      </ListBody>
    </List>
    <br />
    <br />

    <CardHeader title="Events"
      titleStyle={{ color: 'gray' }}
      rightButtons={
        <>
          <Button size="small" title="Add Event" onClick={() => setshowCommunicationRecurringAdd(true)} />
        </>
      }
    />
    <List headers={listHeadersCommunicationRecurring}>
      <ListBody>
        {communicationRecurringList.map((itm: any, index: number) => {
          return (
            <tr key={index}>
              <Td align="left">{itm?.trigger_interval}</Td>
              <Td align="left"><TextLink text={itm?.email_template_title} title="View" goto={`/settings/communication/email/templates/${itm.email_template_id}`} /></Td>
              <Td>{(itm?.trigger_on == "LAST_DAY_OF_MONTH") ? "Last Day Of Month" : itm?.trigger_on} {itm?.trigger_interval == 'DAILY' ? 'Daily ' : ''} / {itm?.trigger_time}</Td>
              <Td><ListRowActions align="right"
                actions={[
                  { icon: 'edit', onClick: () => handleShowCommuicationRecurrenceEdit(`${itm.id}`) },
                  { icon: 'view', onClick: () => navigate(`/tasks/communication/recurrence/${itm.id}`) },
                  { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDeleteCommunicationRecurring(itm.id)) },
                ]}
              />
              </Td>
            </tr>
          );
        })}
      </ListBody>
    </List>
  </Card>
}


export default ClientServiceDetails

const listHeadersTasks: ListHeadType[] = [
  {
    title: "Title",
    width: "60%",
    align: "left",
  },
  {
    title: "Trigger On",
    width: "20%",
    align: "center",
  },
  {
    title: "",
    width: "20%",
    align: "right",
  }
];

const listHeadersTasksSchedules: ListHeadType[] = [
  {
    title: "Interval",
    width: "10%",
    sortName: "uid",
    align: "left",
  },
  {
    title: "Title",
    width: "50%",
    align: "left",
  },
  {
    title: "Trigger On",
    width: "20%",
    align: "center",
  },
  {
    title: "",
    width: "20%",
    align: "right",
  }
];

// const listHeadersTaskRecurring: ListHeadType[] = [
//   {
//     title: "Id",
//     width: "5%",
//     sortName: "uid",
//     align: "center",
//   },
//   {
//     title: "Title",
//     width: "10%",
//     align: "center",
//   },

//   // {
//   //   title: "Linked To",
//   //   width: "10%",
//   //   align: "center",
//   // },
//   {
//     title: "Interval",
//     width: "15%",
//     align: "center",
//   },
//   {
//     title: "Trigger On",
//     width: "15%",
//     align: "center",
//   },
//   {
//     title: "Trigger Time",
//     width: "15%",
//     align: "center",
//   },
//   {
//     title: "Status",
//     width: "10%",
//     align: "center",
//   },
//   {
//     title: "Actions",
//     width: "20%",
//     align: "center",
//   }
// ];

const listHeadersCommunicationRecurring: ListHeadType[] = [
  {
    title: "Interval",
    width: "10%",
    sortName: "uid",
    align: "left",
  },
  {
    title: "Email Template",
    width: "50%",
    align: "left",
  },
  {
    title: "Trigger On",
    width: "20%",
    align: "center",
  },
  {
    title: "",
    width: "20%",
    align: "center",
  }
];