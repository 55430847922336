import { Children, CSSProperties, FC, MouseEventHandler, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
export const TextLink = ({
  title,
  text,
  bold = true,
  goto,
  theme,
  mini = false,
  onClick
}: {
  text: string;
  title?: string;
  bold?: boolean;
  goto?:string,
  theme? : 'light-gray',
  mini?:boolean,
  onClick?:()=>void
}) => {

  const navigation = useNavigate()


  return (
    <span
      className={`text-link-span ${theme} ${mini ? 'mini-text' : ''}`}
      onClick={()=> onClick ? onClick : (goto ? navigation(goto) : null)}
      title={title}
    >
      {text}
    </span>
  );
};

export const SubHeader=({heading,style}:{
  heading:string,
  style?:CSSProperties
})=>{
  return <div style={{fontSize:'1.2em',fontWeight:'500',color:'gray',marginBottom:'8px',...style}}>{heading}</div>
}

export const SubContent = ({
  heading,
  children,
  style
}: {
  heading?: string;
  children:ReactNode;
  style?:CSSProperties
}) => {

  return (
    <div style={{padding:'10px'}}>
      <p style={{fontSize:'1.2em',fontWeight:'500',color:'gray',marginBottom:'8px'}}>
      {heading}
    </p>
    <div style={style}>
    {children}
    </div>
    </div>
  );
};

type LabelDescriptionInternalArray = [string, string | ReactNode, boolean?];

export const LabelDescriptions =({list,style = {},column=1}:{
  list :LabelDescriptionInternalArray[];
  style?:CSSProperties;
  column?:1|2|3
}) : JSX.Element=>{

  return <div className={`label-description column-${column}`} style={style}>
    {list?.map((item,index)=>{
      const [label,description] = item
      return <div key={index} className="label-description-item">
          <div className="item-label">{label}</div>
          <div className="item-description">{description}</div>
      </div>
})}
  </div>
}


interface BadgeProps {
  text:string,
  color?:string,
  bgColor?:string,
  style?:CSSProperties,
  onClick?:()=>void,
  icon?:ReactNode,
  colorTheme?:'A' | 'B' | 'C'
}
export const Badge:FC<BadgeProps> =({
  text,
  color,
  bgColor,
  style,
  onClick,
  icon,
  colorTheme
}):JSX.Element=>{
  let tColor = 'black';
  let tBgColor = 'black';
    switch(colorTheme){
      case 'A':
        tColor = 'var(--color-a)'
        tBgColor = 'var(--color-a-light)'
      break;
      case 'B':
        tColor = 'var(--color-b)'
        tBgColor = 'var(--color-b-light)'
    }

  return <div onClick={onClick} style={{fontSize:'.8em',display:'flex',alignItems:'center',gap:'3px',padding:'2px 10px',fontWeight:'600',borderRadius:'6px',minWidth:'80px',background: bgColor || tBgColor,color:color ||tColor,cursor : 'pointer',justifyContent:'center',textWrap:'nowrap',...style}}>{icon} {text}</div>
}



export interface GridProps  {
  style?:CSSProperties;
  children:ReactNode;
  column?: 1 | 2 | 3 | 4;
}
export const Grid:React.FC<GridProps>=({children,column})=>{

  return <div className={`grid grid-column-${column}`}>
    {children}
  </div>
}  