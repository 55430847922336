import { Card } from "asbawa-react-ui-ts-test-b";
import { Outlet } from "react-router-dom";
import logo from "./../../../assets/images/default-logo.png";
import "./style.css";
import { FC, ReactNode } from "react";
interface AuthLayoutProps {
    children:ReactNode,
    heading?:string,
    description?:string | ReactNode
}
const AuthLayout:FC<AuthLayoutProps> = ({children,heading,description}) => {
  return (
    <>
      <div className="auth-ly-wrapper">
        <div className="auth-ly-log-container">
          <img className="image" src={logo} />
        </div>

        { heading && <h1>{heading}</h1>}
        { description && <div><br/>{description}</div>}
        <br />
        <Card style={{ maxWidth: "500px", margin: "auto", width: "100%" }}>
          {children}
        </Card>
      </div>
    </>
  );
};

export default AuthLayout;
