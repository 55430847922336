import "./style.css";
import MainContent from "../../Components/MainContent";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import { TextLink } from "../../Components";
import clientService from "../../../services/axios/clients";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconDocuments, IconServices } from "../../Components/Icons";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListPagination } from "../../../hooks/useListPagination";
const BulkAssignments = () => {
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [client_id, setClient_id] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize, filters, search])

  const fetchData = () => {
    gLoader(true);
    clientService?.listClient({ search: search,withPagination:false })
      .then((res: any) => {
        gLoader(false);
        let response = res?.data?.response;
        setList(response?.list);
        setTotalPages(response?.dataFilter?.totalPages);
        setTotalCount(response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }


  return (
    <MainContent
    >
      <CardHeader
        title="Bulk Assignment"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Ask for documents"
              theme="primary"
              onClick={()=>null}
            />

<Button
              size="small"
              title="Send Email"
              theme="primary"
              onClick={()=>null}
            />

<Button
              size="small"
              title="Create Task"
              theme="primary"
              onClick={()=>null}
            />
          </>
        }
      />
      

      <Card>
        {/* <ListSearch /> */}
        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <Td>{itm.cuid}</Td>
                  <Td align="left"><TextLink text={itm?.firstname + ' ' + itm?.lastname} title="View profile" goto={`/client/${itm.id}`} /></Td>
                  <Td align="left">{itm?.email} <br /> {itm?.phone}</Td>
                  <Td align="left">{itm?.address_line} <br /> {itm?.city} {itm?.province} {itm?.country} {itm?.postalcode}</Td>
                  <Td>
                    <ListRowActions

                      actions={[
                        { icon: 'view', onClick: () => navigate(`/client/${itm.id}`) },
                        { icon: 'customIcon', customIcon: <IconServices onClick={() => navigate(`/client/${itm.id}/services`)} size='15px' title="Services" /> },
                        { icon: 'customIcon', customIcon: <IconDocuments onClick={() => navigate(`/client/${itm.id}/documents`)} size='15px' title="Documents" /> }
                      ]}
                    />
                  </Td>
                  {/* <Td>
                    <Button title="Ask For Payment" onClick={() => handleAskForPayment(itm.id)} size="small" />
                    <Button title="Add Invoice" onClick={() => handleAddInvoice(itm.id)} size="small" />
                  </Td> */}
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>


    </MainContent>
  );
};

export default BulkAssignments;

const listHeaders: ListHeadType[] = [
  {
    title: "CUID",
    width: "5%",
    sortName: "uid",
    align: "center",
  },
  {
    title: "Name",
    width: "25%",
    align: "left",
  },
  {
    title: "Contact",
    width: "14%",
    align: "left",
  },
  {
    title: "Address Line",
    width: "20%",
    align: "left",
  },
  {
    title: "",
    width: "24%",
    align: "right",
  },
  {
    title: "",
    width: "12%",
    align: "right",
  },
];

