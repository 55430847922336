import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";
import taskService from "../../../services/axios/tasks";
import { CS_STATUS } from "../../../utils/constants";

const EditTaskStatus: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    gLoader(true);
    taskService?.detailTaskStatus(data?.task_status_id)
      .then((res: any) => {
        gLoader(false);
        let newDetail = res?.data?.response?.detail;
        setPayload({ ...payload, ...newDetail });
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    taskService?.updateTaskStatus(data?.task_status_id,payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "500px" }}>
        <CardHeader title="Edit Task Status" />
        <FieldGroup column={1}>
        <Dropdown
            label="Status"
            name="status"
            value={payload?.status}
            handleChange={handleChange}
            options={CS_STATUS}
            error={errors?.status}
          />
          <Input
            label="Title"
            name="title"
            handleChange={handleChange}
            value={payload?.title}
            error={errors?.title}
          />
          <Input
            label="Background Color"
            name="bg_color"
            handleChange={handleChange}
            value={payload?.bg_color}
            error={errors?.bg_color}
          />
          <Input
            label="Color"
            name="color"
            handleChange={handleChange}
            value={payload?.color}
            error={errors?.color}
          />
        </FieldGroup>

        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default EditTaskStatus;
