import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import documentService from "../../../services/axios/documents";

const AddDocumentsTypes: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    documentService?.addDocumentType(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "500px" }}>
        <CardHeader title="Add Document Type" />
        <FieldGroup column={3}>
          <Input
            label="Name"
            name="name"
            handleChange={handleChange}
            value={payload?.name}
            error={errors?.name}
          />
        </FieldGroup>

        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default AddDocumentsTypes;
