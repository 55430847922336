import { Card, CardHeader, List, ListBody, ListHeadType, Td, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import TaskStatusPieChart from "./TaskStatusPieChart"
import { fakeTaskList } from "../../fakeData";
import ListRowActions from "../Components/List";
import { Badge, TextLink } from "../Components";
import taskService from "../../services/axios/tasks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListFilters } from "../../hooks/useListFilters";
import { useListPagination } from "../../hooks/useListPagination";
import { useListSorting } from "../../hooks/useListSorting";
import { utcToLocalDatetime } from "../../utils/dateFunctions";
import meetingService from "../../services/axios/meetings";


const AppointmentsQuickList = () => {
  const [list, setList] = useState<any>([]);
  const [client_id, setClient_id] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()


  useEffect(() => {
    fetchData()
  }, [pageNo, pageSize,filters])

  const fetchData = () => {
    gLoader(true);
    meetingService?.listMeeting({})
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message)
      })
  }

  return <>
    <Card style={{maxWidth:'600px'}}>
      <CardHeader title="Appointments" />
      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            return (
              <tr key={index}>
                  <Td align="left">{itm?.title}</Td>
                  <Td >{itm?.client_name} <br/>
                  {`${utcToLocalDatetime(itm?.from_datetime, 'HH:mm')} - ${utcToLocalDatetime(itm?.to_datetime, 'HH:mm')}`}
                  </Td>
                </tr>
            );
          })}
        </ListBody>
      </List>
      <br />
    </Card>
  </>
}

export default AppointmentsQuickList

const listHeaders: ListHeadType[] = [
  {
    title: "Title",
    width: "65%",
    align: "left",
  },
  {
    title: "Client/Timings",
    width: "35%",
    align: "center",
  },
];
