import { Button, ButtonsGroup, Card, Input, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendForgotPassOtp, getSignup } from "../../store/authSlice";
import { TextLink } from "../Components";
import AuthLayout from "./AuthLayout";

const ForgotPassword = () => {
    const [payload, setPayload] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleChange = (value: any, name: any, item: any) => {
        setPayload({
            ...payload,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: null
        })
    };

    const handleSubmit = () => {
        dispatch(sendForgotPassOtp(payload))
            .then((res: any) => {
                setButtonLoading(false);
                successPopUp(res?.data?.message);
                navigate(`/auth/new-password/${payload?.email}`);
            })
            .catch((err: any) => {
                setButtonLoading(false);
                errorPopUp(err?.message);
                setErrors(err?.field_errors);
            })
    };

    return <AuthLayout
    heading="Recover Password"
    description={<p>Enter your email to recover password.</p>}
>
        <Input
            label="Email"
            name="email"
            handleChange={handleChange}
            value={payload?.email}
            error={errors?.email}
        />
        <div style={{ textAlign: 'right',margin: "10px" }}>
            <TextLink theme="light-gray" text="Back To Login" title="Login" goto="/auth" />
        </div>
        <ButtonsGroup>
            <Button title="Get OTP On Email" onClick={handleSubmit} loading={buttonLoading} theme="success" />
        </ButtonsGroup>
    </AuthLayout>
}

export default ForgotPassword;