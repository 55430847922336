import { IconClients, IconReminder, IconSettings, IconUser, IconView } from "../../Components/Icons";
import "./header.css";
import logo from "./../../../assets/images/default-logo.png"
import { useDispatch } from "react-redux";
import { getLogout } from "../../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { confirmPopUp } from "asbawa-react-ui-ts-test-b";
import { useSelector } from "react-redux";
import { socket } from "../../../sockets";
import { useEffect } from "react";
import { getRemindersData, getTasksData } from "../../../store/taskSlice";
const rightActionsDesign = {
  size: 25,
  color: 'var(--color-gray)'
}
const Header = () => {
  const remindersCount = useSelector((store: any) => store.task?.remindersCount);
  const tasksCount = useSelector((store: any) => store.task?.tasksCount);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.on('newRemindersTriggered', () => {
        dispatch(getRemindersData());
      });
      socket.on('newTasksTriggered', () => {
        dispatch(getTasksData());
      });

      return () => {
        if (socket) {
          socket.off('newRemindersTriggered');
          socket.off('newTasksTriggered');
        }
      };
    }
  }, [socket, dispatch]);

  return (
    <>
      <div className="ly-header">
        <div className="ly-header-left">
          <div className="logo-wrapper"><img className="image" src={logo} /></div>
          <div className="company-title">Infinity Accounts</div>
        </div>
        <div></div>
        <div className="ly-header-right">
          <div className="ly-header-right-icons">
            <span onClick={() => navigate(`/tasks`)}>Tasks : {tasksCount || '0'}</span>
            <span onClick={() => navigate(`/tasks/reminders`)}>Reminders : {remindersCount || '0'}</span>
            <IconSettings {...rightActionsDesign} onClick={() => navigate(`/settings`)} />
            <IconReminder {...rightActionsDesign} onClick={() => alert('setting')} />
            <IconUser {...rightActionsDesign} onClick={() => confirmPopUp('Want to logout', () => dispatch(getLogout(navigate)))} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
