
import { Button, Card, CardHeader, confirmPopUp, errorPopUp, gLoader, Input, List, ListBody, ListHeadType, successPopUp, Td } from "asbawa-react-ui-ts-test-b";
import { Badge, TextLink } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconDocuments } from "../../Components/Icons";
import paymentService from "../../../services/axios/payments";
import AddInvoice from "./AddBusiness";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";
import { CS_INVOICE_STATUS } from "../../../utils/constants";
import AddBusiness from "./AddBusiness";
import businessService from "../../../services/axios/businesses";
const ClientBusinesses = (): JSX.Element | null => {
  const { client_id } = useParams<{ client_id: string }>();
  const [list, setList] = useState<any>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);

  useEffect(() => {
    fetchData()
  }, [client_id])

  const handleAddNewSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    businessService?.list('', { client_id })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }


  return <>
    <Card style={{maxWidth:'700px'}}>


      <CardHeader
        title="Businesses"
        rightButtons={
          <>
            <Button
              size="small"
              title="Add New Business"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
          </>
        }
      />

      {showAddNew && (
        <AddBusiness
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleAddNewSuccess}
          data={{ client_id: client_id }}
        />
      )}

      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            return (
              <tr key={index}>
                <Td align="left">{itm?.name}</Td>

              </tr>
            );
          })}
        </ListBody>
      </List>
      {/* <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} /> */}
    </Card>
  </>
};

export default ClientBusinesses;
const listHeaders: ListHeadType[] = [

  {
    title: "Name",
    width: "100%",
    align: "left",
  }
];