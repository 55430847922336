import moment from "moment-timezone";
import store from "../store";
import { toNull } from "./commonFunctions";

export const formatDate = (date: Date | string): string => {
    if (toNull(date) != null) {
        let parsedDate: Date;
        if (typeof date === 'string') {
            parsedDate = new Date(date);
        } else {
            parsedDate = date;
        }
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const day = String(parsedDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return '';
}

// export const utcToLocalDatetime = (utcDateTime: string): string => {
//     if (toNull(utcDateTime) != null) {
//         const utcDate = new Date(utcDateTime);
//         const userTimeZoneOffset = utcDate.getTimezoneOffset();
//         const localDate = new Date(utcDate.getTime() - userTimeZoneOffset * 60000);
//         // const formattedLocalDate = localDate.toLocaleString('en-US', {
//         //     year: 'numeric',
//         //     month: '2-digit',
//         //     day: '2-digit',
//         //     hour: '2-digit',
//         //     minute: '2-digit',
//         //     second: '2-digit',
//         //     hour12: false,
//         // });
//         // return formattedLocalDate.replace(",", "").replace(" ", " ");
//         const year = localDate.getFullYear();
//         const month = String(localDate.getMonth() + 1).padStart(2, '0');
//         const day = String(localDate.getDate()).padStart(2, '0');
//         const hours = String(localDate.getHours()).padStart(2, '0');
//         const minutes = String(localDate.getMinutes()).padStart(2, '0');
//         const seconds = String(localDate.getSeconds()).padStart(2, '0');
//         const formattedLocalDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
//         return formattedLocalDate;
//     } else {
//         return '';
//     }
// };

export const utcToLocalDatetime = (utcDate: Date | string | null,format:string ='YYYY-MM-DD HH:mm:ss' ): string => {
    try {
        //const state = store.getState();//Access entire Redux state
        // const timezone = store.getState()?.auth?.auth?.detail?.timezone; // Access specific slice of state
        const timezone:any = store.getState()?.auth?.timezone;
        // console.log(timezone)
        if (toNull(utcDate) != null && toNull(timezone) != null) {
            let utcMoment: moment.Moment;
            if (utcDate instanceof Date) {
                utcMoment = moment.utc(utcDate);
            } else if (typeof utcDate === 'string') {
                utcMoment = moment.utc(utcDate, 'YYYY-MM-DD HH:mm:ss');
                if (!utcMoment.isValid()) {
                    return '';
                }
            } else {
                return '';
            }
            const localMoment = utcMoment.tz(timezone);
            return localMoment.format(format);
        }
        return '';
    }
    catch (error) {
        return '';
    }

}