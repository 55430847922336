import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import emailService from "../../../../../services/axios/settings/communication/email";
import { Badge, LabelDescriptions, SubContent } from "../../../../Components";
const DetailEmailTemplates = (): JSX.Element | null => {
  const { email_template_id } = useParams<{ email_template_id: string }>();
  const [emailTemplateData, setEmailTemplateData] = useState<any>({});
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const navigate = useNavigate()
  useEffect(() => {
    fetchData();
  }, [email_template_id]);

  const fetchData = () => {
    gLoader(true);
    emailService
      ?.detailEmailTemplate(email_template_id)
      .then((res: any) => {
        gLoader(false);
        setEmailTemplateData(res?.data?.response?.detail);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const onSuccess = () => {
    setshowEdit(false);
    fetchData();
  };


  return (
    <>
      <Card>
        <CardHeader
          title="Email Template"
          rightButtons={
            <>
              <Button
                size="small"
                title="Edit"
                onClick={() => navigate(`/settings/communication/email/templates/edit/${email_template_id}`)}
              />
            </>
          }
        />

        {/* {showEdit && (
          <EditClient
            show={showEdit}
            onCancel={() => setshowEdit(false)}
            onSuccess={onSuccess}
            data={{ email_template_id: email_template_id }}
          />
        )} */}

        <SubContent heading="General Details" style={{ display: "flex" }}>
          <LabelDescriptions
            style={{ width: "100%" }}
            list={[
              ["id", emailTemplateData?.id],
              ["Title", emailTemplateData?.title],
              ["Subject", emailTemplateData?.subject],
              ["Description", emailTemplateData?.description],
              ["Status", <Badge colorTheme='B' text={emailTemplateData?.status} />],
            ]}
          />
        </SubContent>
      </Card>
    </>
  );
};

export default DetailEmailTemplates;
