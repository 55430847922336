import axios, { AxiosInstance } from 'axios';
import { toNull } from '../../utils/commonFunctions';

let access_token = localStorage.getItem('token');

// const axiosClient:AxiosInstance = axios.create();
// axiosClient.defaults.baseURL = 'http://localhost:3021/';
// axiosClient.defaults.headers = {
//   'Content-Type': 'application/json',
//   'Access-Control-Allow-Origin': ' *',
//   'Authorization': `Bearer ${access_token}`
// };

const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ' *',
    'Authorization': `Bearer ${access_token}`
  }
});

axiosClient.interceptors.request.use(
  async (request: any) => {
    access_token = localStorage.getItem('token')
    if (toNull(access_token) != null) {
      request.headers['Authorization'] = `Bearer ${access_token}`;
    }
    return request;
  },
  (error: any) => Promise.reject(error)
);

axiosClient.interceptors.response.use((response) => {
  return response;
}, (error: any) => {
  if (error.response) {
    // console.log(error.response.status);
    // console.log(error.response.headers);
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('token');
      window.location.replace("/auth");
    }
    if (error.response.data?.errorCode === "EMAIL_NOT_VERIFIED") {
      let email = error.response.data?.response?.email;
      window.location.replace(`/auth/verify-email?email=${email}`);
      //window.location.href = "/auth/verify-email";
    }
    if (error.response.data?.errorCode === "SUBSCRIPTION_ERROR") {
      window.location.replace(`/settings/plans`);
      //window.location.href = `/settings/plans`;
    }

    throw error.response.data
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("axios request error", error.request);
    throw false
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('axios Error', error.message);
    throw false
  }

});

// axiosClient.defaults.timeout = 90000;
// axiosClient.defaults.withCredentials = false;

export async function getRequest(URL: string, urlParam: any) {
  return await axiosClient.get(`/${URL}`, { params: urlParam });
}

export async function postRequest(URL: string, payload: any, otherData = {}) {
  return await axiosClient.post(`/${URL}`, payload, otherData);
}

export async function putRequest(URL: string, payload: any) {
  return await axiosClient.put(`/${URL}`, payload);
}

export async function deleteRequest(URL: string, urlParam: any) {
  return await axiosClient.delete(`/${URL}`, { data: urlParam });
}

export async function postFormdata(URL: string, payload: any) {
  return await axiosClient.post(`/${URL}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${access_token}`
    }
  });
}

export async function putFormdata(URL: string, payload: any) {
  return await axiosClient.put(`/${URL}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${access_token}`
    }
  });
}

export async function getBlobRequest(URL: string, urlParam: any) {
  return await axiosClient.get(`/${URL}`, { params: urlParam, responseType: 'blob' });
}