import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  InputDate,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import clientService from "../../services/axios/clients";
import { ModalFormProps } from "../../types";
import { SubContent } from "../Components";
import { CS_BOOLEAN_TRUE } from "../../utils/constants";

const AddClient: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
}): JSX.Element | null => {

  const [payload, setPayload] = useState<any>({ custom_fields: [{}] });
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [customFieldList, setCustomFieldList] = useState<any>([]);

  useEffect(() => {
    fetchCustomFields();
  }, [])

  const fetchCustomFields = () => {
    gLoader(true);
    clientService?.quickListClientProfileCustomField()
      .then((res: any) => {
        gLoader(false);
        // const dropdownOptionsCustomFields = res?.data?.response?.list?.map((item: any) => ({
        //   name: item?.name,
        //   value: item?.id,
        //   is_required: item?.is_required,
        //   type: item?.type,
        // }));
        // setCustomFieldList(res?.data?.response?.list);
        let fieldDetails = res?.data?.response?.list;
        // let payloadData = { ...payload, custom_fields: fieldDetails };
        // setPayload(payloadData);
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          custom_fields: fieldDetails
        }));
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    clientService?.addClient(payload)
      .then((res) => {
        // gLoader(false);
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
        //navigate('/')
      })
      .catch((err) => {
        // gLoader(false);
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  const handleArrayChange = (value: any, name: string, index: any) => {
    // let customFieldListArr = {...customFieldList[index],[name]: value};
    // customFieldList[index]=customFieldListArr;
    const payloadArr = [...payload.custom_fields];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    // if (name == 'custom_field_id') {
    //   const fieldObj = customFieldList.find((field: any) => field.value == value);
    //   payloadArr[index] = {
    //     ...payloadArr[index],
    //     ['custom_field_value']: null,
    //     ['is_required']: fieldObj?.is_required,
    //     ['type']: fieldObj?.type,
    //   };
    // }
    handleChange(payloadArr, 'custom_fields');
  };

  // const addMore = () => {
  //   const payloadArr = [...payload?.custom_fields]
  //   payloadArr.push({})
  //   handleChange(payloadArr, 'custom_fields');
  // }

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "1200px" }}>
        <CardHeader title="Add Client" />
        <FieldGroup column={3}>
          <Input
            required
            label="First Name"
            name="firstname"
            handleChange={handleChange}
            value={payload?.firstname}
            error={errors?.firstname}
          />
          <Input
            required
            label="Last Name"
            name="lastname"
            handleChange={handleChange}
            value={payload?.lastname}
            error={errors?.lastname}
          />
          <Input
            required
            label="Address"
            name="address_line"
            handleChange={handleChange}
            value={payload?.address_line}
            error={errors?.address_line}
          />
          <Input
            required
            label="City"
            name="city"
            handleChange={handleChange}
            value={payload?.city}
            error={errors?.city}
          />
          <Input
            required
            label="Province"
            name="province"
            handleChange={handleChange}
            value={payload?.province}
            error={errors?.province}
          />
          <Input
            required
            label="Country"
            name="country"
            handleChange={handleChange}
            value={payload?.country}
            error={errors?.country}
          />
          <Input
            required
            label="Postal Code"
            name="postalcode"
            handleChange={handleChange}
            value={payload?.postalcode}
            error={errors?.postalcode}
          />
          <Input
            required
            label="Email"
            name="email"
            handleChange={handleChange}
            value={payload?.email}
            error={errors?.email}
          />
          <Input
            required
            label="Phone"
            name="phone"
            handleChange={handleChange}
            value={payload?.phone}
            error={errors?.phone}
          />
          <Input
          required
            label="Whatsapp No."
            name="whatsapp_number"
            handleChange={handleChange}
            value={payload?.whatsapp_number}
            error={errors?.whatsapp_number}
          />
          <textarea
            value={payload?.internal_notes}
            onChange={(e) => handleChange(e.target.value, 'internal_notes')}
            placeholder="Internal Notes"
            style={{
              width: '100%',
              height: '100px',
              resize: 'none',
              overflow: 'auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              // fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.5'
            }}
          />
        </FieldGroup>
        <br />
        <SubContent heading="Custom Fields">
          <FieldGroup column={3}>
            {payload?.custom_fields?.map((item: any, index: number) => (
              <div key={index}>
                {item?.type == 'BOOLEAN' ?
                  <InputCheckbox
                    label={item?.name}
                    name="custom_field_value"
                    handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                    checked={payload?.custom_fields?.[index]?.custom_field_value}
                  /> :
                  item?.type == 'DATE' ?
                    <InputDate
                      label={item?.name}
                      name="custom_field_value"
                      handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                      value={payload?.custom_fields?.[index]?.custom_field_value}
                      required={CS_BOOLEAN_TRUE.includes(item?.is_required) ? true : false}
                    /> :
                    <Input
                      label={item?.name}
                      name="custom_field_value"
                      handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                      value={payload?.custom_fields?.[index]?.custom_field_value}
                      required={CS_BOOLEAN_TRUE.includes(item?.is_required) ? true : false}
                    />
                }
              </div>
            ))}
          </FieldGroup>
        </SubContent>
        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} theme="success" loading={buttonLoading} />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
          {/* <Button title="Add More" onClick={() => addMore()} theme="primary" /> */}
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default AddClient;
