import {
  Button,
  ButtonsGroup,
  CardHeader,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import TableForm from "../../Components/TableForm";
import { ModalFormProps } from "../../../types";
import { IconDelete } from "../../Components/Icons";
import paymentService from "../../../services/axios/payments";
import authService from "../../../services/axios/auth";

const AddInvoice: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({ client_id: data?.client_id, items: [{}] });
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    paymentService?.addInvoice(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  const handleArrayChange = (value: any, name: string, index: any) => {
    const payloadArr = [...payload.items];
    payloadArr[index] = {
      ...payloadArr[index],
      [name]: value,
    };
    handleChange(payloadArr, 'items');
  };

  const addMore = () => {
    const payloadArr = [...payload?.items]
    payloadArr.push({})
    handleChange(payloadArr, 'items');
  }

  const handleRemove = (index: any) => {
    const payloadArr = [...payload.items];
    payloadArr.splice(index, 1);
    handleChange(payloadArr, 'items');
  };
useEffect(()=>{

    gLoader(true);
    authService?.profile()
      .then((res: any) => {
        gLoader(false);
        let gst_rate = 0;
        let hst_rate = 0;

        if(res?.data?.response?.detail?.tax_gst){
          gst_rate = res?.data?.response?.detail?.tax_gst
        }

        if(res?.data?.response?.detail?.tax_hst){
          hst_rate = res?.data?.response?.detail?.tax_hst
        }

        setPayload({
          ...payload,
          hst_rate,
          gst_rate

        })
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  
},[])
  

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "800px" }}>
        <CardHeader title="Add Invoice" />
        <FieldGroup column={3}>
          <Input
            label="GST rate"
            name="gst_rate"
            handleChange={handleChange}
            value={payload?.gst_rate}
            error={errors?.gst_rate}
          />
          <Input
            label="HST rate"
            name="hst_rate"
            handleChange={handleChange}
            value={payload?.hst_rate}
            error={errors?.hst_rate}
          />
        </FieldGroup>

        <br />
        <TableForm 
         onAddRow={addMore}
        headers={[
          { title: 'Title', width: '80%' },
          { title: 'Price', width: '20%' },
         
        ]}>
          {payload?.items?.map((itm: any, index: number) => (
            <tr key={index}>
              <td>
                <Input
                  name="title"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.items?.[index]?.title}
                />
              </td>
              {/* <td>
                <Input
                  name="quantity"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.items?.[index]?.quantity}
                />
              </td> */}
              <td>
                <Input
                  name="unit_price"
                  handleChange={(value, name) => { handleArrayChange(value, name, index) }}
                  value={payload?.items?.[index]?.unit_price}
                />
              </td>
              <td>
                <IconDelete size={18} onClick={() => handleRemove(index)} />
              </td>
            </tr>
          ))}
        </TableForm>
        <br />

        <ButtonsGroup>
          <Button title="Save & Next" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default AddInvoice;
