
import { BaseGlobalLoader, BasePopUp, BasePopUpConfirm } from "asbawa-react-ui-ts-test-b";
import BaseRouter from "./routes";
import './App.css'
import { NotificationWrapper } from "./screens/Components/Notifications";
function App(): JSX.Element {
  return <>
    {/* <NotificationWrapper/> */}
    <BaseGlobalLoader/>
    <BasePopUp />
    <BasePopUpConfirm />
    <BaseRouter/>
  </>
}

export default App;
