import "./style.css";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  List,
  ListBody,
  ListHeadType,
  Td,
  confirmPopUp,
  errorPopUp,
  gLoader,
  successPopUp
} from "asbawa-react-ui-ts-test-b";
import { useNavigate } from "react-router-dom";
import service from "../../services/axios/settings/companyServices";
import { Badge, TextLink } from "../Components";
import MainContent from "../Components/MainContent";
import ListRowActions, { ListPagination, ListSearch } from "../Components/List";
import { useListPagination } from "../../hooks/useListPagination";
import { useListFilters } from "../../hooks/useListFilters";
import { useListSorting } from "../../hooks/useListSorting";
import userService from "../../services/axios/users";
import AddMeeting from "./AddMeeting";
import EditMeeting from "./EditMeeting";
import meetingService from "../../services/axios/meetings";
import { utcToLocalDatetime } from "../../utils/dateFunctions";
import MeetingCalender from "./MeetingCalender";
const ListMeeting = () => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showMeetingCalender, setShowMeetingCalender] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [meetingId, setMeetingId] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, [pageNo, pageSize, filters, search])

  const handleAddSuccess = () => {
    fetchData();
    setShowAddNew(false);
  };

  const handleShowEdit = (id: any) => {
    setMeetingId(id);
    setshowEdit(true);
  };
  const handleEditSuccess = () => {
    fetchData();
    setMeetingId(null);
    setshowEdit(false);
  };
  const handleEditCancel = () => {
    setshowEdit(false);
    setMeetingId(null);
  };

  const fetchData = () => {
    gLoader(true);
    meetingService?.listMeeting({ pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    meetingService?.deleteMeeting(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return (
    <MainContent

    >
      <CardHeader
        title="Appointments"
        style={{ maxWidth: '1400px' }}
        searchBarProps={{ onSearch: (value) => setSearch(value) }}
        searchBar={true}
        rightButtons={
          <>
            <Button
              size="small"
              title="Add New"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
            <Button
              size="small"
              title="Show Calender"
              theme="primary"
              onClick={() => setShowMeetingCalender(true)}
            />
          </>
        }
      />
      {showAddNew && (
        <AddMeeting
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleAddSuccess}
        />
      )}

      {showMeetingCalender && (
        <MeetingCalender
          show={showMeetingCalender}
          onCancel={() => setShowMeetingCalender(false)}
          // onSuccess={}
        />
      )}

      {showEdit && (
        <EditMeeting
          show={showEdit}
          onCancel={handleEditCancel}
          onSuccess={handleEditSuccess}
          data={{ meeting_id: meetingId }}
        />
      )}

      <Card>
        {/* <ListSearch /> */}

        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              return (
                <tr key={index}>
                  <Td align="left">{itm?.title}</Td>
                  <Td align="left">{itm?.client}</Td>
                  <Td align="left">{itm?.representative_name}</Td>
                  <Td>{utcToLocalDatetime(itm?.from_datetime)}</Td>
                  <Td>{utcToLocalDatetime(itm?.to_datetime)}</Td>
                  <Td style={{ display: 'flex', justifyContent: 'center' }}><Badge colorTheme='B' text={itm?.status} /></Td>
                  <Td>
                    <ListRowActions
                      actions={[
                        // { icon: 'view', onClick: () => navigate(`${itm.id}`) },
                        { icon: 'edit', onClick: () => handleShowEdit(`${itm.id}`) },
                        { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm.id)) },
                      ]}
                    />
                  </Td>
                </tr>
              );
            })}
          </ListBody>
        </List>
        <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} />
      </Card>
    </MainContent>
  );
};

export default ListMeeting;

const listHeaders: ListHeadType[] = [
  {
    title: "Title",
    width: "30%",
    align: "left",
  },
  {
    title: "Client",
    width: "15%",
    align: "left",
  },

  {
    title: "Representative",
    width: "15%",
    align: "left",
  },
  {
    title: "Start time",
    width: "17%",
    align: "center",
  },
  {
    title: "End time",
    width: "18%",
    align: "center",
  },
  {
    title: "Status",
    width: "10%",
    align: "center",
  },
  {
    title: "",
    width: "5%",
    align: "center",
  }
];
